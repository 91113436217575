import {db} from '../fireBase'
import { 
    getFirestore,
    query,
    orderBy,
    onSnapshot,
    collection,
    getDoc, 
    getDocs, 
    addDoc,
    setDoc,
    updateDoc,
    doc, 
    serverTimestamp, 
    arrayUnion,
    arrayRemove,
    limit, 
    where,
    deleteDoc
} from "firebase/firestore";

export const streamDiscounts = (snapshot, error) => {
    //const itemsColRef = collection(db, 'db-matrix')
    const itemsQuery = query(collection(db,'db-matrix'))
    //console.log(itemsQuery)
    return onSnapshot(itemsQuery, snapshot, error)
}

export const GetDiscountService = async () => {
    const docRef = doc(db,'db-matrix','default_price_1')
    const data1 = await getDoc(docRef)
    const data2 = data1.data()
    const data3 = data2.options
    //console.log(data3)
    return data3
}