import React, { useContext, useEffect, useState } from "react";
//#region //*All tables and Components
import NavBar from '../Components/Navbar'
import TableResults from "../Components/TableResults";
import TableNotResults from "../Components/TableNotResults";
import TableDiscarted from "../Components/TableDiscarted";
import AddManualSku from "../Components/AddManualSku";
import AddManualPendiente from "../Components/AddManualPendiente";
import CatalogModal from "./CatalogModal";
import SaveCorrect from "../Components/SaveCorrect";
import PopUpPublished from "../Components/PupUpPublished";
import SaveCorrectVolver from "../Components/SaveCorrectVolver";
import PopUpChangePrice from "../Components/PopUpChangePrice";
//#endregion
import { informationOfExport } from "../context/informationOfExport";
import { informationOfSearch } from "../context/informationOfSearch";
import { updateQuote, saveDetailQuote, getQuoteToEdit } from "../Data/QuoteServices"
import { useNavigate } from "react-router-dom";
import { publishQuote } from "../Data/QuoteServices";
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";
import { listenToQuoteCart, listenToQuoteDraft } from "../Data/newQuoteServices";
import CopyLink from "../Components/CopyLink";


const Results = () => {

    //VARIBLES

    const navigate = useNavigate()

    //*Singletons
    const { idZoho, alias, category, idClient } = useContext( informationOfExport )
    const {  
        total, 
        updateTotal, 
        editQuote, 
        costOfDelivery, 
        setCostOfDelivery } = useContext( informationOfSearch )

    const { 
        arrAllItems,
        arrDiscarted,
        arrNotResults,
        arrResults,
        idQuote,
        setArrDiscarded,
        setArrNotResults,
        setArrResults,
        totalQuote
    } = useContext( functionsToImplementNewStructure )

    //*hooks
    const [ addManualActive, setAddManualActive ] = useState(false)
    const [ activeManualPendiente, setActiveManualPendiente] = useState(false)
    const [ indexRowAddItem, setIndexRowAddItem ] = useState()
    const [ fromResults, setFromResults ] = useState(false)
    const [ activeModalCatalog, setActiveModalCatalog ] = useState(false)
    const [ arrHits, setArrHits ] = useState([])
    const [ itemToEdit, setItemToEdit] = useState()
    const [ isEdit, setIsEdit ] = useState (false)
    const [ indexSkuManual, setIndexSkuManual ] = useState()
    const [ activePopUpGuardado, setActivePopUpGuardado ] = useState(false)
    const [ activePopUpGuardadoVolver, setActivePopUpGuardadoVolver ] = useState(false)
    const [ addSkuFromNotResults, setAddSkuFromNotResults ] = useState(true)
    const [ indexArrNotResults, setIndexArrNotResults ] = useState()
    const [ showPopUpPublished, setShowPopUpPublished] = useState(false)
    const [ showPopUpChangePrice, setShowPopUpChangePrice ] = useState(false)
    const [ index1ArrMain, setIndex1ArrMain ] = useState()
    const [ index2ArrMain, setIndex2ArrMain ] = useState()
    const [ canPublish, setCanPublish ] = useState(false)
    const [ messageToShow1, setMessageToShow1 ] = useState("Por favor, no debe de haber Items en la seccion de Pendientes para poder publicar")
    const [ messageToShow2, setMessageToShow2 ] = useState("El costo de envio es de $0, ¿es correcto?")
    const [ messageToShow3, setMessageToShow3 ] = useState("Hay una linea en descartados sin razon asignada")
    const [ allWithReason, setAllWithReason ] = useState(false)
    const [ changes, setChanges ] = useState(0)
    const [ averageMargin, setAverageMargin ] = useState(0)
    const [cartItems, setCartItems] = useState([]);
    const [ newItems, setNewItems ] = useState([]);
    const link =  process.env.REACT_APP_URL_V2;
    const textHello = `Hola, tu cotización está lista: \n`
    const [ effectCopied, setEffectCoppied ] = useState(false);

    useEffect(() => {
        //console.log(idQuote)
        listenToQuoteDraft(idQuote,setArrNotResults, setArrDiscarded);
        listenToQuoteCart(idQuote,setArrResults)
    }, [idQuote]);

    let porcent = ( arrResults.length * 100 ) / ( arrNotResults.length + arrResults.length )

    const activePopUpAddItem = (index) => {
        setFromResults(true)
        setIndexRowAddItem(index)
        setAddManualActive(true)
        setIsEdit(false)
    }

    const activePopUpEditItem = ( index1, index2 ) => {
        setIsEdit(true)
        setFromResults(true)
        setIndexRowAddItem(index1)
        setAddManualActive(true)
        setIndexSkuManual(index2)
    }

    const activePopUpAddItemOfNotResult = (index) => {
        setFromResults(false)
        setIsEdit(false)
        setIndexRowAddItem(index)
        setAddManualActive(true)
    }

    const launchActiveLapicito = (index) => {
        setAddSkuFromNotResults(false)
        setArrHits(e=>{
            return[
                ...e.splice(0),
                ...e
            ]
        })
        setActiveModalCatalog(true)
        let arrDummy = arrResults[index].value
        //console.log(arrDummy)
        setArrHits(arrDummy)
        setItemToEdit(arrResults[index]) 
    }

    const launchActiveLapicitoFromNotResults = (item) => {
        setAddSkuFromNotResults(true)
        setArrHits(e=>{
            return[
                ...e.splice(0),
                ...e
            ]
        })
        setActiveModalCatalog(true)
        /*let arrDummy = arrNotResults[index].value
        console.log(arrDummy)
        setArrHits(arrDummy)*/
        setItemToEdit(item)
    }

    const saveChanges = async (indexOfSearch) => {
        //console.log(arrResults)
        let arrDummy = {
            alias: alias, 
            arrDiscarted: arrDiscarted,
            arrResults: arrResults,
            arrNotResults: arrNotResults,
            shipping: {
                total: parseInt(costOfDelivery)
            }            
        }
        try {
            await updateQuote(idQuote,arrDummy,indexOfSearch)
            //setActivePopUpGuardado(showPopUp)
            //console.log("Se Guardo")
        } catch (error) {
            //console.log(error)
        }
    }

    const saveChangesDetailQuote = async (indexOfSearch) => {

        console.log("entramos en la edicion")

        const data = await (await getQuoteToEdit(idQuote)).data()

        console.log(data)

        let arrDummy = {
            alias: alias,
            author: "author" in data ?  data.author: "",
            consecutive: data.consecutive,
            created_at: data.created_at,
            //id_cliente: idZoho,
            arrDiscarted: arrDiscarted,
            arrResults: arrResults,
            arrNotResults: arrNotResults,
            discount: data.discount,
            sub_total: data.sub_total,
            tax: data.tax,
            total: data.total,
            shipping: {
                total: parseInt(costOfDelivery)
            },
            quote_category: data.quote_category,
            customer: data.customer,
            version: data.version,
            record: {
                next_action: "calculate_totals"
            },
            totals: data.totals
        }
        console.log(arrDummy)
        try {
            await saveDetailQuote(idQuote,arrDummy,indexOfSearch)
            //setActivePopUpGuardado(showPopUp)
            console.log("Se Guardo")
        } catch (error) {
            console.log(error)
        }
    }

    const saveChangesVolver = async () => {
        let arrDummy = {
            alias: alias, 
            //id_cliente: idZoho,
            arrDiscarted: arrDiscarted,
            arrResults: arrResults,
            arrNotResults: arrNotResults,
            shipping: {
                total: parseInt(costOfDelivery)
            } 
        }
        try {
            updateQuote(idQuote,arrDummy)
            navigate('/')
            navigate(0)
        } catch (error) {
            console.log(error)
        }
    }

    const publishQuoteOnClick = async () => {
        let dummy = true
        arrDiscarted.map((item, index)=>{
            //console.log(item.reasonDiscarted)
            if(dummy === true && item.reasonDiscarted !== "No Aplicate"){
                setAllWithReason(true)
            }else{
                dummy=false
                setAllWithReason(false)
            }
        })
        //console.log(arrNotResults.length)            
        if( allWithReason===true || arrDiscarted.length===0 ){
            setAllWithReason(true)
                if( costOfDelivery>0 ) {
                    try {
                        await saveChanges(false)
                        console.log("empezando a publicar... 1")
                        const idQuotePublished = await publishQuote(idQuote)
                        console.log("La cotizacion fue publicada .... con id ... ", idQuotePublished)
                        setCanPublish(true)
                        setShowPopUpPublished(true)
                    } catch (error) {
                        console.log(error)
                    }
                }else {
                    if(costOfDelivery===0) {
                        setMessageToShow2("El costo de envio es de $0, ¿es correcto?")
                        setCanPublish(false)
                        setShowPopUpPublished(true)
                    }
                }
            }else {
                setMessageToShow3("Hay una linea en descartados sin razon asignada")
                setCanPublish(false)
                setShowPopUpPublished(true)                
            }     
    }

    const activePopUpChangePrice = (index1, index2) => {
        setIndex1ArrMain(index1)
        setIndex2ArrMain(index2)
        setShowPopUpChangePrice(true)
    }

    const publishWithCost0 = async () => {
        try {
            await saveChanges(false)
            console.log("empezando a publicar... 1")
            const idQuotePublished = await publishQuote(idQuote)
            console.log("La cotizacion fue publicada .... con id ... ", idQuotePublished)
            setCanPublish(true)
            setShowPopUpPublished(true)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        /*let totalMargin = 0
        arrResults.map((item, index)=>{
            totalMargin = totalMargin + item.value[0].price.price_margin
        })
        totalMargin = ((totalMargin*100)/arrResults.length).toFixed(2)
        //console.log(totalMargin)
        setAverageMargin(totalMargin)
        //console.log("entramos")
        if(editQuote){
            //saveChangesDetailQuote("quote-detail")
            saveChanges("quote-draft")
        } else {
            saveChanges("quote-draft")
        }  */     
    },[changes])

    useEffect(()=>{
        /*let totalMargin = 0
        arrResults.map((item, index)=>{
            totalMargin = totalMargin + item.value[0].price.price_margin
        })
        totalMargin = ((totalMargin*100)/arrResults.length).toFixed(2)
        //console.log(totalMargin)
        setAverageMargin(totalMargin)*/
    },[])

    const openQuote = () => {
        //window.open(process.env.REACT_APP_URL_V2+idQuote);
        //dev
            //window.open("https://voltz-develop.web.app/?cotz="+idQuote)
        //prod
            window.open("https://app.voltz.mx/?cotz="+idQuote)
    }
    
    return (
        <>
        {
            effectCopied ? 
            <div className="background-copied">
                    <div>
                        <p>Url copiado al portapapeles</p>
                    </div>
                </div>
                : ''
        }
            <NavBar funcionCotizacion={()=>setActivePopUpGuardadoVolver(true)}/>
            <div className="d-flex justify-content-between align-items-center" id="containerButtons">
                {
                    editQuote ?
                    <div></div>
                    :
                   /* <button id="buttonReturnResults" onClick={()=> setActivePopUpGuardadoVolver(true)}>
                        Cancelar cotización
                    </button>*/
                    <div></div>
                }
                
                {
                    editQuote ?
                    <div>
                        ''
                    </div> 
                    : 
                    <div>
                        
                        <CopyLink text={textHello + link+idQuote}/>
                        <button className="button-result button-result-normal"  onClick={()=>openQuote()}>
                            <i className="bi bi-arrow-right"></i>                     
                        </button>
                    </div>
                }               
            </div>
            <div className="d-flex justify-content-between" id="containerStatsResults">
                <div>
                    {
                        alias!==undefined ?
                        <div className="d-flex">
                            <p id="pAlias">COTIZACIÓN: </p>
                            <p id="pIdCliente" className="ps-3">{alias}</p>
                        </div>
                        :
                        <div className="d-flex">
                            <p id="pAlias">Sin Alias</p>
                        </div>                        
                    }                    
                    <div className="d-flex">
                        <p id="pAlias">CLIENTE: </p> 
                        <p id="pIdCliente" className="ps-3"> {idZoho} </p>
                    </div>
                </div>
                {
                    /*<div>
                        <p id="pTotalAprox">{averageMargin}% Margen Promedio</p>
                    </div>*/
                }
                
                <div>
                    <p id="pStatsResults">{ porcent.toFixed(2) } % COMPLETO</p>
                    <p id="pTotalAprox"> $ { totalQuote } APROX.</p>
                </div>
            </div>
            
            {
                /*<div id="containerInputCostDelivery">
                    <span>
                        Costo del envío ($):
                    </span>
                    < input id="inputCostOfDelivery" 
                        type="number" 
                        defaultValue={costOfDelivery} 
                        onChange = {e => {
                            setCostOfDelivery(e.target.value)
                            setChanges(changes+1)                   
                        }}
                    />
                </div>*/
            }
                       

            <div id="containerTablesResults">
                <TableResults total = {total}  
                    activePopUpAddItem = { activePopUpAddItem } 
                    activePopUpEditItem = { activePopUpEditItem }
                    launchActiveLapicito = { (index)=>launchActiveLapicito(index) } 
                    setAddSkuFromNotResults = {()=>setAddSkuFromNotResults(false)}
                    activePopUpChangePrice = {(index1, index2)=>activePopUpChangePrice(index1, index2)}
                    changes={changes}
                    setChanges={setChanges}
                    saveChanges={saveChanges}
                />
                <TableNotResults activePopUpAddItemOfNotResult = { activePopUpAddItemOfNotResult }
                    show = {()=> setActiveManualPendiente(true) }
                    launchActiveLapicitoFromNotResults = {(index)=>launchActiveLapicitoFromNotResults(index)}
                    setAddSkuFromNotResults = {()=>setAddSkuFromNotResults(true)}
                    changes={changes}
                    setChanges={setChanges}
                    showPopUpNewItemManual={()=>setAddManualActive(true)}
                />
                <TableDiscarted
                    changes={changes}
                    setChanges={setChanges}
                />
            </div>

            {
                addManualActive ?
                <AddManualSku 
                    hide = {() => setAddManualActive(false)} 
                    indexRowAddItem = { indexRowAddItem }
                    indexSkuManual = { indexSkuManual }
                    fromResults={ fromResults }
                    isEdit = {isEdit}
                    changes={changes}
                    setChanges={setChanges}
                />
                : ''
            }

            {
                activeManualPendiente ?
                <AddManualPendiente hide = {() => setActiveManualPendiente(false) }
                    changes={changes}
                    setChanges={setChanges}
                />
                :''
            }

            {
                activeModalCatalog ?
                <CatalogModal hide={()=>setActiveModalCatalog(false)}
                    arrHits={arrHits}
                    itemToEdit = {itemToEdit}
                    addSkuFromNotResults = {addSkuFromNotResults}
                    indexArrNotResults = {indexArrNotResults}
                    changes={changes}
                    setChanges={setChanges}
                />
                : ''
            }

            {
                activePopUpGuardado ? 
                <SaveCorrect hide = { () => setActivePopUpGuardado(false)} saveChanges = {()=>saveChanges()}/>
                :''
            }

            {
                activePopUpGuardadoVolver ? 
                <SaveCorrectVolver hide = {()=>setActivePopUpGuardadoVolver(false)} saveChangesVolver={()=>saveChangesVolver()} id = {idQuote}/>
                :''
            }

            {
                showPopUpPublished ? 
                <PopUpPublished hide = {()=>setShowPopUpPublished(false)} 
                    id = {idQuote}
                    canPublish = {canPublish}
                    messageToShow1 = {messageToShow1}
                    messageToShow2 = {messageToShow2}
                    messageToShow3 = {messageToShow3}
                    costOfDelivery = {costOfDelivery}
                    lengthArr = {arrNotResults.length}
                    publish = {publishWithCost0}
                    allWithReason = {allWithReason}
                />
                :''
            }

            {
                showPopUpChangePrice ?
                <PopUpChangePrice hide = {()=>setShowPopUpChangePrice(false)} index1={index1ArrMain} index2={index2ArrMain}/>
                :''
            }
        </>
    )
}

export default Results