import React, {useState, useEffect} from "react";
import Navbar from "../Components/Navbar";
import { GetCurrencies } from '../Hooks/ValCurrencyHooks'
import { updateCurrency } from "../Data/CurrencyServices";
import { useNavigate } from "react-router-dom";

const ChangeCurrency = () => {

    const navigate = useNavigate()

    let currenciesArr = GetCurrencies()
    //console.log(typeof(currenciesArr))

    const [ newVal,setNewVal ] = useState()
    const [ indexEdit, setIndexEdit ] = useState()
    const [ isEdit, setIsEdit ] = useState(false)

    const saveNewVal = async () => {
        let newVal1 =parseInt(newVal)
        console.log(typeof(newVal1))
        let currencyDummy = {
            name: currenciesArr[indexEdit].name,
            usd: newVal1
        }
        try {
            await updateCurrency(currenciesArr[indexEdit].id,currencyDummy)
            setIsEdit(false)
            currenciesArr.map((item,index)=>{
                
                    document.getElementById("val-Usd-" + index.toString() ).disabled = false
                
            })
        } catch(error) {
            console.log(error)
        }
    }

    const changeValAndBottunActive = (e, index) => {
        //let button = document.getElementById("button-Save-Active-" + index.toString() )
        let valId = currenciesArr[index].id
        setIndexEdit(index)
        setNewVal(e.target.value)
        setIsEdit(true)
        console.log(isEdit)
        currenciesArr.map((item,index)=>{
            if(valId!==item.id) {
                document.getElementById("val-Usd-" + index.toString() ).disabled = true
            }
        })
    }

    return(
        <>
            <Navbar/>
            <div id="containerChangeCurrencies">
                <h2 className="text-center">Valores de Cambio</h2>
                {
                    currenciesArr.map((item,index) =>   
                       <>    
                            <div key={item.id} className="d-flex justify-content-center align-items-center p-3" id="div-Currency">
                                <p id="p-Name-Supplier">{item.name}:</p>
                                <input id={"val-Usd-"+index.toString()} 
                                    className="form-control val-Usd" 
                                    type="number" 
                                    defaultValue={item.usd}
                                    onChange = { e => { changeValAndBottunActive(e,index) }}
                                />
                                
                            </div>                                
                      </>                         
                    )
                }

                {
                    isEdit ?
                    <button 
                        className="button-Save-Active" 
                        onClick={()=>{saveNewVal()}}
                    >
                        Guardar
                    </button>
                    :
                    <button 
                        className="button-Save-Desactive"
                    >
                        Guardar
                    </button>
                }        
            </div>
        </>
    )
}

export default ChangeCurrency