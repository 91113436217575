import React from "react";//*module that starts react is imported
import { useState } from "react";
import { useAuth } from "../context/authContext";
import { useNavigate } from 'react-router-dom'

const Register = () => {

    //extraction of external objects
    const {signUp} = useAuth() //*this use for register a new user
    const navigate = useNavigate()//* this use for a navegate on the pages

    //VARIABLES
    //*save user data to register
    const [user, setUser] = useState ({
        email: '',
        password: ''
    })
    const [error, setError] = useState()

    //FUNCTIONS
    const handeleChangeUserData = ({target: {name, value}}) => {
        setUser({...user, [name]: value})
    }

    const sendingData = async (e) => {
        e.preventDefault()
        try {
            await signUp(user.email, user.password)
            navigate('/')
        } catch (error) {
            setError(error.message)
        }        
    }

    //Render of page
    return( 
        <div>
            {error && <p>{error}</p>}
            <form onSubmit={sendingData}>

                <label htmlFor="email">Email</label>
                <input 
                    type="email" 
                    name="email" 
                    placeholder="correo@voltz.com"
                    onChange={handeleChangeUserData}
                />

                <label htmlFor="password">Contraseña</label>
                <input 
                    type="password" 
                    name="password" 
                    id="password"
                    onChange={handeleChangeUserData}
                />

                <button>Register</button>
            </form>
        </div>
    )
}

export default Register //*module export, to be used