import React, { useRef, useState } from "react";
import ClipboardJS from "clipboard";

const CopyLink = ({ text }) => {
    const buttonRef = useRef(null);
    const [ copied, setCopied ] = useState(false)

    const handleCopy = () => {
        const clipboard = new ClipboardJS(buttonRef.current, {
            text: () => text
        });

        clipboard.on('success', (e) => {
            setCopied(true);
            clipboard.destroy();
            setTimeout(() => {
                setCopied(false)
            }, 2000);
        });

        clipboard.on('error', (e) => {
            console.log('No se ha copiado el link', e.trigger)
            clipboard.destroy();
        });

        clipboard.onClick({ currentTarget: buttonRef.current });

    }

    return (
        <>
            <button className={copied ? "button-result button-result-copied" : "button-result button-result-normal "} ref={buttonRef} onClick={handleCopy}>
                <i className={copied ? "bi bi-clipboard-check icon-checked" : "bi bi-clipboard"}></i>
            </button>
            {
                copied ?
                <div className="background-copied">
                    <div>
                        <p>Url copiado al portapapeles</p>
                    </div>
                </div>
                : ''
            }
        </>
    );

}

export default CopyLink;