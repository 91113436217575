import {db} from '../fireBase'
import {
    query,
    orderBy,
    onSnapshot,
    collection,
    limit, 
    where,
    getDoc,
    updateDoc,
    doc,
    serverTimestamp,
    getDocs
} from 'firebase/firestore'

export const streamQuoteDetail = (snapshot, error) => {
    const itemsColRef = collection(db,'quote-detail')
    const itemsQuery = query(itemsColRef, orderBy("created_at", "desc"))
    return onSnapshot(itemsQuery,snapshot,error)
}