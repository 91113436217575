import { useState, useEffect } from "react";
import { streamOrdersItems} from "../Data/OrderesServices";

export function GetOrders () {
    const [ orders, setOrders ] = useState([])

    useEffect(() => {
        const unsubscribe = streamOrdersItems(
            (querySnapshot) => {
                const items = []
                querySnapshot.forEach(doc => {
                    const data = doc.data()
                    items.push({...data, id: doc.id})
                });
                setOrders(items)
            }
        )
        return () => unsubscribe()
    },[])
    return orders
}