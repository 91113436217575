import React, {useState, useContext} from "react";
import { informationOfSearch } from "../context/informationOfSearch";
import { useNavigate } from "react-router-dom";

const PopUpDataPaste = (props) =>{

    const navigate = useNavigate()

    const { asignDataManual } = useContext ( informationOfSearch )
    const [ textPaste, setTextPaste ] = useState()
    const [ arrayOfItems, setArrayOfItem ] = useState([])

    const startSeach = () =>{
        let itemOfSearch = ''
        let arrayDummy = []
        //console.log(e)
        for(var i=0; i < textPaste.length; i++){
            if(textPaste[i]!=="\n") { //console.log("se puede")
                itemOfSearch = itemOfSearch + textPaste[i]
            } else {                
                arrayDummy.push(itemOfSearch)
                itemOfSearch = ''
            }
            if(i >= textPaste.length-1){
                //console.log("entramos al final")
                setArrayOfItem(arrayDummy)
            }
        }
        console.log(arrayDummy)
    }


    const goToManualData = () => {
        console.log(arrayOfItems)
        props.setArrDummyItems(arrayOfItems)
        //navigate('/DatosManuales')
        props.close()
    }

    return(
        <>
            <div className="modal pt5" id="modalStyle">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header d-flex flex-wrap">
                            <textarea id="inputData" onChange={e=>{
                                console.log("entramos")
                                //console.log(e.target.value)
                                setTextPaste(e.target.value)
                                startSeach()
                            }}/>
                            <button id="buttonAddData" onClick={
                                ()=>{
                                    goToManualData()
                                }
                            }>
                                Cargar datos
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )    
}

export default PopUpDataPaste