import React, { useContext, useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import PopUpStats from "../Components/PopUpStats";
import { informationOfSearch } from "../context/informationOfSearch";
import uuid from "react-uuid";
import { useNavigate } from 'react-router-dom'
import PopUpDataPaste from "../Components/PopUpDataPaste";
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";

const ManualData = () => {

    //VARIABLES
    //*Singletons
    const { manualSearch, dataManual, manualSearchDummy } = useContext( informationOfSearch )
    const { arrItemsToSearch, setArrItemsToSearch } = useContext(functionsToImplementNewStructure)
    const navigate = useNavigate()
    const [ showSpan, setShowSpan] = useState( false )
    const [ popUpActive, setPopUpActive ] = useState (false)
    const [ arrDummyItems, setArrDummyItems ] = useState([])
    const [ arrDataSearch, setArrDataSearch ] = useState([{
        requested_product: '',
        position: 1,
        status: "to_search",
        requestedAmount: 1
    }])
    const [ isManual, setIsManual ] = useState(false)
    
    useEffect(()=>{
        //console.log(arrDataSearch[0].nameItem)
        if(arrDummyItems.length>0){
            console.log(arrDataSearch)
            if(arrDataSearch[0].nameItem===''){
                let arrDummy = []
                console.log(arrDataSearch)
                arrDummyItems.map((item, index)=>{
                    //console.log(item)
                    arrDummy.push({
                        requested_product:item,
                        position: index+1, 
                        id: uuid(),
                        requestedAmount: 1,
                        status: "to_search"
                    })
                })
                setArrDataSearch(e=>{
                    e.splice(0)
                    return arrDummy
                })
            }else{
                let arrDummy=[]
                arrDummy.push({
                    requested_product: arrDataSearch[0].nameItem,
                    position: 1, 
                    id: uuid(),
                    requestedAmount: 1,
                    status: "to_search"
                })
                arrDummyItems.map((item, index)=>{
                    //console.log(item)
                    arrDummy.push({
                        requested_product:item,
                        position: index+2, 
                        id: uuid(),
                        requestedAmount: 1,
                        status: "to_search"
                    })
                })
                setArrDataSearch(e=>{
                    e.splice(0)
                    return arrDummy
                })
            }
        }        
    },[arrDummyItems])

    //console.log(dataManual)

    //FUNCTIONS 
    const changeVal = (val,index,from) =>{
        if(from==="name") {
            arrDataSearch[index].requested_product=val.target.value
        }else if(from==="quantity"){
            arrDataSearch[index].requestedAmount=parseInt(val.target.value)
        }
    } 

    const launchAddRow = () => {
        let num = arrDataSearch.length + 1
        let dummy = {requested_product: '', id: uuid(), position: num, requestedAmount: 1, status: "to_search"}
        setArrDataSearch([...arrDataSearch, dummy])
    }

    const launchRemoveRow = (index) => {
        let dummy = arrDataSearch.filter(e=>
            e.id!==arrDataSearch[index].id
        )
        setArrDataSearch(dummy)
    }

    return(
        <>
            <Navbar funcionCotizacion={()=>{navigate('/')}}/>
            <div className="d-flex justify-content-between align-items-center" id="containerButtons">
                <button id="buttonManualDataReturnPage" onClick={()=>{
                    navigate(0)
                }}>
                    <i className="bi bi-chevron-left"></i>
                    Volver
                </button>
                {
                    showSpan?
                    <span>Ingresa al menos un Item a buscar</span>
                    :''
                }
                <button id="buttonManualDataSearch" onClick={()=>   setArrItemsToSearch(arrDataSearch.filter(item => item.nameItem !== ''), setPopUpActive(true)) }>
                    Buscar                    
                </button>
            </div>
            {
                <div id="containerBodyManualData">
                    <div id="containerTittleManualData" className="d-flex">
                        <p id="tittle1ManualData">Cotización</p>
                        <p id="tittle2ManualData">manual</p>
                    </div>
                    <div id="containerInputsManualData">
                        {
                            arrDataSearch.map((item,index) => (
                                //<p onClick={()=>{launchRemoveRow(index)}}>hola{item.nameItem}</p>
                                <div key={item.id} className="form-group containerInputManualData d-flex">
                                    <span className="bi bi-box-fill form-control-ManualData"></span>
                                    
                                    {
                                        item.requested_product ?
                                            <input 
                                                type="text"
                                                //placeholder="Escribe el producto a SKU que buscas"                             
                                                defaultValue={item.requested_product}
                                                className="form-control width80"
                                                id="inputDataManual"
                                                onChange={e=>{changeVal(e,index,"name")}}
                                            />
                                        :                                        
                                            <input 
                                                type="text"
                                                placeholder="Escribe el producto a SKU que buscas"                                
                                                //defaultValue={item.nameItem}
                                                className="form-control width80"
                                                id="inputDataManual"
                                                onChange={e=>{changeVal(e,index,"name")}}
                                            /> 
                                    }
                                    <span className="px-2 text-center">Cantidad solicitada</span>
                                    <input
                                        type="number"
                                        className="width10 text-center borderForm"
                                        defaultValue={item.requestedAmount}
                                        onChange={e=>{changeVal(e,index,"quantity")}}
                                    />                  
                                    <button id="buttonTrashManualData" className="btn" onClick={()=>{launchRemoveRow(index)}}>
                                        <span className="bi bi-trash-fill"></span>
                                    </button>
                                </div>
                            ))
                        }
                        <div className="d-flex justify-content-around">
                            <button id="buttonAddRow" onClick={launchAddRow}>
                                <i id="iconPlusManualData" className="bi bi-plus-circle"></i>
                                Agregar fila
                            </button>
                            <button id="buttonAddRow" onClick={()=>setIsManual(true)}>
                                <i id="iconPlusManualData" className="bi bi-clipboard"></i>
                                Copy / Paste
                            </button>
                        </div>
                        
                    </div>
                
                </div>
            }
            {
                popUpActive === true ?
                <PopUpStats hide={() => setPopUpActive(false)}/> :
                ''
            }
            {
                isManual ?
                <PopUpDataPaste close={()=>{setIsManual(false)}}
                    setArrDummyItems={setArrDummyItems}
                />
                :''
            }
        </>
    )
}

export default ManualData