import React, { useContext, useState } from "react";
import { informationOfSearch } from "../context/informationOfSearch";
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";
import { changeReasonToDiscarded, moveDiscardedToPending } from "../Data/newQuoteServices";

const TableDiscarted = (props) => {

    //*Singleton
    const { arrDiscarted, idQuote } = useContext ( functionsToImplementNewStructure )

    return(
        <>
            <div className="containerTables">
                <p id="tittleDiscarted"> Descartados ({arrDiscarted.length}) </p>
                <p id="subTittleDiscarted"> Productos que solicitó el cliente y Voltz no puede o quiere cotizar </p>
                <div id="containerTableDiscarted">
                    <table className="table table-borderless">
                        <thead id="headTableDiscarted">
                            <tr>
                                <th className="text-center" scope="col">#</th>
                                <th scope="col">PRODUCTO</th>
                                <th scope="col" className="text-center">CAUSAL</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody className="tableBody">
                            {
                                arrDiscarted.map((item,index) => (
                                    <tr>
                                        <td className="text-center" id="positionDiscarted"> { item.position } </td>
                                        <td id="itemNameDiscarted"> { item.requested_product } </td>
                                        <td className="d-flex justify-content-center align-items-center">
                                            <div className="dropdown">
                                                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" id="buttonToggleDiscarted">
                                                    {item.reason}
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right">
                                                    <button className="dropdown-item" type="button" onClick={()=>changeReasonToDiscarded(idQuote,item.id,"Descontinuado")}>Descontinuado</button>
                                                    <button className="dropdown-item" type="button" onClick={()=>changeReasonToDiscarded(idQuote,item.id,"No Encontrado")}>No Encontrado</button>
                                                    <button className="dropdown-item" type="button" onClick={()=>changeReasonToDiscarded(idQuote,item.id,"Otros")}>Otros</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center indexSup dropdown" id="points"> 
                                            <button className="buttonPoints btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots-vertical"/>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li class="dropdown-item opstionsResult" onClick={()=>{moveDiscardedToPending(idQuote,item.id)}}>
                                                    Mover a pendientes
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default TableDiscarted