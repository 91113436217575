import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    /*apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID*/
    apiKey:"AIzaSyBHoRvL7-K2OJdBIssJAMXOqd00xSnEJNw",
    authDomain:"voltz-pro.firebaseapp.com",
    projectId:"voltz-pro",
    storageBucket:"voltz-pro.appspot.com",
    messagingSenderId:930873677724,
    appId:"1:930873677724:web:68c8143502ea3604c39a59"
}

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);