import React, {useState} from "react";
import { useAuth } from "../context/authContext";
import logo from '../Img/Logo.png'
import { useNavigate } from 'react-router-dom'
import PopUpStatsVentas from "./PopUpStatsVentas";
import PopUpNewStatsVentas from "./PopUpNewStatsVentas";

const Navbar = (props) => {

    //#region //*extraction of external objects
    const {user, isLogin,logOut, handleIsLogin} = useAuth()

    const navigate = useNavigate()

    const [ openStats, setOpenStats ] = useState(false)
    const [ openNewStats, setOpenNewStats ] = useState(false)
    const sendLogOut = async () => {
        await logOut()
        handleIsLogin()
    }
    
    return(
        <>
            <nav id="containerNavBar" className="navbar">
                <div className="container-fluid p-2">
                    <div style={{padding: "0px 54px"}}>
                        <a href="/">
                            <img src={logo} alt="LOGO" width="130"/>
                        </a>
                    </div>
                    {
                        isLogin === true || user ?
                            <div className="d-flex">
                                <div id="containerButtons1">
                                    {
                                        user.email==="juanmanuel@voltz.mx" ?
                                        <button onClick={()=>{navigate('/modification')}} className="stylebutton1">
                                            Modificación
                                        </button>:
                                        ''
                                    }
                                    {
                                        user.email==="juanmanuel@voltz.mx"||user.email==="checho@voltz.mx" ?
                                        <button onClick={()=>{navigate('/changeCurrency')}} className="stylebutton1">
                                            Configuración
                                        </button>:
                                        ''
                                    }
                                    {/*
                                        <button className="stylebutton1" onClick={()=>{setOpenStats(true)}}>
                                        Estadísticas
                                    </button>
                                    <button className="stylebutton1" onClick={()=>{setOpenNewStats(true)}}>
                                       N Estadísticas
                                    </button>*/
                                    }
                                    
                                    <button onClick={()=> {navigate('/users')}}className="stylebutton1">
                                        Clientes
                                    </button>
                                    <button onClick={()=> {navigate('/catalog')}} className="stylebutton1">
                                        Catálogo
                                    </button>
                                    <button className="stylebutton1" onClick={ () => {navigate('/reviewQuotes')}}>
                                        Cotizaciones
                                    </button>
                                    <button className="stylebutton1" onClick={ () => {navigate('/orders')}}>
                                        Pedidos
                                    </button>
                                </div>
                                <div id="containerButtons2">
                                    <button className="stylebutton2" 
                                        onClick={sendLogOut}
                                    >
                                        <i id="iconLogOut" className="bi bi-box-arrow-in-right"></i>
                                        Cerrar sesión
                                    </button>
                                </div>
                            </div>
                        : <div className="d-flex"></div>
                    }
                </div>
            </nav>
            {
                openStats ?
                    <PopUpStatsVentas close={()=>{setOpenStats(false)}}/>
                :
                    ''
            }
            {
                openNewStats ?
                    <PopUpNewStatsVentas close={()=>{setOpenNewStats(false)}}/>
                :
                    ''
            }
        </>
    )
}

export default Navbar