import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { informationOfExport } from "../context/informationOfExport";
import { informationOfSearch } from "../context/informationOfSearch";
import { getCompany } from "../Data/CompanyServices";
import { GetCompanies, GetUsers } from "../Hooks/CompanyHooks";
import PopUpNewUsers from "../Components/PopUpNewUsers"
import InfiniteScroll from "react-infinite-scroll-component";
import { useRef } from "react";
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";


const PopUpSearchClient = (props) => {
    const navigate = useNavigate()
    //const users = GetUsers()

    const { setIdZoho, setCategory, setIdClient } = useContext(informationOfExport)
    const { setClientOfReview } = useContext(informationOfSearch)
    const { arrClients, serchClientsAlgolia } = useContext(functionsToImplementNewStructure)

    const [wordToFilter, setWordToFilter] = useState()
    const [arrInitial, setArrInitial] = useState([])
    const [openCreateNewUser, setOpenCreateNewUser] = useState(false)
    const [ready, setReady] = useState(true)
    const [arrMain, setArrMain] = useState([])
    const [displayedItems, setDisplayedItems] = useState([]);
    const [displayIndex, setDisplayIndex] = useState(10);
    const scrollContainerRef = useRef(null);

    /*useEffect(() => {
        //console.log(users)
        users.map(async (item, index) => {
            //console.log(item.company_id)
            if (item.company_id !== null) {
                const infoCompany = await getCompany(item.company_id)
                //console.log(infoCompany)
                users[index].infoCompany = infoCompany
                users[index].status = "employee"
                //console.log(users[index])
            }
            if (item.email === null) users[index].email = ""
            if (item.phone_1 === null) users[index].phone_1 = ""
            if (index + 1 >= users.length) {
                //console.log(users)
                setArrInitial(users)
                setArrMain(users)
                setDisplayedItems(users.slice(0, displayIndex));
                setReady(true)
            }
        })

    }, [users])

    const filter = (e) => {
        setWordToFilter(e.target.value.toUpperCase())
        let val = e.target.value.toUpperCase()
        console.log(arrInitial, val)
        let arrDummy = arrMain.filter(user => {
            if (user.infoCompany) {
                if (
                    user.full_name.toUpperCase().includes(val) ||
                    user.phone_1.includes(val) ||
                    user.email.includes(val.toLowerCase()) ||
                    user.infoCompany.name.toUpperCase().includes(val)
                ) return user
            } else {
                if (
                    user.full_name.toUpperCase().includes(val) ||
                    user.phone_1.includes(val) ||
                    user.email.includes(val.toLowerCase())
                ) return user
            }
        })

        setArrInitial(arrDummy);
        setDisplayedItems(arrDummy.slice(0, displayIndex));
    }*/

    const selectOrEdit = async (item) => {
        if (props.fromNewQuote === true) {
            console.log(item)
            let name = "full_name" in item ? item.full_name : item.name
            let category = item.category
            let id = "companies" in item || "company_id" in item ? item.objectID : item.persons[0]
            setIdZoho(name)
            setCategory(category)
            setIdClient(id)
            props.setHasIdZoho()
            props.changeHasInfo()
            props.hide()
            /*if (item.status === "independent") {
                console.log(item)
                setIdZoho(item.full_name)
                setCategory(item.category)
                setIdClient(item.id)
                props.setHasIdZoho()
                props.changeHasInfo()
                props.hide()
            } else {
                console.log("false: ",item)
                setIdZoho(item.name)
                setCategory(item.category)
                setIdClient(item.id)
                props.setHasIdZoho()
                props.changeHasInfo()
                props.hide()
            }*/
        } else {
            //console.log(item)
            setClientOfReview(item)
            navigate('/checkClient')
            props.hide()
        }
    }

    /*const fetchMoreData = () => {
        setTimeout(() => {
            const newIndex = displayIndex + 10;
            const newItems = arrInitial.slice(0, newIndex);
            setDisplayIndex(newIndex);
            setDisplayedItems(newItems);
        }, 300);
    };
    //setArrInitial(onlyUsers)
    //console.log(onlyUsers)

    const handleScroll = (event) => {
        const target = event.target;
        const atBottom =
            target.scrollHeight - target.scrollTop === target.clientHeight;

        if (atBottom) {
            fetchMoreData();
        }
    };*/

    console.log(arrClients)
    return (
        <>
            <div className="modal pt5 sticky" id="modalStyle">
                <div className="modal-dialog modalSearchClient">
                    <div className="modal-content containerSearchClient">
                        <div className="modal-header p-0 d-flex flex-wrap headerSearchClientMain">
                            <div className="width100 headerSearchClient justify-content-around d-flex align-items-center">
                                <p className="tittleSearchClient">Asigna un cliente a la cotización</p>
                                <button className="btn buttonCloseSearch" onClick={() => { props.hide() }}>
                                    Cerrar
                                </button>
                            </div>
                            <div className="width100 d-flex align-items-center containerInputSearch">
                                <span className="bi bi-search ml-5 iconInInput"></span>
                                <input type="text" className="inputSearchClient"
                                    placeholder="Buscar persona, empresa, teléfono, email"
                                    onChange={(e) => {
                                        serchClientsAlgolia(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-body containerTagsOfClients"
                        style={{ height: "400px", overflowY: "auto" }}>
                            {
                                ready ?
                                    arrClients.map(item => (
                                        <div key={item.id} className="d-flex tagUser align-items-center"
                                        onClick={() => {
                                            selectOrEdit(item)
                                        }}
                                        >
                                            {
                                                //item.status==="employee" && item.infoCompany === undefined ? console.log(item.status, item.infoCompany.name, item) : ''

                                                item.number_person ?
                                                    <p className="width33 text-center textBoldBlack text-danger">

                                                        {item.number_person}
                                                    </p>//console.log(item.infoCompany,item.id, item)
                                                    :
                                                    <p className="width33 text-center textBoldBlack text-danger">
                                                        {item.number_company}
                                                    </p>
                                            }
                                            <div className="width33">
                                                {
                                                    item.number_person  ?
                                                        <p className="textBoldBlack m-0">{item.full_name}</p>
                                                        :
                                                        <p className="textBoldBlack m-0">{item.name}</p>
                                                }
                                            </div>
                                            <div className="width33 m-5">
                                                {
                                                    item.phone_1 !== null ?
                                                        <p className="textBoldBlack m-0">{item.phone_1}</p>
                                                        :
                                                        <p className="textBoldBlack m-0 text-danger">Falta llenar este dato</p>
                                                }
                                                {
                                                    item.email !== "" ?
                                                        <p className="textBoldBlack m-0">{item.email}</p>
                                                        :
                                                        <p className="textBoldBlack m-0 text-danger">Falta llenar este dato</p>
                                                }
                                            </div>
                                        </div>
                                    ))
                                :
                                <h1>CARGANDO .....</h1>
                            }
                        </div>
                        <div className="modal-footer containerFooterOfClients">
                            <button className="buttonCreateNewClientSearchClient"
                                onClick={() => {
                                    //props.hide()
                                    setOpenCreateNewUser(true)
                                    //navigate("/createUser")
                                }}
                            >
                                Crear nuevo cliente
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {
                openCreateNewUser ?
                    <PopUpNewUsers hide={() => { setOpenCreateNewUser(false) }} />
                    :
                    ''
            }
        </>
    )
}

export default PopUpSearchClient