import React, { useState, useContext } from "react";
import { serverTimestamp } from "firebase/firestore";
import { informationOfExport } from "../context/informationOfExport";
import { addNewUserAndNewCompany, addOnlyNewClientCompany, addOnlyNewClientUser } from "../Data/CompanyServices";
import { GetCompanies } from "../Hooks/CompanyHooks";

const PopUpFondo = (props) => {

    const users = GetCompanies()
    //console.log(users)

    const [ existCompany, setExistCompany ] = useState(true)
    const [ isNecessary, setIsNecessary ] = useState(true)
    const [ categoryClient, setCategoryClient ] = useState("Elige Categoría")
    const [ canPublish, setCanPublish ] = useState(false)
    const [ whoIsEnrolled, setWhoIsEnrolled ] = useState()
    const [ hasCompany, setHasCompany ] = useState(false)
    const [ companySelect, setCompanySelect ] = useState()
    const [ mapNewUser, setMapNewUser ] = useState({ 
        first_name: null,
        second_name: null,
        last_name: null,
        phone_1: null,
        ext_phone_1: null,
        email: null,
        company_id: null,
        companies: null,
        category: null
    })
    const [ mapNewCompany, setMapNewCompany ] = useState()

    const updateUser = (name, value) => {
        //console.log(name)
       /* var val2
        if(name==="phone_1") val2=value.toString()
        users.some(user=> user.users[0].phone === val2) ? setWhoIsEnrolled("Teléfono ya registrado") : setWhoIsEnrolled()*/
        //users.some(user=>  user.users[0].email===value) ? setWhoIsEnrolled("Correo ya registrado") : setWhoIsEnrolled()
        //console.log(users)
        setMapNewUser({...mapNewUser,[name]:value})
    }

    const verificationOneName = (val) =>{
        var val2 = val.split(" ")
        if(val2.length>1){
            mapNewUser["first_name"] = val2[0]
            mapNewUser["second_name"] = val2.slice(1).join(" ")
        }else{
            mapNewUser["first_name"] = val2[0]
            mapNewUser["second_name"] = null
        }
        verificationAllFull()
    }

    const verificationAllFull = (val) => {
        //console.log(whoIsEnrolled)
        let hasCompanyDummy
        let count = 0
        typeof(mapNewUser.first_name) === "string" ? count++ : count--
        typeof(mapNewUser.last_name) === "string" ? count++ : count--
        typeof(mapNewUser.phone_1) === "number" ? count++ : count--
        typeof(mapNewUser.email) === "string" ? count++ : count--
        typeof(mapNewUser.category) === "string" ? count++ : count--
        //console.log(count, whoIsEnrolled,hasCompany)
        
        if(val===false || hasCompany===true){
            hasCompanyDummy = true
        }else{
            hasCompanyDummy = false
        }
        if(count >= 5 && hasCompanyDummy===true && typeof(whoIsEnrolled)==="undefined"){
            setCanPublish(true)
        }else{
            setCanPublish(false)
        }
    }

    const auxCreateCompany = (item) => {
        //var exist
        let company ={}
        let isexist = users.some(user=>user.name===item)
        let index = users.findIndex(user=>user.name===item)
        setCompanySelect(users[index])
        let companyExisted = users[index]
        if(isexist===true){
            setExistCompany(true)
            ///MAPEAR CON LOS DATOS DE LA COMPANIOA Y DE AHI HACER SERVICIO
            company.name = companyExisted.name
            company.business_sector = companyExisted.business_sector
            company.created_at = companyExisted.created_at
            company.rfc = companyExisted.rfc
            company.persons = [companyExisted.users[0].id]
            company.id = companyExisted.id
            company.category = company.category
            setHasCompany(true)
        }else{
            setExistCompany(false)
            company.name = item
            setHasCompany(true)
        }
        setMapNewCompany(company)
        console.log(company)
        verificationAllFull()
    }

    const publishCompanyAndUser = async () => {
        if(isNecessary===false){
            const newUser = await addOnlyNewClientUser(mapNewUser,"independent")
            console.log(newUser)
        }else{
            if(existCompany===true){
                //console.log(mapNewCompany.id,mapNewUser)
                const newUser = await addOnlyNewClientUser(mapNewUser, "new-employee" , mapNewCompany)
                //console.log(newUser)
                props.hide()
            }else{
                //console.log("Se creara empresa")
                const newUser = await addOnlyNewClientUser(mapNewUser, "employee")
                //const newCompany = await addOnlyNewClientCompany(mapNewCompany,newUser, mapNewUser)
                //console.log(newUser,newCompany)
                props.hide()
            }
        }
    }

    return(
        <>
            <div className="modal pt5" id="modalStyle">
                <div className="modal-dialog modalDialogNewUser">
                    <div className="modal-content modalContentNewUser">
                        <div className="modal-header headerNewClient d-flex flex-wrap">
                            <p className="tittleNewClient">Crear cliente</p>
                            
                        </div>
                        <div className="modal-body bodyNewUser">
                            <div className="constainerInfo d-flex flex-wrap">
                                <p className="mt-2 width50" id="tittleBody">Datos de la persona</p>
                                {
                                    typeof(whoIsEnrolled)!== "undefined" ?
                                        <label className="width50 text-danger mt-2">{whoIsEnrolled}</label>
                                    : ''
                                }
                                
                                <input type="text" className="ps-3 pb-1 mb-3 inputNewUser" placeholder="Nombres (first_name)"
                                    onChange={(e)=>{
                                        verificationOneName(e.target.value)
                                    }}
                                />
                                <input type="text" className="ps-3 pb-1 inputNewUser" name="last_name" 
                                    placeholder="Apellidos (last_name)"
                                    onChange={(e)=>{
                                        updateUser("last_name",e.target.value)
                                        verificationAllFull()
                                    }}
                                />
                                <div className="mt-3 d-flex justify-content-between">
                                    <label className="pt-2 position-absolute">+52</label>
                                    <input type="text" className="ps-5 inputNewUser width80" name="phone_1"
                                        placeholder="Teléfono a 10 digitos" 
                                        onChange={(e)=>{
                                            updateUser("phone_1", parseInt(e.target.value))
                                            verificationAllFull()
                                    }}
                                        maxLength="10"
                                    />
                                    <input type="text" className="ms-4 text-center inputNewUser width15" name="ext_phone_1"
                                        placeholder="ext."
                                        onChange={(e)=>{
                                            updateUser("ext_phone_1", parseInt(e.target.value))
                                        }}
                                    />
                                </div>
                                <input type="email" className="inputNewUser mt-3 ps-3" name="email"
                                    placeholder="Email (correo)"
                                    onChange={(e)=>{
                                        updateUser("email", e.target.value)
                                        verificationAllFull()
                                    }}
                                />
                                
                                <div className="btn-group width100 mt-3">
                                    <button type="button" className="btn dropdown-toggle" 
                                        data-bs-toggle="dropdown" 
                                        aria-expanded="false"
                                        onClick={()=>{verificationAllFull()}}
                                    >
                                        {categoryClient}
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <button className="dropdown-item" type="button"
                                                onClick={()=>{
                                                    setCategoryClient("Categoría A")
                                                    mapNewUser["category"] = "A"
                                                    verificationAllFull()
                                                }}
                                            >
                                                Categoría A
                                            </button>
                                        </li>
                                        <li>
                                            <button className="dropdown-item" type="button"
                                                onClick={()=>{
                                                    setCategoryClient("Categoría B")
                                                    mapNewUser["category"] = "B"
                                                    verificationAllFull()
                                                }}
                                            >
                                                Categoría B
                                            </button>
                                        </li>
                                        <li>
                                            <button className="dropdown-item" type="button"
                                                onClick={()=>{
                                                    setCategoryClient("Categoría C")
                                                    mapNewUser["category"] = "C"
                                                    verificationAllFull()
                                                }}
                                            >
                                                Categoría C
                                            </button>
                                        </li>
                                        <li>
                                            <button className="dropdown-item" type="button"
                                                onClick={()=>{
                                                    setCategoryClient("Categoría D")
                                                    mapNewUser["category"] = "D"
                                                    verificationAllFull()
                                                }}
                                            >
                                                Categoría D
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="mt-3 secondPartContainerInfo d-flex justify-content-between">
                                <p className="mt-2 width40" id="tittleBody">Datos de la empresa</p>
                                <div className="form-check form-switch form-check-reverse mt-2">
                                    <input className="form-check-input" 
                                        type="checkbox" 
                                        id="flexSwitchCheckReverse"
                                        onChange={()=>{
                                            setIsNecessary(!isNecessary)
                                            verificationAllFull(!isNecessary)
                                        }}
                                    />
                                    
                                </div>
                            </div>
                            {
                                isNecessary ?
                                    <>
                                        <input className="form-control" list="datalistOptions" 
                                            name="datalistOptions"
                                            id="exampleDataList" 
                                            placeholder="Busca la empresa"
                                            onClick={()=>{}}
                                            onChange={(e)=>{auxCreateCompany(e.target.value)}}
                                        />
                                        <datalist id="datalistOptions">
                                            {
                                                users.map((item,index)=>(
                                                    <option value={item.name} label={item.name}/>
                                                ))
                                            }
                                            <option label="No existe"/>
                                        </datalist>
                                    </>
                                :
                                    <input className="form-control" 
                                        disabled
                                        placeholder="Buscar empresa"
                                    />
                            }
                        </div>
                        <div className="modal-footer d-flex justify-content-around footerNewUser">
                            <button
                                onClick={()=>{props.hide()}}
                                id="buttonCancel"
                            >
                                Cancelar
                            </button>
                            {
                                canPublish ? 
                                    <button
                                        id="buttonCanPublish"
                                        onClick={()=>{
                                            //verificationAllFull()
                                            //console.log(mapNewUser)
                                            //console.log(mapNewCompany)
                                            publishCompanyAndUser()
                                        }}
                                    >
                                        Crear
                                    </button>
                                :
                                    <button 
                                        disabled
                                        id="buttonCanPublishDisabled"    
                                    >
                                        Crear
                                    </button>
                            }                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopUpFondo