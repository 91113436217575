import React from 'react';
import { useAuth } from '../context/authContext';
import { useNavigate } from 'react-router-dom'

const ProtectedRoute = ({children}) => {
    const navigate = useNavigate()
    const { user, loading } = useAuth()

    if (loading) navigate('/login')

    if (!user)  navigate('/login')

    return <>{children}</>
}

export default ProtectedRoute