import React, { useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { informationOfSearch } from "../context/informationOfSearch";
import moment from "moment";

import {
  deleteClient,
  getAllCompany,
  getCompany,
  getUser,
} from "../Data/CompanyServices";
import { GetCompanies, GetUsers } from "../Hooks/CompanyHooks";
import InfiniteScroll from "react-infinite-scroll-component";

import Navbar from "../Components/Navbar";
import PopUpNewUsers from "../Components/PopUpNewUsers";
import PopUpSearchClient from "../Components/PopUpSearchClient";

const Users = () => {
  const users = GetUsers();
  const companies = GetCompanies();
  const navigate = useNavigate();

  const { setClientOfReview } = useContext(informationOfSearch);

  const [addNewUserActive, setAddNewUserActive] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [ready, setReady] = useState();
  const [isCompany, setIsCompany] = useState();
  const [arrCompanies, setArrCompanies] = useState([]);
  const [arrUsers, setArrUsers] = useState([]);
  const [arrInitial, setArrInitial] = useState([]);
  const [page, setPage] = useState(1);
  const [paginatedUsers, setPaginatedUsers] = useState([]);

  useEffect(() => {
    checkInfoTheArrs();
  }, [users]);

  useEffect(() => {
    checkInfoTheArrs();
  }, [companies]);

  /*useEffect(()=>{
        console.log(isCompany)
        isCompany === true ? setArrInitial(arrCompanies) : setArrInitial(arrUsers)
    },[isCompany])*/

  useEffect(() => {
    const newUsers = users.slice(0, 15 * page);
    setPaginatedUsers(newUsers);
  }, [page, users]);

  const loadMoreUsers = () => {
    setPage(page + 1);
  };

  const checkInfoTheArrs = () => {
    //console.log(users.length, companies.length)
    if (companies.length > 0 && users.length > 0) {
      users.map(async (item, index) => {
        //console.log(item.company_id)
        if (item.company_id === null) {
          users[index].type = "person";
          users[index].status = "independent";
          //console.log(item)
          //dummyUsers.push(item)
        } else {
          users[index].type = "person";
          users[index].status = "employee";
          const infoCompany = await getAllCompany(item.company_id);
          //arrCompanyDummy.push(infoCompany)
          //users[index].infoCompany = users[index].infoCompany.push(infoCompany)
          users[index].infoCompany = infoCompany;
          //users[index].infoCompany = arrCompanyDummy
        }
        //console.log(item.company_id)
        //console.log(users)
        setArrUsers(users);
      });
      companies.map((item, index) => {
        let arrUserDummy = [];
        item.persons.map(async (item2, index2) => {
          const infoUser = await getUser(item2);
          arrUserDummy.push(infoUser);
          //companies[index].infoUser = users[index].infoUser.push(infoUser)
          companies[index].infoUser = arrUserDummy;
          companies[index].type = "company";
          setArrCompanies(companies);
        });
      });
      setArrInitial(companies);
      setIsCompany(true);
      setReady(true);
      //console.log(users)
      //console.log(companies)
      //console.log(dummyUsers)
    }
  };

  //console.log(arrCompanies)

  return (
    <>
      <Navbar />
      <div
        className="d-flex align-items-center justify-content-around"
        id="containerBottonsUsers"
      >
        <div>
          <button
            className="m-3 buttonUsersBlue"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </button>
          <button
            className="m-3 buttonUsersBlue"
            onClick={() => {
              setAddNewUserActive(true);
            }}
          >
            Crear cliente
          </button>
        </div>
        {isCompany ? (
          <div>
            <button className="buttonUsersWhite" disabled>
              Empresas
            </button>
            <button
              className="buttonUsersBlue"
              onClick={() => {
                setIsCompany(false);
                setArrInitial(arrUsers);
              }}
            >
              Personas
            </button>
          </div>
        ) : (
          <div>
            <button
              className="buttonUsersBlue"
              onClick={() => {
                setIsCompany(true);
                setArrInitial(arrCompanies);
              }}
            >
              Empresas
            </button>
            <button className="buttonUsersWhite" disabled>
              Personas
            </button>
          </div>
        )}

        {
          <button
            className="buttonUsersBlue"
            onClick={() => {
              setIsSearch(true);
            }}
          >
            Buscar cliente
          </button>
        }
      </div>

      <div className="d-flex width100 flex-wrap">
        {ready ? (
          <InfiniteScroll
          className="d-flex width100 flex-wrap"
            dataLength={paginatedUsers.length}
            next={loadMoreUsers}
            hasMore={paginatedUsers.length < users.length}
            loader={<h4>Cargando...</h4>}
            endMessage={<p style={{ textAlign: "center" }}></p>}
          >
            {paginatedUsers.map((data, index) => (
              <div key={data.id} className="width20 cardQuote">
                {data.type === "company" ? (
                  <>
                    <span id="LabelAlias">{data.name}</span>
                    <br></br>
                  </>
                ) : (
                  <>
                    <span id="labelAlias">{data.full_name}</span>
                    <br></br>
                  </>
                )}
                <span id="labelContent">
                    {
                        data.type === "company" ?
                            <>
                                <span>{data.infoUser.full_name}</span>
                            </>
                        :
                            data.status === "employee" ?
                            <>
                                <span>{data.infoCompany ? data.infoCompany.name : "desconocido"}</span><br></br>
                            </>
                            :
                            <>
                                <span>Independiente.</span><br></br>
                            </>
                    }
                    <span>{data.phone_1}</span><br></br>
                    <span>{data.city}</span><br></br>
                </span>
                <span id='labelContent'>
                    {
                        data.created_at!==null ? 
                        <span>{moment(data.created_at.toDate()).local().format("YYYY-MM-DD HH:mm")}</span> :
                        <span></span>
                    }
                </span>
                <span id="labelContent">
                                    {
                                        data.type === "company" ?
                                            <> 
                                                <span>Empresa: {data.number_company}</span><br></br>
                                            </>
                                        :
                                            <>
                                                <span>Cliente: {data.number_person}</span><br></br>
                                            </>
                                    }
                                </span>
                                                    
                                    <div style={{marginTop: "12px"}} className='d-flex wrap'>
                                        <div id='consecutiveContainer-Review'>
                                            <span id='consecutiveNumber-Review'>
                                                CAT: {data.category}
                                            </span>
                                        </div>
                                        <div id='cardDecoration-Review' className='d-flex justify-content-around'>
                                            <button id='cardDecorationArrow-Review'
                                                onClick={()=>{
                                                    setClientOfReview(arrInitial[index])
                                                    navigate('/checkClient')
                                                }}
                                                className='btn'
                                                target="_blank"
                                            >
                                                <i className="fa-solid fa-arrow-right fa-sm	" ></i>
                                            </button>
                                            <button className="ms-3 btn btn-danger"
                                                onClick={()=>{try {
                                                    //deleteClient(data.id, data.infoCompany.id)
                                                } catch (error) {
                                                    console.log(error)
                                                }}}
                                            > 
                                                Borrar 
                                            </button>
                                        </div>
                                                        
                                    </div>
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          
          <h1>CARGANDO...</h1>
        )}
      </div>
      <>
        {addNewUserActive ? (
          <PopUpNewUsers hide={() => setAddNewUserActive(false)} />
        ) : (
          ""
        )}
        {isSearch ? (
          <PopUpSearchClient
            hide={() => setIsSearch(false)}
            fromNewQuote={false}
          />
        ) : (
          ""
        )}
      </>
    </>
  );
};

export default Users;
