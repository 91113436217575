import React, { useContext,useEffect,useState } from "react";
import voltz from "../Img/Captura.png";
import { informationOfSearch } from "../context/informationOfSearch";
import algoliasearch from "algoliasearch";

const PopUpTechFile = (props) => {

    const searchClient = algoliasearch(
        process.env.REACT_APP_APPLICATION_ID,
        process.env.REACT_APP_SEARCH_API_KEY
    )
    const indexSerch = searchClient.initIndex(process.env.REACT_APP_NAME_INDEX)

    const { arrResults } = useContext( informationOfSearch )
    const base = arrResults[props.index1].value[props.index2]
    const [ objectSelect, setObjectSelect ] = useState()
    const [ allReady, setAllReady ] = useState(false)
    const [ features,setFeatures ] = useState()
    
    const skuOfSearch = base.sku
    const idOfSearch = base.objectID

    
    useEffect(()=>{
        indexSerch.search(skuOfSearch,{
            attributesToRetrieve: ['sku_description', 'sku', 'brand', 'warranty',
            'price', 'color', 'gender', 'supplier', 'sale_unit', 'sub_brand', 'image_urls',
            'sale_value', 'description', 'tech_file', 'suppliers', 'features']
        }).then(({hits}) => {
            hits.map((item,index)=>{
                if(item.objectID===idOfSearch){
                    setObjectSelect(hits[index])
                    matchFeatures(hits[index])
                }
            })
        })        
    },[])
    
    const matchFeatures = async (obj) => {
        let dummy 
        console.log(obj.features)
        obj.features.map((item,index)=>{
            if(item!="undefined"){
                if(dummy === undefined){
                    dummy = item
                }else{
                    //console.log(item)
                    dummy = dummy + ","+" " + item
                }
            }
        })
        //console.log(dummy)
        setFeatures(dummy)
        setAllReady(true)
    }

    console.log(objectSelect)
    
    return(
        <>
            {
                allReady ?
                    <div className="modal pt5" id="modalStyle" >
                    
                        <div className="modal-dialog" id="testPosition">
                            
                            <div className="modal-content" id="containerModal">
                                <div id="headerTechFile" className="modal-header d-flex justify-content-between">
                                    <button id="buttonCloseTechFile" type="button" className="btn"  onClick = { ()=>{ props.hide() } }>x</button>
                                </div>
                                <div className="modal-body d-flex justify-content-between">
                                    <div className="width48 d-flex flex-wrap">
                                        <div className="width100">
                                            <img src={voltz} alt="" width="150" className="d-block"/>
                                        </div>
                                        <div className="width100 slider-container">
                                            {
                                                objectSelect.image_urls.map((item,index)=>(
                                                    <img className="slider-item" src={item} alt="" />
                                                ))
                                            }                                            
                                        </div>                              
                                    </div>
                                    <div className="width48 d-flex flex-wrap">
                                        <div className="d-flex flex-wrap width100">
                                            <p className="negroGrueso">{ objectSelect.brand }</p>
                                            <p className="ms-2">({ objectSelect.sku })</p>
                                        </div>
                                        
                                        <p className="width100 negroGrueso">{ objectSelect.sku_description }</p>
                                        <p className="colorAzul fw-bold mb-0">Especificaciones:</p>
                                        <p className="width100 colorAzul">{ features }</p>
                                        {
                                            objectSelect.warranty ?
                                            <div className="d-flex flex-wrap width100">
                                                <p className="negroGrueso">{ objectSelect.warranty } </p>
                                                <p className="ms-2"> de garantía</p>
                                            </div>:''
                                        }
                                        
                                                                                
                                        <div className="width100 d-flex justify-content-center">
                                            <button onClick={()=> window.open(objectSelect.tech_file, "_blank")} id="dowloandTechFile">
                                                <span className="bi bi-cloud-download me-2"></span>
                                                Ficha técnica
                                            </button>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :''
            }
        </>
    )
}

export default PopUpTechFile