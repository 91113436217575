import { useState, useEffect } from "react";
import { streamQuoteDetail } from "../Data/statsServices";

export function GetQuotesStats () {
    const [ quotes, setQuotes ] = useState([])
    useEffect(()=>{
        const unsubscribe = streamQuoteDetail(
            (querySnapshot) => {
                const items =[]
                querySnapshot.forEach(doc => {
                    const data = doc.data()
                    items.push({...data, id: doc.id})
                })
                setQuotes(items)
            }
        )
        return () => unsubscribe()
    },[])
    return quotes
}