//*este script es para enviar informacion del estado de conexion del usuario para todas las paginas
import { createContext, useContext, useEffect, useState } from "react";//* this module is included on react
import { auth } from '../fireBase';
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom'

export const authContext = createContext()

export const useAuth = () => {
    const context = useContext(authContext)
    if(!context) throw new Error("there is not auth provider")
    return context
}

const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [isLogin, setIsLogin] = useState(false)

    //*this fuction create a new user en firebase
    const signUp = ( email, password) => {
        createUserWithEmailAndPassword(auth, email, password)
    }

    const login = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logOut = () => signOut(auth)

    const navigate = useNavigate()

    useEffect(() =>{

        const unlisten = onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              const uid = user.uid;
              setUser(user);
              setLoading(false);
              navigate("/")
              // ...
            } else {
              // User is signed out
              // ...
              setUser(null);
              navigate("/login")
            }
          });
        
        return () => {
            unlisten();
        }
     }, []);

    const handleIsLogin = () =>{setIsLogin(!isLogin)}

    return (
        <authContext.Provider value={{
            signUp, 
            login, 
            user, 
            logOut, 
            loading, 
            isLogin,
            handleIsLogin
        }}>
            {children}
        </authContext.Provider>
    )
}

export default AuthProvider