import React from "react";
import { useNavigate } from "react-router-dom";
import { updateQuoteCancel } from "../Data/QuoteServices";

const SaveCorrectVolver = (props) => {
    const navigate = useNavigate()

    const cerradoPopUpToHome = async () => {
        await updateQuoteCancel(props.id)
        props.hide()
        navigate('/')
        navigate(0) 
    }

    return(
        <>
             <div className="modal pt-5" id="modalStyle">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header d-block">
                            <div id="containerFirstButtons" className="d-flex justify-content-between">
                                <div>
                                    <p id="tittleAddItem">¿Desea cancelar la cotización?</p>
                                </div>
                                <div>
                                    <button type="button" className="btn-close" onClick = { ()=>{ props.hide() } }></button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex justify-content-end">
                                <button id="buttonCloseQuote" onClick={ ()=> {cerradoPopUpToHome()} }>Si, cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SaveCorrectVolver