import { createContext, useState } from "react";
import _map from 'lodash/map';

export const editSearchSelectedItem = createContext()

export const EditSearchSelectedItem = ({ children }) => {

    const [arrResultsOfSelected, setArrResultsOfSelected] = useState ([]) //*Save values with reults
    const [arrSearchedLine, setSearchedLine] = useState ({
        nameItemOfSearch: "PRODUCTO BUSCADO POR EL CLIENTE",
        id: "xxxxxx",
        position: 1,
        requestedAmount: 0,
        value: []
    })

    const initEdition = (searchedLine) => {
        console.log(searchedLine)
        setSearchedLine(
            {
                nameItemOfSearch: searchedLine.nameItemOfSearch,
                id: searchedLine.id,
                position: searchedLine.position,
                requestedAmount: searchedLine.requestedAmount,
                value: [...searchedLine.value]
            }
        )
        setArrResultsOfSelected(e=>{
            return[
                ...searchedLine.value,
            ]
        })
            
    }

    const transformCatalogResultItems = (items) => {

        return items.map((item) => (
            {
        ...item,
        cardColor: arrResultsOfSelected.some(val => item.objectID === val.objectID) ? "#EFF2FD" : "white", 
        transformedFeatures: '_highlightResult' in item ? transformFeatures(item._highlightResult) : null
        }));
    };

    function transformFeatures(_highlightResult){
        let text = null
        if('sku_description' in _highlightResult ) {   
            if(_highlightResult.sku_description.matchLevel === 'full' || _highlightResult.sku_description.matchLevel === 'partial'){
                _map(_highlightResult.sku_description.matchedWords, (d, index) => {
                    if(text === null){
                        text = d.toLowerCase().replaceAll('<mark>', '').replaceAll('</mark>', '');
                    } else {
                        text = text + ', ' + d.toLowerCase().replaceAll('<mark>', '').replaceAll('</mark>', '');
                    }
                })  
            }
        }
        if('features' in _highlightResult ) {
            _map(_highlightResult.features, (d, index) => {
                
                if((d.matchLevel === 'full' || d.matchLevel === 'partial')){
                    if(text === null){
                        text = d.value.toLowerCase().replaceAll('<mark>', '').replaceAll('</mark>', '');
                    } else {
                        let val = d.value.toLowerCase().replaceAll('<mark>', '').replaceAll('</mark>', '');
                        let val2 = text.toLowerCase();
                        let result = val2.includes(val);
                        if(!result) {
                            text = text + ', ' + val
                        }
                    }
                }
            })
        }
        return text;
    }
   
    const resolveOnSelectedItem = (hit,e) => {
        /*let mapHit = {
            brand: hit.brand,
            catalog_code: hit.catalog_code,
            features: hit.features,
            image_cover: hit.image_cover,
            image_urls: hit.image_urls,
            objectID: hit.objectID,
            path: hit.path,
            price_currency: hit.price_currency,
            price_public: hit.price_public,
            sale_unit: hit.sale_unit,
            sale_value: hit.sale_value,
            selected: false,
            sku: hit.sku,
            sku_description: hit.sku_description,
            status: hit.status,
            tech_file: hit.tech_file,
            warranty: hit.warranty,
            price: {
                price_best: e.price_best,
                supplier_code: e.supplier_code,
                supplier_name: e.supplier_name, 
                stock: e.stock, 
                currency: e.currency
            }
        }*/

        /*hit.price={
            price_best: e.price_best,
            supplier_code: e.supplier_code,
            supplier_name: e.supplier_name, 
            stock: e.stock, 
            currency: e.currency,
            price_margin: .15,
            price_margin_name: "K"
        }*/
        //console.log(mapHit)
        //hit.indexselect = i
        setArrResultsOfSelected(prevItems => {
            let insert = true;
            console.log(prevItems)
            prevItems.forEach(element => {
                if(element.objectID === hit.objectID){
                    insert = false;
                }
            });  

            if(insert === true){
                //prevItems.push(hit)
                hit.selected=false
                return [...prevItems, hit ]
            } else {
                return prevItems.filter(prevItems => prevItems.objectID !== hit.objectID)
            }
        }); 
    }


    return(
        <editSearchSelectedItem.Provider value = {{
            arrResultsOfSelected,
            setArrResultsOfSelected,
            resolveOnSelectedItem,
            transformCatalogResultItems, 
            arrSearchedLine,
            initEdition,
        }}>
            {children}
        </editSearchSelectedItem.Provider>
    )
}