import React from 'react'//*module that starts react is imported
import './App.css' //*import css
//#region all pages imported
import Home from './Pages/Home.js'//*import Home page
import Login from './Pages/Login';//*import Login page
import Register from './Pages/Register';//*import Register page
import ProtectedRoute from './Pages/ProtectedRoute';
import ManualData from './Pages/ManualData';
import Results from './Pages/Results'
import ReviewQuotes from './Pages/ReviewQuotes';
import ChangeCurrency from './Pages/ChangeCurrency';
import Users from './Pages/Users';
import ReviewClient from './Pages/ReviewClient';
import Orders from './Pages/Orders';
//#endregion
import {Routes, Route} from 'react-router-dom' //*router controller module is imported in to app
import AuthProvider from './context/authContext.js'
import { InfoSearch } from './context/informationOfSearch';
import { InfoExport } from './context/informationOfExport';
import CatalogPage from './Pages/CatalogPage';
import Modification from './Pages/Modification';
import PopUpFondo from './Pages/PopUpFondo';
import { ImplementStruct } from './context/functionsToImplementNewStructure';

function App() {

  return ( //*Return something to paint
    <AuthProvider>
      
        <InfoSearch>
            <InfoExport>
            <ImplementStruct>
              <Routes>
                <Route path="/" element={
                  <ProtectedRoute>                  
                    <Home/>                              
                  </ProtectedRoute>         
                }/> 
                <Route path="/datosManuales" element={
                  <ProtectedRoute>
                    <ManualData/>
                  </ProtectedRoute>
                }/>
                <Route path='/results' element = {
                  <ProtectedRoute>
                    <Results/>
                  </ProtectedRoute>
                }/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/catalog" element={
                  <ProtectedRoute>
                    <CatalogPage/>
                  </ProtectedRoute>
                }/>

                <Route path="/reviewQuotes" element={
                  <ProtectedRoute>
                    <ReviewQuotes/>
                  </ProtectedRoute>
                }/>

                <Route path='/changeCurrency' element = {
                  <ProtectedRoute>
                    <ChangeCurrency/>
                  </ProtectedRoute>
                }/>

                <Route path='/users' element = {
                  <ProtectedRoute>
                    <Users/>
                  </ProtectedRoute>
                }/>

                <Route path='/checkClient' element = {
                  <ProtectedRoute>
                    <ReviewClient/>
                  </ProtectedRoute>
                }/>

                <Route path='/orders' element = {
                  <ProtectedRoute>
                    <Orders/>
                  </ProtectedRoute>
                }
                />

                <Route path='/modification' element = {
                  <ProtectedRoute>
                    <Modification/>
                  </ProtectedRoute>
                }
                />
                <Route path="/createUser" element = {
                    <ProtectedRoute>
                      <PopUpFondo/>
                    </ProtectedRoute>
                  }
                />

              </Routes>
              </ImplementStruct>
          </InfoExport>
        </InfoSearch>
      
    </AuthProvider>    
  );
}

export default App //*module export, to be used