import React, { useContext, useState, useEffect } from "react";

import moment from 'moment';
import { useNavigate } from "react-router-dom";

import { updateData } from "../Data/CompanyServices";
import { informationOfSearch } from "../context/informationOfSearch";

import Navbar from "../Components/Navbar";

const ReviewClient = () => {

    const navigate = useNavigate()

    const { clientOfReview } = useContext(informationOfSearch)

    const [ haveChanges, setHaveChanges ] = useState(false)
    const [ objectToUpdate, setObjectToUpdate ] = useState({})
    const [ valCategory, setValCategory ] = useState(clientOfReview.category)

    const updateFields = (nameToEdit, valToChange) => {
        let new_full_name
        let modifyObject = {
            country: clientOfReview.billing_address.country, //segun el pais asi seran los campos abajo, por ahora solo manejamos Mexico
            entidad_federativa: clientOfReview.billing_address.entidad_federativa,
            municipio_o_delegacion: clientOfReview.billing_address.municipio_o_delegacion,
            localidad: clientOfReview.billing_address.localidad,
            colonia: clientOfReview.billing_address.colonia,
            nombre_calle: clientOfReview.billing_address.nombre_calle,
            no_exterior: clientOfReview.billing_address.no_exterior,
            no_interior: clientOfReview.billing_address.no_interior,
            entre_calle: clientOfReview.billing_address.entre_calle,
            y_calle: clientOfReview.billing_address.y_calle, 
            referencias_adicionales: clientOfReview.billing_address.referencias_adicionales,
            codigo_postal: clientOfReview.billing_address.codigo_postal
        }

        if (nameToEdit !== "first_name" || 
            nameToEdit !== "second_name" || 
            nameToEdit !== "last_name" || 
            nameToEdit !== "city") {
                setObjectToUpdate({...objectToUpdate,[nameToEdit]:valToChange})
        }        

        if(nameToEdit === "first_name"){
            new_full_name= valToChange + " " + clientOfReview.second_name+ " " + clientOfReview.last_name
            setObjectToUpdate({...objectToUpdate,["full_name"]:new_full_name,["first_name"]:valToChange})
        }
        if(nameToEdit === "second_name"){
            new_full_name= clientOfReview.first_name + " " + valToChange+ " " + clientOfReview.last_name
            setObjectToUpdate({...objectToUpdate,["full_name"]:new_full_name,["second_name"]:valToChange})
        }
        if(nameToEdit === "last_name"){
            new_full_name= clientOfReview.first_name + " " + clientOfReview.second_name+ " " + valToChange
            setObjectToUpdate({...objectToUpdate,["full_name"]:new_full_name,["last_name"]:valToChange})
        }
        if(nameToEdit === "city") {
            modifyObject.entidad_federativa = valToChange
            setObjectToUpdate({...objectToUpdate,["billing_address"]:modifyObject})
        }
        setHaveChanges(true)
    }

    const sendUpdate = async () =>{
        let nameTable
        clientOfReview.type === "person" ? nameTable = "profile-user" : nameTable = "company" 
        try {
            await updateData( clientOfReview.id, nameTable, objectToUpdate)
            setHaveChanges(false)
        } catch (error) {
            console.log(error)
        }
        
    }
    //console.log(clientOfReview)

    return(
        <>
            <Navbar/>
            <div id="containerTopReviewClient" className="d-flex justify-content-between align-content-center p-3 width100">
                    <div className="d-flex">
                        {
                            clientOfReview.type === "company" ?
                                clientOfReview.alias ?
                                    <>
                                        <p id="tittleClientReviewClient" className="ms-3 text-center align-items-center">
                                            {clientOfReview.name}
                                        </p>
                                        <p id="tittleClientReviewClient" className="ms-3 text-center align-items-center">
                                            ({clientOfReview.alias})
                                        </p>
                                    </>
                                :
                                    <>
                                        <p id="tittleClientReviewClient" className="ms-3 text-center align-items-center">
                                            {clientOfReview.name}
                                        </p>
                                        <p id="tittleClientReviewClient" className="ms-3 text-center align-items-center">
                                            ()
                                        </p>
                                    </>
                            :
                                <p id="tittleClientReviewClient" className="ms-3 text-center align-items-center">
                                    {clientOfReview.full_name}
                                </p>  
                        }
                    </div>
                    
                    {
                        haveChanges ?
                            <div id="containerButtonsTopReviewClient" className="d-flex align-items-center">
                                <button className="buttonBlueReviewClient ms-3" 
                                    onClick={()=>{
                                        sendUpdate()
                                        //console.log(objectToUpdate)
                                    }}
                                >
                                    Guardar
                                </button>
                                <button className="buttonBlueReviewClient ms-3"
                                    onClick={()=>{
                                        navigate("/users")
                                    }}
                                >
                                    Cerrar
                                </button>
                            </div>
                        :
                            <div id="containerButtonsTopReviewClient" className="d-flex align-items-center">
                                
                                <button className="buttonBlueReviewClient ms-3"
                                    onClick={()=>{
                                    navigate("/users")
                                }}
                                >
                                    Cerrar
                                </button>
                            </div>
                    }
            </div>
            <div className="d-flex" id="containerBodyReviewClient">
                <div className="width50 containerLeftReviewClient">
                    <div className="d-flex width80 marginL10">
                        <div className="width60">
                            {
                                clientOfReview.type === "company" ?
                                    <>
                                        <p id="tittleNumberClient">
                                            {clientOfReview.number_company}
                                        </p>
                                        <p className="tittleIdClient">
                                            {clientOfReview.id}
                                        </p>
                                    </>
                                :
                                    <>
                                        <p id="tittleNumberClient">
                                            {clientOfReview.number_person}
                                        </p>
                                        <p className="tittleIdClient">
                                            {clientOfReview.id}
                                        </p>
                                    </>
                            }
                            
                        </div>
                        <div className="width40">
                            <p className="tittleIdClient">
                                    Creado: {moment(clientOfReview.created_at.toDate()).local().format("YYYY-MM-DD HH:mm")}
                            </p>
                            {
                                 clientOfReview.updated_at !==null ?
                                    <p className="tittleUpdateReviewClient">
                                                    Última actualización:    {moment(clientOfReview.updated_at.toDate()).local().format("YYYY-MM-DD HH:mm")}
                                    </p>                                                
                                :
                                    ''
                            }
                        </div>
                    </div>
                    <div className="d-flex widdth80 flex-wrap secondPartLeftReviewClient">
                            {
                                clientOfReview.type === "person" ? 
                                    <div className="btn-group mt-3 width80 marginL10 mb-2 border-bottom" id="buttonSelectCategory">
                                        <button type="button" className="btn dropdown-toggle" 
                                            data-bs-toggle="dropdown" 
                                            aria-expanded="false"
                                            //onClick={()=>{verificationAllFull()}}
                                        >
                                            Categoría: {valCategory}
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                                <button className="dropdown-item" type="button"
                                                    onClick={()=>{
                                                        updateFields("category", "A")
                                                        setValCategory("A")
                                                    }}
                                                >
                                                    Categoría A
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" type="button"
                                                    onClick={()=>{
                                                        updateFields("category", "B")
                                                        setValCategory("B")
                                                    }}
                                                >
                                                    Categoría B
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" type="button"
                                                    onClick={()=>{
                                                        updateFields("category", "C")
                                                        setValCategory("C")
                                                    }}
                                                >
                                                    Categoría C
                                                </button>
                                            </li>
                                            {
                                                /*<li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            updateFields("category", "D")
                                                            setValCategory("D")
                                                        }}
                                                    >
                                                        Categoría D
                                                    </button>
                                                </li>*/
                                            }
                                        </ul>
                                    </div>
                                :
                                    ''
                            }
                        <div id="containerInfoPerson" className="width100 width80 marginL10 mb-3">
                            <p className="tittlePartBlue mt-3">
                                Datos básicos
                            </p>
                            {
                                clientOfReview.type === "person" ?
                                    <>
                                        <input type="text" className="inputReviewClient width90"
                                           defaultValue = {clientOfReview.first_name}
                                           onChange = {(e)=>{
                                                updateFields("first_name", e.target.value)
                                           }}
                                        />
                                        {
                                            clientOfReview.second_name ? 
                                                <input type="text" className="inputReviewClient width90"
                                                   defaultValue = {clientOfReview.second_name}
                                                   onChange = {(e)=>{
                                                        updateFields("second_name", e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Segundo Nombre"
                                                    onChange = {(e)=>{
                                                        updateFields("second_name", e.target.value)
                                                    }}
                                                />
                                        }

                                        {
                                            clientOfReview.last_name ?
                                                <input type="text" className="inputReviewClient width90"
                                                   defaultValue = {clientOfReview.last_name}
                                                   onChange = {(e)=>{
                                                        updateFields("last_name", e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Apellido"
                                                    onChange = {(e)=>{
                                                        updateFields("last_name", e.target.value)
                                                    }}
                                                />
                                        }
                                    </>
                                :
                                    <>
                                        <input type="text" className="inputReviewClient width90"
                                            defaultValue={clientOfReview.name}
                                            onChange = {(e)=>{
                                                updateFields("name", e.target.value)
                                            }}
                                        />
                                        {
                                            clientOfReview.alias ? 
                                                <input type="text" className="inputReviewClient width90"
                                                    defaultValue={clientOfReview.alias}
                                                    onChange = {(e)=>{
                                                        updateFields("alias", e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Ingrese el alias"
                                                    onChange = {(e)=>{
                                                        updateFields("alias", e.target.value)
                                                    }}
                                                />
                                        }

                                        {
                                            clientOfReview.business_sector ?
                                                <input type="text" className="inputReviewClient width90"
                                                    defaultValue={clientOfReview.business_sector}
                                                    onChange = {(e)=>{
                                                        updateFields("business_sector", e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Giro de la emnpresa"
                                                    onChange = {(e)=>{
                                                        updateFields("business_sector", e.target.value)
                                                    }}
                                                />
                                        }
                                        {
                                            clientOfReview.business_sector ?
                                                <input type="text" className="inputReviewClient width90"
                                                    defaultValue={clientOfReview.web_site}
                                                    onChange = {(e)=>{
                                                        updateFields("web_site", e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Pagina web"
                                                    onChange = {(e)=>{
                                                        updateFields("web_site", e.target.value)
                                                    }}
                                                />
                                        }
                                    </>
                            }
                        </div>
                        <div id="containerInfoContact" className="width100 width80 marginL10 mb-3">
                            {
                                clientOfReview.type === "person" ?
                                    <>
                                        <p className="tittlePartBlue mt-3">
                                            Datos de contacto
                                        </p>
                                        {
                                            clientOfReview.phone_1 ?
                                                <div>
                                                    <input type="text" className="inputReviewClient width60"
                                                        defaultValue={clientOfReview.phone_1}
                                                        onChange = {e=>{
                                                            updateFields("phone_1", e.target.value)
                                                        }}
                                                    />
                                                    {
                                                        clientOfReview.ext_1 ?
                                                            <input type="text" className="inputReviewClient ms-5 width20"
                                                                defaultValue={clientOfReview.ext_1}
                                                                onChange = {e=>{
                                                                    updateFields("ext_1", e.target.value)
                                                                }}
                                                            />
                                                        :
                                                            <input type="text" className="inputReviewClient ms-5 width20"
                                                                placeholder="ext 1"
                                                                onChange = {e=>{
                                                                    updateFields("ext_1", e.target.value)
                                                                }}
                                                            />
                                                    }                                                    
                                                </div>
                                            :
                                                <div>
                                                    <input type="text" className="inputReviewClient width60"
                                                        placeholder="Teléfono 1"
                                                        onChange = {e=>{
                                                            updateFields("phone_1", e.target.value)
                                                        }}
                                                    />
                                                    {
                                                        clientOfReview.ext_1 ?
                                                            <input type="text" className="inputReviewClient ms-5 width20"
                                                                defaultValue={clientOfReview.ext_1}
                                                                onChange = {e=>{
                                                                    updateFields("ext_1", e.target.value)
                                                                }}
                                                            />
                                                        :
                                                            <input type="text" className="inputReviewClient ms-5 width20"
                                                                placeholder="ext 1"
                                                                onChange = {e=>{
                                                                    updateFields("ext_1", e.target.value)
                                                                }}
                                                            />
                                                    }                                                    
                                                </div>
                                        }
                                        {
                                            clientOfReview.phone_2 ?
                                            <div>
                                                <input type="text" className="inputReviewClient width60"
                                                    value={clientOfReview.phone_2}
                                                />
                                                {
                                                    clientOfReview.ext_2 ?
                                                        <input type="text" className="inputReviewClient ms-5 width20"
                                                            value={clientOfReview.ext_2}
                                                        />
                                                    :
                                                        <input type="text" className="inputReviewClient ms-5 width20"
                                                            placeholder="ext 2"
                                                        />
                                                }                                                    
                                            </div>
                                            :
                                            <div>
                                                <input type="text" className="inputReviewClient width60"
                                                    placeholder="Teléfono 2"
                                                />
                                                {
                                                    clientOfReview.ext_2 ?
                                                        <input type="text" className="inputReviewClient ms-5 width20"
                                                            value={clientOfReview.ext_2}
                                                        />
                                                    :
                                                        <input type="text" className="inputReviewClient ms-5 width20"
                                                            placeholder="ext 2"
                                                        />
                                                }                                                    
                                            </div>
                                        }
                                        {
                                            clientOfReview.email ?
                                                <input type="text" className="inputReviewClient width90"
                                                    defaultValue={clientOfReview.email}
                                                    onChange = {e=>{
                                                        updateFields("email", e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Ingresa email"
                                                    onChange = {e=>{
                                                        updateFields("email", e.target.value)
                                                    }}
                                                />
                                        }
                                        {
                                            clientOfReview.billing_address.entidad_federativa ?
                                                <input type="text" className="inputReviewClient width90"
                                                    defaultValue={clientOfReview.billing_address.entidad_federativa}
                                                    onChange = {e=>{
                                                        updateFields("city", e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Ingresa la ciudad"
                                                    onChange = {e=>{
                                                        updateFields("city", e.target.value)
                                                    }}
                                                />
                                        }
                                    </>
                                :
                                    <>
                                        <p className="tittlePartBlue mt-3">
                                            Datos de facturación
                                        </p>
                                        {
                                             clientOfReview.rfc ?
                                                <input type="text" className="inputReviewClient width90"
                                                    defaultValue={clientOfReview.rfc}
                                                    onChange = {e=>{
                                                        updateFields("rfc", e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Ingresa el RFC del usuario"
                                                    onChange = {e=>{
                                                        updateFields("rfc", e.target.value)
                                                    }}
                                                />
                                        }
                                        <input type="text" className="inputReviewClient width90"
                                            defaultValue = {clientOfReview.name}
                                            onChange = {e=>{
                                                updateFields("name", e.target.value)
                                            }}
                                        />
                                    </>
                            }
                        </div>
                        
                        <div id="containerInfoFact" className="width100 width80 marginL10 mb-3 mt-3">
                            {
                                clientOfReview.type === "person" ?
                                    <>
                                        <p className="tittlePartBlue">
                                            Datos de facturación
                                        </p>
                                        {
                                             clientOfReview.rfc ?
                                                <input type="text" className="inputReviewClient width90"
                                                    defaultValue={clientOfReview.rfc}
                                                    onChange = {e=>{
                                                        updateFields("rfc",e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Ingresa el RFC del usuario"
                                                    onChange = {e=>{
                                                        updateFields("rfc",e.target.value)
                                                    }}
                                                />
                                        }
                                        {
                                            clientOfReview.razon_social ?
                                                <input type="text" className="inputReviewClient width90"
                                                    defaultValue={clientOfReview.razon_social}
                                                    onChange = {e=>{
                                                        updateFields("razon_social", e.target.value)
                                                    }}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Ingresa la razon social a facturar"
                                                    onChange = {e=>{
                                                        updateFields("razon_social", e.target.value)
                                                    }}
                                                />
                                        }
                                        {
                                            clientOfReview.billing_address.codigo_postal ?
                                                <input type="text" className="inputReviewClient width90"
                                                    value={clientOfReview.billing_address.codigo_postal}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Ingresa el codigo Postal"
                                                />
                                        }
                                        <div className="btn-group mt-3 width80 marginL5 mb-2 border-bottom" id="buttonSelectCategory">
                                            <button type="button" className="btn dropdown-toggle" 
                                                data-bs-toggle="dropdown" 
                                                aria-expanded="false"
                                                //onClick={()=>{verificationAllFull()}}
                                            >
                                                Régimen fiscal: 
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría A")
                                                            //mapNewUser["category"] = "A"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Pyme
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        PeFiEm
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="btn-group mt-3 width80 marginL5 mb-2 border-bottom" id="buttonSelectCategory">
                                            <button type="button" className="btn dropdown-toggle" 
                                                data-bs-toggle="dropdown" 
                                                aria-expanded="false"
                                                //onClick={()=>{verificationAllFull()}}
                                            >
                                                Uso de CFI: 
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría A")
                                                            //mapNewUser["category"] = "A"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Adquisición de mercancias
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Gastos en general
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Construcciones
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Mobilario y equipo de oficina por inversiones
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Por definir
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Otra maquinaria y equipo
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Primas por seguros de gastos médicos
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Donativos
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                                :
                                    <>
                                        <p className="tittlePartBlue">
                                            Dirección completa de facturación completa
                                        </p>
                                        {
                                            clientOfReview.billing_address.entidad_federativa ?
                                                <input type="text" className="inputReviewClient width90"
                                                    value={clientOfReview.billing_address.entidad_federativa}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Ingrese la ciudad"
                                                />
                                        }
                                        {
                                            clientOfReview.billing_address.codigo_postal ?
                                                <input type="text" className="inputReviewClient width90"
                                                    value={clientOfReview.billing_address.codigo_postal}
                                                />
                                            :
                                                <input type="text" className="inputReviewClient width90"
                                                    placeholder="Ingresa el codigo Postal"
                                                />
                                        }
                                        <div className="btn-group mt-3 width80 marginL5 mb-2 border-bottom" id="buttonSelectCategory">
                                            <button type="button" className="btn dropdown-toggle" 
                                                data-bs-toggle="dropdown" 
                                                aria-expanded="false"
                                                //onClick={()=>{verificationAllFull()}}
                                            >
                                                Régimen fiscal: 
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría A")
                                                            //mapNewUser["category"] = "A"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Pyme
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        PeFiEm
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="btn-group mt-3 width80 marginL5 mb-2 border-bottom" id="buttonSelectCategory">
                                            <button type="button" className="btn dropdown-toggle" 
                                                data-bs-toggle="dropdown" 
                                                aria-expanded="false"
                                                //onClick={()=>{verificationAllFull()}}
                                            >
                                                Uso de CFI: 
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría A")
                                                            //mapNewUser["category"] = "A"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Adquisición de mercancias
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Gastos en general
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Construcciones
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Mobilario y equipo de oficina por inversiones
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Por definir
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Otra maquinaria y equipo
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Primas por seguros de gastos médicos
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" type="button"
                                                        onClick={()=>{
                                                            //setCategoryClient("Categoría B")
                                                            //mapNewUser["category"] = "B"
                                                            //verificationAllFull()
                                                        }}
                                                    >
                                                        Donativos
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </>
                            }                            
                        </div>
                    </div>
                </div>
                <div className="width50 containerRigthReviewClient">
                    {
                        clientOfReview.type === "person" ?
                             <>
                                
                                {
                                    clientOfReview.status === "employee" ?
                                        <>
                                            <p className="tittlePartBlue mt-3">
                                                Empresas vinculadas
                                            </p>
                                            {
                                                <div className="containerInfoAnexos">
                                                    <p className="paragraphDataUserCompany colorBlue">Alias: {clientOfReview.infoCompany.alias}</p>
                                                    <p className="paragraphDataUserCompany colorBlackLitgh">Razon social: {clientOfReview.infoCompany.name}</p>
                                                    <p className="paragraphDataUserCompany colorBlackLitgh">Ciudad: {clientOfReview.infoCompany.billing_address.entidad_federativa}</p>
                                                </div>    
                                            }
                                        </>
                                    :
                                        <>
                                            <p className="tittlePartBlue mt-3">
                                                Empresas vinculadas
                                            </p>
                                            <div className="containerInfoAnexos">
                                                <p className="paragraphDataUserCompany colorBlue">Alias: {clientOfReview.full_name}</p>
                                                <p className="paragraphDataUserCompany colorBlackLitgh">Razon social: {clientOfReview.razon_social}</p>
                                                <p className="paragraphDataUserCompany colorBlackLitgh">Ciudad: {clientOfReview.billing_address.entidad_federativa}</p>
                                            </div>
                                        </>
                                }
                            </>
                        :
                            <>
                                <p className="tittlePartBlue mt-3">
                                    Personas vinculadas
                                </p>
                                <div className="containerInfoAnexos">
                                    <p className="paragraphDataUserCompany colorBlue">Alias: {clientOfReview.full_name}</p>
                                    <p className="paragraphDataUserCompany colorBlackLitgh">Razon social: {clientOfReview.razon_social}</p>
                                    <p className="paragraphDataUserCompany colorBlackLitgh">Ciudad: {clientOfReview.billing_address.entidad_federativa}</p>
                                </div>
                            </>
                    }                    
                </div>
            </div>            
        </>
    )
}

export default ReviewClient