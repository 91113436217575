import {db} from '../fireBase'
import { 
    getFirestore,
    query,
    orderBy,
    onSnapshot,
    collection,
    getDoc, 
    getDocs, 
    addDoc,
    setDoc,
    updateDoc,
    doc, 
    serverTimestamp, 
    arrayUnion,
    arrayRemove,
    limit, 
    where,
    deleteDoc
} from "firebase/firestore";

// Devuelve un stream de datos, se actualiza cada vez que hay un cambio en la db
// devuelve los documentos del node quote-draft, ordenados en orden descendente 
// filtrados por el parametro estado = in_progress
export const streamQuoteItems = (snapshot, error) => {
    const itemsColRef = collection(db, 'quote-draft')
    const itemsQuery = query(itemsColRef, where("status", "==", "in_progress"), orderBy("created_at", "desc"), limit(15))
    return onSnapshot(itemsQuery, snapshot, error);
};

export const streamQuoteItemsCancel = (snapshot, error) => {
  const itemsColRef = collection(db, 'quote-draft')
  const itemsQuery = query(itemsColRef, where("status", "==", "cancelled"), orderBy("created_at", "desc"))
  return onSnapshot(itemsQuery, snapshot, error);
};

export const streamQuoteItemsPublish = (snapshot, error) => {
  const itemsColRef = collection(db, 'quote-draft')
  const itemsQuery = query(itemsColRef, where("status", "==", "published"), orderBy("created_at", "desc"))
  return onSnapshot(itemsQuery, snapshot, error);
};

export const streamQuoteItemsAccepted = (snapshot,error) => {
  //const itemsColRef = collection(db, 'order-detail' )
  const itemsQuery = query(collection(db,'order-detail'))
  //console.log(itemsQuery.data())
  return onSnapshot(itemsQuery, snapshot, error)
}

export const addNewProduct = async (newItem) => {
  if(typeof(newItem.source)==="string"){
    let id = newItem.catalog_code + "_" + newItem.sku
    //console.log(newItem.source)
    const colRef  = doc(db,'products', id)
    const docRef = await setDoc(colRef,newItem)
    return docRef.id
  } else {
    return
  }
  
}

// Añade una nueva cotización al node quote-draft 
// devuelve el id de la cotizacion
export const addNewQuote = async (alias, idCliente, authorEmail, authorUserId, quote) => {
  const quotesColRef = collection(db, 'quote-draft')
  if('_highlightResult' in quote) {
    delete quote._highlightResult
  }
  const docRef = await addDoc(quotesColRef, {
          created_at: serverTimestamp(),
          status: "in_progress",
          alias: alias,
          author: {
            user_id: authorUserId, 
            email: authorEmail, 
          },
          
          ...quote,
          version:2
      });
  return docRef.id;
};

// Cambia el estado de una cotizacion a published en el node quote-detail 
export const publishQuote = async (docId) => {
  const quotesDocRef = doc(db, "quote-draft", docId)
  await updateDoc(quotesDocRef, {
          updated_at: serverTimestamp(),
          status: "published",
      });

  const quote = await getQuote(docId);
  console.log(quote)
  
  const quoteDetailColRef = doc(db, 'quote-detail', docId);
  await setDoc(quoteDetailColRef, {
        ...mapQuoteForPublish(quote),
      });

  return docId;
};

//Agrega solamente productos a la cotizacion publicada en quote-detail 
//luego de editar en quote-draft
function mapProductForUpdateDetail(product, remove_from_pending){
  //console.log(product)
  
  let detailItem = {
      product_requested: product.nameItemOfSearch,
      id: product.id,
      products_suggested: [],
      position: product.position
    }
    product.value.forEach(element2 => {
    //console.log(element2)
    detailItem.products_suggested.push({        
      product_id : element2['objectID'],
      brand_favicon : "brand_favicon" in element2 ? element2["brand_favicon"] : null,
      sku : element2['sku'],
      image_cover: "image_cover" in element2 ? element2['image_cover']: null,
      sku_description : element2['sku_description'],
      brand : element2['brand'],
      sale_value : element2['sale_value'],
      sale_unit : element2['sale_unit'],
      selected : "selected" in element2 ? element2['selected']: false,
      quantity : product.requestedAmount,
      tech_file: "tech_file" in element2 ? element2['tech_file'] :  null,
      source:"source" in element2 ? element2["source"] : "normal",
      price_currency: element2['price']['currency'],
      price: {
        price_best: element2['price']['price_best'],
        currency: element2['price']['currency'],
        supplier_name: element2['price']['supplier_name'],
        supplier_code: element2['price']['supplier_code'],
        stock: element2['price']['stock'],
        price_1: 'price_1' in element2['price'] ? element2['price']['price_1'] : 0,
        price_2: 'price_2' in element2['price'] ? element2['price']['price_2'] : 0,
        price_margin: 'price_margin' in element2['price'] ? element2['price']['price_margin'] : .15,
        price_margin_name: 'price_margin_name' in element2['price'] ? element2['price']['price_margin_name'] : .15
      },
      total:"total" in element2 ? element2["total"] 
        : {after_discount: 0, before_discount: 0, discount: 0 },
      price_public: "price_public" in element2 ? element2['price_public'] :  null,
      })
  })

  let quoteDetail = remove_from_pending == null ?  {
    detail: arrayUnion(detailItem),
    record: {
      next_action: "calculate_totals"
    },
    last_update: serverTimestamp()
  } : {
    detail: arrayUnion(detailItem),
    arr_not_result: arrayRemove(remove_from_pending),
    record: {
      next_action: "calculate_totals"
    },
    last_update: serverTimestamp()
  }

  //console.log("Este es el documento mapeado: ", quoteDetail)
  return quoteDetail;
}

function mapQuoteForPublish(quoteDraft){
  console.log(quoteDraft)
  let quoteDetail = {
    consecutive : quoteDraft['consecutive'],
    alias : quoteDraft['alias'],
    author: quoteDraft['author'],    
    created_at : quoteDraft['created_at'],
    accepted : false,
    detail: [],
    discarded_products: [],
    arr_not_result: [],
    shipping: quoteDraft['shipping'],
    version: quoteDraft.version,
    quote_category: quoteDraft.quote_category,
    customer: quoteDraft.customer,
    totals: "totals" in quoteDraft ? quoteDraft['totals'] :  null ,
    record: {
      next_action: "calculate_totals"
    },
    //price_public: quoteDraft.price_public
  }

  quoteDraft.arrResults.forEach(element => {
    let detailItem = {
      product_requested: element.nameItemOfSearch,
      products_suggested: [],
      id: element.id,
      position: element.position
    }
    element.value.forEach(element2 => {
      console.log(element2)
      detailItem.products_suggested.push({
        brand_favicon: "brand_favicon" in element2 ? element2["brand_favicon"] : null,   
        product_id : element2['objectID'],
        sku : element2['sku'],
        image_cover: "image_cover" in element2 ? element2['image_cover']: null,
        supplier : element2.price.supplier_name,
        sku_description : element2['sku_description'],
        brand : element2['brand'],
        quantity : element2['quantity'],
        sale_value : element2['sale_value'],
        sale_unit : element2['sale_unit'],
        selected : "selected" in element2 ? element2['selected']: false,
        quantity : element.requestedAmount,
        tech_file: "tech_file" in element2 ? element2['tech_file'] :  null,
        price_currency: element2['price']['currency'],
        price: {
          price_best: element2['price']['price_best'],
          currency: element2['price']['currency'],
          supplier_name: element2['price']['supplier_name'],
          supplier_code: element2['price']['supplier_code'],
          stock: element2['price']['stock'],
          price_margin: element2['price']['price_margin'],
          price_margin_name: element2['price']['price_margin_name'],
          price_1: 'price_1' in element2['price'] ? element2['price']['price_1'] : 0,
          price_2: 'price_2' in element2['price'] ? element2['price']['price_2'] : 0,
        },
        total:"total" in element2 ? element2["total"] : null,
        price_public: "price_public" in element2 ? element2['price_public'] :  null,
        source: "source" in element2 ? element2['source']: "normal"
      })
    })
    quoteDetail.detail.push(detailItem)
  });

  quoteDraft.arrDiscarted.forEach(element => {
    quoteDetail.discarded_products.push({
      id: element.id,
      requested_products: element.nameItemOfSearch,
      reason: element.reasonDiscarted,
      quantity: element.requestedAmount, 
      position: element.position
    })
  })

  quoteDraft.arrNotResults.forEach(element=>{
    quoteDetail.arr_not_result.push({
      id: element.id,
      requested_products: element.nameItemOfSearch,
      position: element.position,
      quantity: element.requestedAmount
    })
  })

  console.log("Este es el documento mapeado: ", quoteDetail)
  return quoteDetail;
}

export const getQuoteToEdit = async (docId) => {
  return await getDoc(doc(db,"quote-detail",docId))
}

export const serviceAssingReasonDiscarted = async (docId, indexOfSearch, product) => {
  console.log(product)
  const quotesDocRef = doc(db, indexOfSearch, docId)
  const quote_detail_doc= await getQuoteToEdit(docId)
  let quote_detail = quote_detail_doc.data()
  let dummy = {
    id: product.id,
    requested_products: product.nameItemOfSearch,
    position: product.position,
    reason: product.reasonDiscarted,
    quantity: product.requestedAmount,
    value: []
  }
  let indexSelect
  quote_detail.discarded_products.map((item, index)=>{
    if(item.id===dummy.id){
      indexSelect=index
    }
  })
  quote_detail.discarded_products.splice(indexSelect,1)
  quote_detail.discarded_products.push(dummy)
  //console.log(quote_detail)
  return updateDoc(quotesDocRef, quote_detail )
}

//se agrega un nuevo producto a buscar en pendientes
export const addNewItem = async (docId, indexOfSearch, newItem) => {
  //console.log("entramos")
  const quotesDocRef = doc(db, indexOfSearch, docId)
  const quote_detail_doc = await getQuoteToEdit(docId)
  let quote_detail = quote_detail_doc.data()
  quote_detail.arr_not_result.push(newItem)
  //console.log(quote_detail.arr_not_result)
  return updateDoc(quotesDocRef, quote_detail)
}

export const updateMargin = async (docId, value, tag, idItem) => {
  const quotesDocRef = doc(db, 'quote-detail', docId)
  const quote_detail_doc = await getQuoteToEdit(docId)
  let quote_detail = quote_detail_doc.data()
  //console.log(quoteToEdit, idItem)
  //console.log(quote_detail)
  quote_detail.detail.forEach(element => {
    if(element.id===idItem){
      //console.log(element.value[0].price)
      let base = element.products_suggested[0].price
      base.price_margin = value
      base.price_margin_name = tag
    }
  });
  //console.log(quoteToEdit.arrResults)
  return updateDoc(quotesDocRef, quote_detail)
}

export const serviceMoveAllToDiscarted = async (docId, indexOfSearch) =>{
  const quotesDocRef = doc(db, indexOfSearch, docId)
  const quote_detail_doc = await getQuoteToEdit(docId)
  let quote_detail = quote_detail_doc.data()

  quote_detail.arr_not_result.map((item,index)=>{
    quote_detail.discarded_products.push(item)
  })

  quote_detail.arr_not_result.splice(0)
  //console.log(quote_detail)
  return updateDoc(quotesDocRef, quote_detail)
}

//Mueve un producto de descartados a pendientes
export const moveDiscartedToPending = async (docId, product, indexOfSearch) => {
  const quotesDocRef = doc(db, indexOfSearch, docId)
  const quote_detail_doc = await getQuoteToEdit(docId)
  let quote_detail = quote_detail_doc.data()
  
  //console.log(quote_detail)

  let indexSelect
  quote_detail.discarded_products.map((item, index)=>{
    if(item.id===product.id){
      indexSelect=index
    }
  })
  quote_detail.discarded_products.splice(indexSelect,1)
  quote_detail.arr_not_result.push(product)
  console.log(quote_detail)
  
  return updateDoc(quotesDocRef, quote_detail )
}

//Mueve un producto de pendientes a descartados
export const movePendingToDiscarted = async (docId, product, indexOfSearch) => {
  const quotesDocRef = doc(db, indexOfSearch, docId)
  const quote_detail_doc = await getQuoteToEdit(docId)
  let quote_detail = quote_detail_doc.data()

  let dummy ={
    id: product.id,
    requested_products: product.nameItemOfSearch,
    position: product.position,
    reason: "No Encontrado",
    quantity: product.requestedAmount,
    value: []
  }
  
  //console.log(product.id)

  let indexSelect
  quote_detail.arr_not_result.map((item, index)=>{
    if(item.id===dummy.id){
      indexSelect=index
    }
  })
  quote_detail.arr_not_result.splice(indexSelect,1)
  quote_detail.discarded_products.push(dummy)
  console.log(quote_detail)
  
  return updateDoc(quotesDocRef, quote_detail )
}

//agrega un porducto en Detail  
export const addProductInDetail = async (docId, product, indexOfSearch) => {
  //console.log(indexOfSearch)
  const quotesDocRef = doc(db, indexOfSearch, docId)
  const quote_detail_doc = await getQuoteToEdit(docId)
  let quote_detail = quote_detail_doc.data()
  let remove_from_pending = null;
  if('arr_not_result' in quote_detail){
    quote_detail.arr_not_result.forEach(element => {
      if(element.id === product.id){
        remove_from_pending = element
      }
    })
  }
  
  return updateDoc(quotesDocRef, {
        ...mapProductForUpdateDetail(product, remove_from_pending)
      });
}

//se elemina elemento de arrResults y agrega a arrNotResults
export const addPendingDeleteResults = async (itemToAdd, itemToDelete, docId, indexOfSearch) =>{
  const quotesDocRef = doc(db, indexOfSearch, docId)
  const quote_detail_doc = await getQuoteToEdit(docId)
  let quote_detail = quote_detail_doc.data()
  //console.log(quote_detail)
  let arrToDelete
  quote_detail.detail.forEach(element=>{
    if(itemToDelete.id===element.id){
      arrToDelete=element
    }
  })
  //console.log(arrToDelete)

  return updateDoc(quotesDocRef, {
    arr_not_result: arrayUnion(itemToAdd),
    detail: arrayRemove(arrToDelete),
    record: {
      next_action: "calculate_totals"
    }
  })
}

//actualiza una cotizacion despues de publicada 
export const saveDetailQuote = async (docId, quote, indexOfSearch) => {
  console.log(indexOfSearch)
  const quotesDocRef = doc(db, indexOfSearch, docId)
  if('_highlightResult' in quote) {
    delete quote._highlightResult
  }
  return updateDoc(quotesDocRef, {
        ...mapQuoteForPublish(quote)
      });
}

// Actualiza toda los campos dados de una cotizacion 
export const updateQuote = (docId, quote, indexOfSearch) => {
  //console.log(quote)
  const quotesDocRef = doc(db, indexOfSearch, docId)
  if('_highlightResult' in quote) {
    delete quote._highlightResult
  }
  //console.log(quotesDocRef)
  return updateDoc(quotesDocRef, {
          updated_at: serverTimestamp(),
          ...quote
      });
};

export const updateQuoteCancel = (id) => {
  const quotesColRef = doc(db,"quote-draft", id)
  return updateDoc(quotesColRef,{
    updateQuote: serverTimestamp(),
    status:"cancelled"
  })
}

export const reverseStatus = (docId) => {
  const quoteDetailColRef = doc(db, "quote-detail", docId)
  const quoteDetailColRefDelete = doc(db, "order-detail", docId)
  deleteDoc(quoteDetailColRefDelete)
  return updateDoc(quoteDetailColRef,{
    accepted: false
  })
}

// Devuelve una cotizacion solicitada por id
// si existe devuelve el documento
// sino existe devuelve null
export const getQuote = async (docId) => {
  const docRef = doc(db, "quote-draft", docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No existe el documento!");
    return null;
  }
}

//servicio para cambiar unit de pieza a metro
export const upDateUnit = async (idQuote, idItem) => {
  const docRef = doc(db, "quote-detail", idQuote)
  const docSnap = await getDoc(docRef)
  const data = docSnap.data()
  let arrDummy = []
  data.detail.forEach(e=>{
    if(e.id === idItem){
      e.products_suggested[0].sale_unit="metro"
      arrDummy.push(e)
    }else{
      arrDummy.push(e)
    }
  })
  await updateDoc(docRef,{
    detail: arrDummy
  })
}

//*********************** SERVICIOS PARA UTILIZAR LA MATRIZ DE PRECIOS **************/
//Cambiar next action a "calculate_totals" para q el back corra las funciones
export const updateStatusNextActions = async (id) =>{
  const docRef = doc(db, "quote-detail", id)
  let dummy = docRef
  //console.log(dummy)
  await updateDoc(docRef, {
    updated_at: serverTimestamp(),
    record: {next_action:"calculate_totals"}  
  });
}

export const getTotalQuote = async (id) => {
  
}