import React, { useEffect, useState } from "react";
import { GetQuotesStats } from "../Hooks/statsHooks";
import moment from "moment/moment";

const PopUpStatsVentas = (props) =>{
    
    const arrStart = GetQuotesStats()

    const [ dataMain, setDataMain ] = useState([])
    const [ dataFilter, setDataFilter ] = useState([])

    //********* hooks de estadisticas generales ************/
    const [ quotesOfDay, setQuotesOfDay ] = useState()
    const [ priceTotal, setPriceTotal ] = useState()
    const [ statsJose, setStatsJose ] = useState(0)
    const [ statsHumber, setStatsHumber ] = useState(0)
    const [ statsOthers, setStatsOthers ] = useState(0)
    const [ totalParts, setTotalParts ] = useState()
    const [ partsYesCot, setPartsYesCot ] = useState()
    const [ partsPending, setPartsPending ] = useState()
    const [ partsDiscarted, setPartsDiscarted ] = useState()
    const [ porcentA, setPorcentA ] = useState()
    const [ porcentB, setPorcentB ] = useState()
    const [ porcentC, setPorcentC ] = useState()
    const [ porcentD, setPorcentD ] = useState()
    const [ porcentCatalog, setPorcentCatalog ] = useState()
    const [ porcentManual, setPorcentManual ]= useState()
    const [ porcentScraping, setPorcentScraping ] = useState()
    const [ porcentPartsJose, setPorcentPartsJose ] = useState()
    const [ porcentPartsHumber, setPorcentPartsHumber ] = useState()
    const [ porcentPartsOthers, setPorcentPartsOthers ] = useState()

    useEffect(()=>{
        let arrDummy = []
        let dateStart = moment().format('YYYY-MM-DD')
        setDataMain(arrStart)
        arrStart.forEach(doc=>{
            let dateDoc = moment(doc.created_at.seconds*1000).format('YYYY-MM-DD')
            if( dateDoc === dateStart)arrDummy.push(doc)
        })
        setDataFilter(arrDummy)
    },[arrStart])

    useEffect(()=>{
        //console.log(dataFilter.length)
        let priceDummy = 0
        let jose = 0
        let humber = 0
        let otros = 0
        let totalPartsCot = 0
        let totalPartsPen = 0
        let totalPartsDes = 0
        let catA = 0
        let catB = 0
        let catC = 0
        let catD = 0
        let partCatalog = 0
        let partsScraping = 0
        let partsManual = 0
        let partsOfHumber = 0
        let partsOfJose = 0
        let partsofOthers = 0
        setQuotesOfDay(dataFilter.length)
        dataFilter.forEach(doc=>{
            priceDummy = priceDummy + doc.totals?.total
            if(doc.author.email==="jose@voltz.mx"){
                jose++
                partsOfJose = partsOfJose + doc.detail.length
            }else {
                if(doc.author.email==="humberto@voltz.mx"){
                    humber++
                    partsOfHumber = partsOfHumber + doc.detail.length
                }else {
                    otros++
                    partsofOthers = partsofOthers + doc.detail?.length
                }
            }

            if(doc.quote_category === "A"){
                catA++
            }else{
                if(doc.quote_category === "B"){
                    catB++
                }else{
                    if(doc.quote_category === "C"){
                        catC++
                    }else{
                        catD++
                    }
                }
            }
            totalPartsCot = totalPartsCot + doc.detail?.length
            totalPartsDes = totalPartsDes + doc.discarded_products?.length
            totalPartsPen = totalPartsPen + doc.arr_not_result?.length
            console.log(doc)
            /*
            doc.detail.forEach(doc2=>{
                if(doc2.products_suggested.length>0){
                    if(doc2.products_suggested[0].source==="catalog"||doc2.products_suggested[0].source==="normal")partCatalog++
                    if(doc2.products_suggested[0].source==="scraping")partsScraping++
                    if(doc2.products_suggested[0].source==="manual")partsManual++
                }
            })
            */
        })
        setPriceTotal(priceDummy.toLocaleString('es-MX'))
        setStatsJose(((jose*100)/dataFilter.length).toFixed(2))
        setStatsHumber(((humber*100)/dataFilter.length).toFixed(2))
        setStatsOthers(((otros*100)/dataFilter.length).toFixed(2))
        setTotalParts(totalPartsCot + totalPartsDes + totalPartsPen)
        setPartsYesCot(totalPartsCot)
        setPartsPending(totalPartsPen)
        setPartsDiscarted(totalPartsDes)
        setPorcentA(((catA*100)/dataFilter.length).toFixed(2))
        setPorcentB(((catB*100)/dataFilter.length).toFixed(2))
        setPorcentC(((catC*100)/dataFilter.length).toFixed(2))
        setPorcentD(((catD*100)/dataFilter.length).toFixed(2))
        setPorcentCatalog(((partCatalog*100)/totalPartsCot).toFixed(2))
        setPorcentManual(((partsManual*100)/totalPartsCot).toFixed(2))
        setPorcentScraping(((partsScraping*100)/totalPartsCot).toFixed(2))
        setPorcentPartsJose(((partsOfJose*100)/totalPartsCot).toFixed(2))
        setPorcentPartsHumber(((partsOfHumber*100)/totalPartsCot).toFixed(2))
        setPorcentPartsOthers(((partsofOthers*100)/totalPartsCot).toFixed(2))
    },[dataFilter])

    const updateDate = ( date ) => {
        let arrDummy = []
        dataMain.forEach(doc=>{
            let dateDoc = moment(doc.created_at.seconds*1000).format('YYYY-MM-DD')
            if(dateDoc === date) arrDummy.push(doc)
        })
        setDataFilter(arrDummy)
    }

    return(
        <>
             <div className="modal pt5" id="modalStyle">
                <div className="modal-dialog modalDialogNewUser">
                    <div className="modal-content modalContentNewUser">
                        <div className="modal-header headerNewClient d-flex flex-wrap">
                            <p className="tittleNewClient width100 text-center">Estadísticas Rapidas</p>
                            <input type="date" defaultValue={moment().format('YYYY-MM-DD')}
                                id="inputDate"
                                onChange={(e)=>{
                                    updateDate(e.target.value)
                                }}
                            />
                        </div>
                        <div className="modal-body bodyNewUser">
                            <div className="width100">
                                <p className="colorBlackLitgh tittleStatsPart">{quotesOfDay} cotizaciones</p>
                                <div className="d-flex">
                                    <p className="pe-2">JOS({statsJose}%)</p>|
                                    <p className="ps-2 pe-2">HUM({statsHumber}%)</p>|
                                    <p className="ps-2 pe-2">OTROS({statsOthers}%)</p>
                                </div>
                                <p className="subTittleStatsPart">$ {priceTotal}</p>
                                <div className="d-flex">
                                    <p className="pe-2">CAT A:({porcentA}%)</p>|
                                    <p className="ps-2 pe-2">CAT B: ({porcentB}%)</p>|
                                    <p className="ps-2 pe-2">CAT C: ({porcentC}%)</p>|
                                    <p className="ps-2 pe-2">CAT D: ({porcentD}%)</p>
                                </div>                              
                            </div>
                            <div className="width100">
                                <p className="colorBlackLitgh tittleStatsPart">{totalParts} partidas</p>
                                <div className="d-flex">
                                    <p className="pe-2">Partidas JOS({porcentPartsJose}%)</p>|
                                    <p className="ps-2 pe-2">Partidas HUM({porcentPartsHumber}%)</p>|
                                    <p className="ps-2 pe-2">Partidas OTROS({porcentPartsOthers}%)</p>
                                </div>
                                <p className="subTittleStatsPart">{partsYesCot} cotizadas ({((partsYesCot*100)/totalParts).toFixed(2)} %)</p>
                                <div className="d-flex">
                                    <p className="pe-2">CAT({porcentCatalog}%)</p>|
                                    <p className="ps-2 pe-2">SRC({porcentScraping}%)</p>|
                                    <p className="ps-2 pe-2">MAN({porcentManual}%)</p>
                                </div> 
                                <p className="subTittleStatsPart">{partsPending} pendientes ({((partsPending*100)/totalParts).toFixed(2)} %)</p>
                                <p className="subTittleStatsPart">{partsDiscarted} descartadas ({((partsDiscarted*100)/totalParts).toFixed(2)} %)</p>                               
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-around footerNewUser">
                            <button className="stylebuttonStats" onClick={()=>{
                                props.close()
                            }}>
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopUpStatsVentas