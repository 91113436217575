import React, { useContext, useState, useEffect } from "react";//*module that starts react is imported
import { useAuth } from "../context/authContext";
import { informationOfExport } from "../context/informationOfExport"
import { informationOfSearch } from "../context/informationOfSearch";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from 'react-router-dom'
import Papa from 'papaparse'
//#region all components imported
import Navbar from "../Components/Navbar";
import ListQuotes from "../Components/ListQuotesComponent";
import CatalogComponent from "../Components/CatalogComponent";
import PopUpStats from "../Components/PopUpStats"
import PopUpSearchClient from "../Components/PopUpSearchClient";
import PopUpNewUsers from "../Components/PopUpNewUsers"
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";

//#endregion

//*start component
const Home = () =>{

    //VARIABLES
    //*Singletons
    const { loading, user } = useAuth()
    const { setIdZoho, setAlias, idZoho } = useContext( informationOfExport )
    const { manualSearch, manualSearchDummy } = useContext( informationOfSearch )
    const { setArrItemsToSearch } = useContext(functionsToImplementNewStructure)

    const navigate = useNavigate()

    //*Hooks UseState
    const [ showAlert, setShowAlert ] = useState (false)
    const [ hasAlias, setHasAlias ] = useState (false)
    const [ hasIdZoho, setHasIdZoho ] = useState (false)
    const [ hasInfo, setHasInfo ] = useState (false)
    const [ popUpActive, setPopUpActive ] = useState (false)
    const [ arrToSend, setArrToSend ] = useState ([])
    const [ selectClient, setSelectClient ] = useState(false)
    const [ newClient, setNewClient ] = useState(false)

    //FUNCTIONS
    //if (user.email==="juanmanuel@voltz.mx"||user.email==="juanmanuel@voltz.mx") console.log("funciona")
    
    //console.log(idZoho)

    const changeHasInfo = () => {
        if(hasAlias===true && hasIdZoho===true) {
            setHasInfo(true)
            setShowAlert(false)
        }
    }

    //*launchs of searchs
    const launchManualSearch = () => {
        if(!hasInfo) {
            setShowAlert(true)
        }else {
            setShowAlert(!showAlert)
            navigate("/datosManuales")
        }
    }

    const launchAutomaticSearch = () => {
        if(!hasInfo) {
            setShowAlert(true)
        }else {
            setShowAlert(!showAlert)
        }
    }

    const readExcel = (e) => {        
        const myPromise = new Promise ((resolve, reject) => {
            let num
            Papa.parse(e,{
                complete: results => {
                    results.data.map((item,index) => {
                        if(item[1]===""||item[1]===undefined) {
                            num = 1
                        }else {
                            num = parseInt(item[1])
                        }
                        if(item[0]!=="") {
                            setArrToSend(arrToSend.push({
                                requested_product: item[0],
                                requestedAmount: num,
                                position: index+1,
                                status: "to_search"
                            }))
                        }
                        
                        if(index>=results.data.length-1){
                            //console.log(arrToSend)
                            resolve()
                        }

                        return arrToSend
                    })
                }
            })
        })

        myPromise.then(() => {
            //manualSearch(arrToSend)
            //console.log(arrToSend)
            setArrItemsToSearch(arrToSend)
            setPopUpActive(true)
        })
    }

    //*Return something to paint
    return( 
        <>
            <Navbar/>
            
            <div id="containerHome" className="d-flex">
                <div id="containerHomeLeft" className="d-flex justify-content-center align-items-center">
                    <div id="containerHomeInLeft">

                        <h2 id="tittleHomeLeft">Crear</h2>

                        <h2 id="subTittleHomeLeft">nueva cotización</h2>

                        <div className="form-group containerInput">
                            <span className="bi bi-person-fill form-control-feedback"></span>
                            <button className="form-control" 
                                id="inputHomeIdZoho" 
                                onClick={()=>{setSelectClient(true)}}>
                                {
                                    idZoho?
                                    idZoho:
                                    "Ingresa el cliente"
                                }
                            </button>
                        </div>

                        <div className="form-group containerInput">
                            <span 
                                className="bi bi-file-earmark-fill form-control-2feedback"
                            ></span>
                            <textarea 
                                type = "text" 
                                name = "alias"
                                placeholder = "Alias de cotizacion"
                                onChange = { e =>{ 
                                    setAlias(e.target.value.toUpperCase())
                                    setHasAlias(true)
                                    {changeHasInfo()}
                                }}
                                className = "form-control"
                                id = "inputHomeAlias"
                                maxLength = {120}
                            />
                        </div>

                        <div className="d-flex justify-content-center flex-wrap">
                            {
                                hasInfo ?
                                    <>
                                        <input type="file" id="upload" hidden 
                                            accept=".csv"
                                            onChange={ (e)=> {
                                                const file = e.target.files[0]
                                                readExcel(file)
                                            }}
                                        />
                                        <label id="labelInputHomeBlue" for="upload">Automática (CSV)</label>
                                    
                                        <button id ="buttonHomeLeftYellow" onClick={ launchManualSearch } >
                                            Manual
                                        </button>
                                    </>                                  
                                :
                                <>
                                    <button id ="buttonHomeLeftBlue" onClick={ launchAutomaticSearch } >
                                        Automática (CSV)
                                    </button>
                                    <button id ="buttonHomeLeftYellow" onClick={ launchManualSearch }>
                                        Manual
                                    </button>
                                </>                                    
                            }
                            
                            <Alert show={showAlert} variant="alert" >Ingrese el Id del cliente.</Alert>
                        </div>
                        
                    </div>
                </div>
                <div id="containerHomeRight">
                    <div>
                        <ListQuotes></ListQuotes>
                    </div>
                </div>
            </div>
            {
                popUpActive ?
                <PopUpStats hide={() => {setPopUpActive(false)}}/>
                :   ''
            }

            {
                selectClient?
                <PopUpSearchClient hide={()=>{setSelectClient(false)}}
                    newUser={()=>{setNewClient(true)}}
                    setHasIdZoho = {()=>{setHasIdZoho(true)}}
                    changeHasInfo={()=>changeHasInfo()}
                    fromNewQuote = {true}
                />
                : ''
            }  

            {
                newClient ?
                <PopUpNewUsers close={()=>{setNewClient(false)}}
                    setHasIdZoho = {()=>{setHasIdZoho(true)}}
                    changeHasInfo={()=>changeHasInfo()}
                />
                :''
            }
        </>    
    )
}
export default Home //*module export, to be used