
import Hit from "./CatalogHitComponent";

const EditSelectedLineComponent = ({arrResultsOfSelected}) => {

   
    return (
        <div className={"catalog-edit-container"}>
            {arrResultsOfSelected.map((hit, index) => (
                Hit({hit : {...hit, fromSelectedList : true}})
            ))}
        </div>
    );

}

export default EditSelectedLineComponent