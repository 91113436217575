
import { useContext, useState, useEffect } from "react";
import { Highlight } from 'react-instantsearch-hooks-web';
import { editSearchSelectedItem } from "../context/editSearchSelectedItem";
import { numberFormat } from "./CatalogComponent";
import picture_icon from '../Img/NO_PHOTO.svg';

const Hit = ({ hit }) =>{

    const {resolveOnSelectedItem} = useContext(editSearchSelectedItem)
    let isDisabled
    
    if(hit.status === "Disponible"){
        isDisabled = false
    }else {
        isDisabled = true
    }
    
    //if(hit.isManual===true)console.log(hit)

    return (
        <div key={hit.objectID} style={{ backgroundColor: hit.cardColor }} className="catalog-card" >
            {
                <div style={{display: 'flex', marginTop: "8px", marginBottom: "0px"}}>
                    {'image_cover' in hit ? 
                    <img width={120} height={120} src={hit.image_cover} /> : <img width={120} height={120} src={picture_icon}/>
                    }
                    <div width={140} style={{marginRight: "8px", marginLeft: "8px"}}>
                        {   hit.transformedFeatures != null &&
                            <span className="catalog-sku-features">
                                {hit.transformedFeatures}
                            </span>
                        }
                    </div>
                    
                </div>
            }
            {
                <div style={{marginTop: "8px", marginBottom: "0px"}}>
                    <span className='catalog-cards-content'>
                        {hit.brand}
                    </span>
                </div>
            }
            <span className='catalog-cards-content'>
                <span>{hit.sku}</span>
            </span>
            {   'fromSelectedList' in hit ?
                //<Highlight className='catalog-sku-description' attribute="sku_description" hit={hit} /> 
                 <p className='catalog-sku-description'>{hit.sku_description}</p> : <p className='catalog-sku-description'>{hit.sku_description}</p>
            }
            
            <div style={{marginTop: "8px", marginBottom: "8px"}} className='wrap'>
                { 
                    <div className='pb-3 d-flex'>
                        <span className='catalog-sku-price' >
                            {'Precio Lista: ' + numberFormat(hit.price_public) + ' ' + hit.price_currency}
                        </span>
                    </div>
                    
                }
            </div>
            
            <div>
                {
                    hit.status==="Disponible"?
                        hit.suppliers ?
                            /*Object.values(hit.suppliers).map((e,i)=>(

                                //e.price_best === undefined ? console.log(hit) : console.log(".")
                                e.price_best !== undefined ?
                                    <button className="d-flex buttonSupplierCatalog btn"
                                        onClick = {() => {
                                            if(isDisabled=== true) {
                                                
                                            }else {
                                                console.log(hit)
                                                resolveOnSelectedItem(hit,e)
                                            }
                                        }}
                                    >  
                                        
                                        <p className="ps-1">{e.supplier_name} ${Number((e.price_best).toFixed(2))} {e.currency}; Inv. ({e.stock})</p>
                                            
                                    </button>
                                :
                                    ''
                            ))*/
                            <button className="d-flex  buttonSupplierCatalog btn text-center"
                                onClick = {() => {
                                    if(isDisabled=== true) {
                                      
                                    }else {
                                        console.log(hit)
                                        resolveOnSelectedItem(hit)
                                    }
                                }}
                            >  
                                        
                                <p className="ps-1 text-center">Agregar</p>
                                            
                            </button>
                        :
                            <button className="btn" id="buttonNotStock"
                                onClick={()=>{
                                    if(hit.isManual===true){
                                        resolveOnSelectedItem(hit, hit)
                                    }
                                }}
                            >
                                No hay stock
                            </button>        
                    :
                        <button className="btn" id="buttonNotStock"
                            onClick={()=>{
                                if(hit.isManual===true){
                                    resolveOnSelectedItem(hit, hit)
                                }
                            }}
                        >
                            No hay stock
                        </button>
                }
            </div>
        </div>
        
    );
  }

export default Hit