import {db} from '../fireBase'
import {
    getFirestore,
    query,
    orderBy,
    onSnapshot,
    collection,
    getDoc, 
    getDocs, 
    addDoc,
    setDoc,
    updateDoc,
    doc, 
    serverTimestamp, 
    arrayUnion,
    limit, 
    where,
    deleteDoc
} from 'firebase/firestore'

export const streamCompaniesItems = (snapshot, error) => {
    const itemsColRef = collection(db, 'company')
    const itemsQuery = query(itemsColRef,orderBy("name","desc"))
    return onSnapshot(itemsQuery, snapshot, error)
}

export const streamUsersItems = (snapshot, error) => {
    const itemsColRef = collection(db, 'profile-user')
    const itemsQuery = query(itemsColRef,orderBy("created_at","desc"))
    return onSnapshot(itemsQuery, snapshot, error)
}

export const addOnlyNewClientUser = async (user, statusVal ,company) => {
    console.log(user)
    let full_name_dummy

    if(user.second_name !== null){
        full_name_dummy = user.first_name + " " + user.second_name + " " + user.last_name
    }else {
        full_name_dummy = user.first_name  + " " + user.last_name
    }

    let idCompany1
    if(statusVal==="independent"){
        idCompany1 = null
    }else{
        if(company.id!==undefined){
            idCompany1 = company.id
        }else{
            idCompany1 = null
        }
    }    
    //console.log(company)

    var mapUser = {
        zoho_id: null,
        created_at: serverTimestamp(),
        updated_at: null,
        email: user.email,
        category: user.category,
        company_id: idCompany1,
        credential_id: null, //opcional, la persona puede o no tener credencial para acceder a las apps de voltz
        first_name: user.first_name,
        second_name: user.second_name,
        last_name: user.last_name,
        full_name: full_name_dummy,
        phone_1: "+52" + user.phone_1, 
        ext_phone_1: user.ext_phone_1,
        phone_2: null, 
        ext_phone_2: null,
        position: null,
        rfc: null,
        status: statusVal,
        CFDI_use: {
            code: null,
            description: null,
        },
        billing_address: {
            country: "Mexico", //segun el pais asi seran los campos abajo, por ahora solo manejamos Mexico
            entidad_federativa: null,
            municipio_o_delegacion: null,
            localidad: null,
            colonia: null,
            nombre_calle: null,
            no_exterior: null,
            no_interior: null,
            entre_calle: null,
            y_calle: null, 
            referencias_adicionales: null,
            codigo_postal: null,
        },
        shipping_address: [{
            country: "Mexico", //segun el pais asi seran los campos abajo, por ahora solo manejamos Mexico
            entidad_federativa: null,
            municipio_o_delegacion: null,
            localidad: null,
            colonia: null,
            nombre_calle: null,
            no_exterior: null,
            no_interior: null,
            entre_calle: null,
            y_calle: null, 
            referencias_adicionales: null,
            codigo_postal: null,
            geo_location: {
                latitude: null,
                longitude: null,
            },
            how_to_arrrive: null, //instrucciones de llegada para un conductor, cuando el gps no funciona en esa zona
            delivery_schedule: [
                {day: "Lunes", from: null, to: null, status: null},
                {day: "Martes", from: null, to: null, status: null},
                {day: "Miércoles", from: null, to: null, status: null},
                {day: "Jueves", from: null, to: null, status: null},
                {day: "Viernes", from: null, to: null, status: null},
                {day: "Sabado", from: null, to: null, status: null},
                {day: "Domingo", from: null, to: null, status: null}
            ], 
            delivery_instructions: null
        }],
        companies: [idCompany1],
        version: 2,
        assisted: true
    }
    console.log(mapUser)
    const colUser = collection(db,'profile-user')
    const docRefUser = await addDoc(colUser,mapUser)
    console.log("este es el usuario: ",mapUser)
    return docRefUser.id
}

export const addOnlyCompany = async (nameCompany) => {
    var mapCompany = {
        zoho_id: null,
        business_sector: null,
        created_at: serverTimestamp(),
        updated_at: null,
        name: nameCompany,
        rfc: null,
        CFDI_use: {
            code: null,
            description: null,
        },
        billing_address: {
            country: "Mexico", //segun el pais asi seran los campos abajo, por ahora solo manejamos Mexico
            entidad_federativa: null,
            municipio_o_delegacion: null,
            localidad: null,
            colonia: null,
            nombre_calle: null,
            no_exterior: null,
            no_interior: null,
            entre_calle: null,
            y_calle: null, 
            referencias_adicionales: null,
            codigo_postal: null,
        },
        shipping_address: [{
            country: "Mexico", //segun el pais asi seran los campos abajo, por ahora solo manejamos Mexico
            entidad_federativa: null,
            municipio_o_delegacion: null,
            localidad: null,
            colonia: null,
            nombre_calle: null,
            no_exterior: null,
            no_interior: null,
            entre_calle: null,
            y_calle: null, 
            referencias_adicionales: null,
            codigo_postal: null,
            geo_location: {
                latitude: null,
                longitude: null,
            },
            how_to_arrrive: null, //instrucciones de llegada para un conductor, cuando el gps no funciona en esa zona
            delivery_schedule: [
                {day: "Lunes", from: null, to: null, status: null},
                {day: "Martes", from: null, to: null, status: null},
                {day: "Miércoles", from: null, to: null, status: null},
                {day: "Jueves", from: null, to: null, status: null},
                {day: "Viernes", from: null, to: null, status: null},
                {day: "Sabado", from: null, to: null, status: null},
                {day: "Domingo", from: null, to: null, status: null}
            ], 
            delivery_instructions: null
        }],
        type: null,
        web_site: null,
        notes: null,
        persons: [],
        version: 2
    }
    //console.log(mapCompany)
    const colCompany = collection(db,"company")
    console.log("Este es el objeto a mandar: ",mapCompany)
    const docRefCompany = await addDoc(colCompany,mapCompany)
    return docRefCompany
}

export const addIdPersonToCompany = async(idCompany, idPerson) => {
    const colCompany = doc(db,"company", idCompany)
    //console.log(colCompany)
    const docRefCompany = await updateDoc(colCompany,{
        persons: [idPerson]
    })
    console.log("se actualizo")
    return
}

export const addNewUserAndNewCompany = async (company,user) => {
    const colUser = collection(db,'profile-user')
    const colCompany = collection(db,'company')
    const docRefUser = await addDoc(colUser,{
        created_at: serverTimestamp(),
        name: user.first_name,
        phone: user.phone_1,
        email: user.email,
        //position: user.position
        category: user.category
    })
    const docRefCompany = await addDoc(colCompany, {
        created_at: serverTimestamp(),
        name: company.name,
        //city: company.city,
        //category: company.category,
        //web_site: company.web_site,
        business_sector: company.business_sector,
        rfc: company.rfc,
        //type: company.type,
        users: [{
            id:docRefUser.id,
            //name: user.name,
            //phone: user.phone,
            //email: user.email,
            //position: user.position
        }]
    })
    return docRefCompany.id
}




export const updateClient = (id, arr, id2, arr2) => {
    //console.log(id2)
    const quotesDocRef = doc(db, "company", id)
    const quotesDocRef2 = doc(db, "profile-user", id2)

    updateDoc(quotesDocRef2,{
        update_at: serverTimestamp(),
        ...arr2
    })

    return updateDoc(quotesDocRef,{
        update_at: serverTimestamp(),
        ...arr
    })
}

export const deleteClient = async (id1, id2) =>{
   //const quotesDocRef = doc(db,"company", id)
   await deleteDoc(doc(db,"company",id1))
   await deleteDoc(doc(db,"profile-user",id2))
}

export const getCompany = async (id) =>{
    //console.log(id)
    const docRef = doc(db, "company", id)
    const docSnap = await getDoc(docRef)
    const val = docSnap.data()
    let nameC
    let numberC 
    //console.log(val)
    val.name !== undefined ? nameC=val.name : nameC= null
    val.number_company !== undefined ? numberC=val.number_company : numberC= null
    //console.log(val)
    let infoCompany = {
        name: nameC,
        number_company: numberC
    }
    return infoCompany
}

export const getUser = async (id) => {
    const docRef = doc(db, "profile-user", id)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
}

export const getAllCompany = async (id) =>{
    //console.log(id)
    const docRef = doc(db, "company", id)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
}

export const updateData = async (id, nameData, newData) => {
    const queryUpdate = doc(db, nameData, id)
    updateDoc(queryUpdate,{
        update_at: serverTimestamp(),
        ...newData
    })
    console.log("se actualizo data")
}