import { useState, useEffect } from "react";
import { streamCurrencies } from "../Data/CurrencyServices";

export const GetCurrencies = () => {
    
    const [currencies, setCurrencies] = useState([])

    useEffect( () => {
        const unsubscribe = streamCurrencies(
            (querySnapshot) => {
                const items = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    items.push({...data, id: doc.id})
                })
                setCurrencies(items)
            }
        )
        return () => unsubscribe()
    }, [])
    return currencies
}