import { createContext, useState, useContext } from "react";

export const informationOfExport = createContext()

export const InfoExport = ({ children }) => {

    const [ idZoho, setIdZoho ] = useState()//*Saves the IdZho value entered
    const [ alias, setAlias ] = useState()//*Saves the Alias value entered
    const [ category, setCategory ] = useState()
    const [ idClient, setIdClient ] = useState()

    return(
        <informationOfExport.Provider value ={{
            setIdZoho,
            setAlias,
            idZoho,
            alias,
            category,
            setCategory,
            setIdClient,
            idClient
        }}>
            {children}
        </informationOfExport.Provider>
    )
}