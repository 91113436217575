import React from "react";

const PopUpConfirmOrDisconfirm = (props) => {

    //console.log(props.idOrder)

    return(
        <>
            {
                props.toConfirmPopUp ? 
                    <div className="modal pt5" id="modalStyle">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <p className="width100 text-center" id="tittlePopUpConfirm">¿Cómo pagó?</p>
                                </div>
                                <div className="modal-body" id="containerButtonsTypePay">
                                    <div className="d-flex flex-wrap">
                                        <button className="buttonTypePayment"
                                            onClick={()=>{
                                                //props.setMethodOfPayment("transfer")
                                                props.confirmOrDisconfirmPayment(props.idOrder, true, "transfer")
                                                props.hide()
                                            }}
                                        >
                                            Transferencia
                                        </button>
                                        <button className="buttonTypePayment mt-3"
                                            onClick={()=>{
                                                //props.setMethodOfPayment("cash")
                                                props.confirmOrDisconfirmPayment(props.idOrder, true, "cash")
                                                props.hide()
                                            }}
                                        >
                                            Efectivo
                                        </button>
                                        <button className="buttonTypePayment mt-3"
                                            onClick={()=>{
                                                //props.setMethodOfPayment("transfer")
                                                props.confirmOrDisconfirmPayment(props.idOrder, true, "pasalera")
                                                props.hide()
                                            }}
                                        >
                                            Pasarela
                                        </button>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                :
                <div className="modal pt5" id="modalStyle">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <p className="width100 text-center" id="tittlePopUpConfirm">¿Desconfirmar el pago?</p>
                            </div>
                            <div className="modal-body" id="containerButtonsTypePay">
                                <div className="d-flex flex-wrap">
                                    <button className="buttonTypePayment"
                                        onClick={()=>{
                                            //props.setMethodOfPayment(" ")
                                            props.confirmOrDisconfirmPayment(props.idOrder, false, "")
                                            props.hide()
                                        }}
                                    >
                                        Si, aún no ha pagado
                                    </button>
                                    <button className="buttonTypePayment mt-3"
                                        onClick={()=>{
                                            props.hide()
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default PopUpConfirmOrDisconfirm