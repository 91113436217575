import React, { useState, useContext, useEffect, useRef } from 'react';
import Navbar from '../Components/Navbar';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
//import { GetQuotesAccepted, GetQuotesCancel, GetQuotesPublish } from '../Hooks/ActiveQuotesHooks'
import { informationOfSearch } from '../context/informationOfSearch';
import { informationOfExport } from '../context/informationOfExport';
import { reverseStatus } from "../Data/QuoteServices"
import { functionsToImplementNewStructure } from '../context/functionsToImplementNewStructure';
import { GetAllQuotesDetail, GetAssistQuotesDetail, GetAutoQuotesDetail } from '../Hooks/ImplementNewStructure';
import InfiniteScroll from 'react-infinite-scroll-component';

const ReviewQuotes = () => {
    let limitCount = 10
    const [limit, setLimit] = useState(10);
    const [limitAuto, setLimitAuto] = useState(10)
    const [limitFiltered, setLimitFiltered] = useState(10)
    const { firstTimeToEnter } = useContext(functionsToImplementNewStructure)
    //const quotesArrPublish = GetQuotesPublish();
    //const quotesArrCancel = GetQuotesCancel();
    //const quotesArrAccepted = GetQuotesAccepted()
    const quotesArrAssist = GetAssistQuotesDetail(limit);
    const quotesArrAuto = GetAutoQuotesDetail(limitAuto)
    //Se hace un conteo con limite para que se pagine desde la base de datos
    const quotesArrQuoteDetail = GetAllQuotesDetail(limit)
    //console.log(quotesArrPublish)
    const [data, setData] = useState([]); // Datos a mostrar en el scroll
    const [page, setPage] = useState(1); // Número de página actual
    const [paginatedQuotes, setPaginatedQuotes] = useState([]);
    const [pageAuto, setPageAuto] = useState(1); // Número de página actual
    const [paginatedQuotesAuto, setPaginatedQuotesAuto] = useState([]);
    const [sortedQuotes, setSortedQuotes] = useState([]);



    /*
        useEffect(() => {
    
            quotesArrCancel.map((item, index) => {
                //console.log(item.arrResults)
                item.arrResults.map((item2, index2) => {
                    let totalAprox = 0
                    //console.log(item2.value[0].price.value)
                    //console.log(item.arrResults.length, index2)
                    totalAprox = item2.value[0].price.value + totalAprox
                    if (item.arrResults.length - 1 === index2) {
                        //console.log(totalAprox)
                        //console.log(quotesArrCancel[index])
                        quotesArrCancel[index].totalAprox = totalAprox
                    }
                })
                //console.log(totalAprox)
            })
            //console.log(quotesArrCancel)
        }, [quotesArrCancel])
        */

    const navigate = useNavigate();

    const { getTotalAprox, editQuoteSelect, updateQuotePublish, setCostOfDelivery } = useContext(informationOfSearch)
    const { setIdZoho, setAlias, setCategory } = useContext(informationOfExport)

    const [arrFilter, setArrFilter] = useState([])
    const [elementFilter, setElementFilter] = useState()
    const [elementsToShow, setElementsToShow] = useState("assist")

    //console.log(quotesArrCancel)

    const editQuote = async (quoteId) => {
        try {
            const data = await firstTimeToEnter(quoteId, "reviewQuotes")
            navigate('/results')
        } catch (error) {
            console.log(error)
        }
    }

    const navigateToQuote = (id, version) => {
        var Url
        if (typeof (version) === "undefined") {
            Url = "https://material.voltz.mx/?cotz=" + id
        } else {
            //Url = process.env.REACT_APP_URL_V2 + id
            Url = "https://app.voltz.mx/?cotz=" + id
        }

        window.open(Url)
    }

    const loadMoreQuotesFiltered = () => {
        setLimitFiltered(limitFiltered + 10);
    };


    const activeFilter = (e) => {
        setElementFilter(e.target.value.toUpperCase())
        //console.log(elementFilter)
        let arrFilter1 = quotesArrQuoteDetail.filter(correct => {
            //correct.alias.includes(elementFilter)
            if (correct.alias.includes(elementFilter)) return correct
        })
        setArrFilter(arrFilter1)
        setElementsToShow("filtered")
        //console.log(arrFilter1)
    }

    /* este efecto filtra y pagina pero tiene un error. deja el elementFilter sin datos y no muestra nada cuando carga la pagina
    useEffect(() => {
        const arrFilter1 = quotesArrQuoteDetail.filter((correct) => {
          if (correct.alias.includes(elementFilter)) return correct;
        });
        setArrFilter(arrFilter1);
        setLimitFiltered(10); // Reinicia el límite al cambiar el filtro
      
        if (elementFilter === "") {
          setElementsToShow("assist");
        } else {
          setElementsToShow("filtered");
        }
      }, [elementFilter, quotesArrQuoteDetail]);
*/
    const calculateTotalsCancel = (quoteId) => {
        getTotalAprox(quoteId)
    }

    const revertAccepted = async (quoteId) => {
        try {
            await reverseStatus(quoteId)
            setElementsToShow("assist")
        } catch (error) {
            console.log(error)
        }
    }
    const loadMoreQuotes = () => {
        setLimit(limit + 10);
    };

    const loadMoreQuotesAuto = () => {
        setLimitAuto(limitAuto + 10);
    };


    //pagina la imformacion una vez recibida desde quotesArrQuoteDetail haciendo infiniteScroll
    useEffect(() => {
        const newData = quotesArrAssist.slice(0, limit * page);
        setPaginatedQuotes(newData);
    }, [page, limit, quotesArrAssist]);



    useEffect(() => {
        const newDataA = quotesArrAuto.slice(0, limitAuto * pageAuto);
        setPaginatedQuotesAuto(newDataA);
    }, [pageAuto, limitAuto, quotesArrAuto]);


   

    return (
        <>
            <Navbar />
            <div className="d-flex justify-content-between" id='containerButtonsReview'>
                <button id='buttonReturnHome' onClick={() => {
                    navigate('/')
                    navigate(0)
                }}>
                    Home
                </button>
                <div id='containeButtonsAcceptOrCancel'>

                    {
                        elementsToShow === "assist" &&
                        <>
                            <button className="buttonDesActiveRQ" onClick={() => setElementsToShow("auto")}>Autogestión</button>
                            <button className="buttonActiveRQ"> Asistidas </button>
                        </>
                    }
                    {
                        elementsToShow === "auto" &&
                        <>
                            <button className="buttonActiveRQ" >Autogestión</button>
                            <button className="buttonDesActiveRQ" onClick={() => setElementsToShow("assist")}> Asistidas </button>
                        </>
                    }

                    {
                        elementsToShow === "filtered" &&
                        <>
                            <button className="buttonDesActiveRQ" onClick={() => setElementsToShow("auto")} >Autogestión</button>
                            <button className="buttonDesActiveRQ" onClick={() => setElementsToShow("assist")}> Asistidas </button>
                        </>
                    }

                </div>
                <div id='containerFilter'>
                    <input id='inputAliasToSearch' type="text" onChange={(e) => {
                        activeFilter(e)
                    }} />
                </div>
            </div>
            <div className="d-flex flex-wrap">
                {
                    elementsToShow === "assist" &&
                    <InfiniteScroll
                        dataLength={paginatedQuotes.length}
                        next={loadMoreQuotes}
                        hasMore={paginatedQuotes.length <= quotesArrAssist.length}
                        loader={<h4>Cargando...</h4>}
                        endMessage={<p style={{ textAlign: 'center' }}>No hay más datos.</p>}
                        className="d-flex flex-wrap"
                    >
                        {
                            paginatedQuotes.map((data, index) =>

                                //console.log(data);
                                <div key={data.id} className='width20 cardQuote'>
                                    <span id='labelAlias'>{data.alias}</span><br></br>
                                    <span id='labelContent'>
                                        <span>{data.id_cliente}</span><br></br>
                                    </span>
                                    <span id='labelContent'>
                                        {
                                            //console.log(data.author.email)
                                            <span>{data.author?.email || data.customer?.nameOfClient || "---"}</span>
                                        }


                                    </span>
                                    <span>
                                        {
                                            data.created_at !== null && typeof (data.created_at) !== "undefined" ?
                                                <span>{moment(data.created_at.toDate()).local().format("YYYY-MM-DD HH:mm")}</span> :
                                                <span></span>
                                        }
                                    </span>

                                    <div style={{ marginTop: "12px" }} className='d-flex wrap'>
                                        <div id='consecutiveContainer-Review'>
                                            <span id='consecutiveNumber-Review'>
                                                {
                                                    data.consecutive ?
                                                        data.consecutive : "Nuevas"
                                                }
                                            </span>
                                        </div>
                                        <div id='cardDecoration-Review' className='d-flex'>
                                            <button id='cardDecorationArrow-Review' className='btn'
                                                onClick={() => {
                                                    editQuote(data.id)
                                                }}
                                            >
                                                <i className="bi bi-pencil-square"></i>
                                            </button>
                                            <button id='cardDecorationArrow-Review'
                                                className='btn'
                                                onClick={() => { navigateToQuote(data.id, data.version) }}
                                                target="_blank"
                                            >
                                               
                                                <i className="fa-solid fa-arrow-right fa-sm	" ></i>
                                            </button>
                                        </div>

                                    </div>
                                    <p className='m-2'>

                                        Total Aprox: {

                                            typeof (data.totals) !== "undefined" && data.totals !== null ?
                                                Intl.NumberFormat('es-Mx', { style: "currency", currency: "MXN" }).format(data.totals.total.toFixed(2)) :
                                                "$----"
                                        }
                                    </p>
                                </div>
                            )
                        }
                    </InfiniteScroll>
                }
                {
                    elementsToShow === "auto" &&
                    <InfiniteScroll
                        dataLength={paginatedQuotesAuto.length}
                        next={loadMoreQuotesAuto}
                        hasMore={paginatedQuotesAuto.length <= quotesArrAuto.length}
                        loader={<h4>Cargando...</h4>}
                        endMessage={<p style={{ textAlign: 'center' }}>No hay más datos.</p>}
                        className="d-flex flex-wrap"
                    >
                        {
                            paginatedQuotesAuto.map((data, index) =>

                                //console.log(data);
                                <div key={data.id} className='width20 cardQuote'>
                                    <span id='labelAlias'>{data.alias}</span><br></br>
                                    <span id='labelContent'>
                                        <span>{data.id_cliente}</span><br></br>
                                    </span>
                                    <span id='labelContent'>
                                        {
                                            //console.log(data.author.email)
                                            <span>{data.author?.email || data.customer?.nameOfClient || "---"}</span>
                                        }


                                    </span>
                                    <span>
                                        {
                                            data.created_at !== null && typeof (data.created_at) !== "undefined" ?
                                                <span>{moment(data.created_at.toDate()).local().format("YYYY-MM-DD HH:mm")}</span> :
                                                <span></span>
                                        }
                                    </span>

                                    <div style={{ marginTop: "12px" }} className='d-flex wrap'>
                                        <div id='consecutiveContainer-Review'>
                                            <span id='consecutiveNumber-Review'>
                                                {
                                                    data.consecutive ?
                                                        data.consecutive : "Nuevas"
                                                }
                                            </span>
                                        </div>
                                        <div id='cardDecoration-Review' className='d-flex'>
                                            <button id='cardDecorationArrow-Review' className='btn'
                                                onClick={() => {
                                                    editQuote(data.id)
                                                }}
                                            >
                                                <i className="bi bi-pencil-square"></i>
                                            </button>
                                            <button id='cardDecorationArrow-Review'
                                                className='btn'
                                                onClick={() => { navigateToQuote(data.id, data.version) }}
                                                target="_blank"
                                            >
                                                <i className="fa-solid fa-arrow-right fa-sm	" ></i>
                                            </button>
                                        </div>

                                    </div>
                                    <p className='m-2'>

                                        Total Aprox: {

                                            typeof (data.totals) !== "undefined" && data.totals !== null ?
                                                Intl.NumberFormat('es-Mx', { style: "currency", currency: "MXN" }).format(data.totals.total.toFixed(2)) :
                                                "$----"
                                        }
                                    </p>
                                </div>
                            )
                        }
                    </InfiniteScroll>
                }

                {
                    elementsToShow === "filtered" &&
                    <>
                        <InfiniteScroll
                            dataLength={arrFilter.length}
                            next={loadMoreQuotesFiltered}
                            hasMore={arrFilter.length < quotesArrQuoteDetail.length}
                            loader={<h4>Cargando...</h4>}
                            endMessage={<p style={{ textAlign: 'center' }}>No hay más datos.</p>}
                            className="d-flex flex-wrap width100"
                            
                        >   
                            {
                                
                                arrFilter.map((data, index) =>
                                    //console.log(data);
                                    <div key={data.id} className={`cardQuote ${arrFilter.length === 1 ? "width100" : "width20"}`}>
                                        <span id='labelAlias'>{data.alias}</span><br></br>
                                        <span id='labelContent'>
                                            <span>{data.id_cliente}</span><br></br>
                                        </span>
                                        <span id='labelContent'>
                                            <span>{data.author?.email || data.customer?.nameOfClient || "---"}</span><br></br>
                                            {
                                                data.created_at !== null ?
                                                    <span>{moment(data.created_at.toDate()).local().format("YYYY-MM-DD HH:mm")}</span> :
                                                    <span></span>
                                            }
                                        </span>

                                        <div style={{ marginTop: "12px" }} className='d-flex wrap'>
                                            <div id='consecutiveContainer-Review'>
                                                <span id='consecutiveNumber-Review'>
                                                    {data.consecutive}
                                                </span>
                                            </div>
                                            <div id='cardDecoration-Review' className='d-flex'>
                                                <button id='cardDecorationArrow-Review' className='btn'
                                                    onClick={() => {
                                                        editQuote(data.id)
                                                    }}
                                                >
                                                    {console.log(data.id)}
                                                    <i className="bi bi-pencil-square"></i>
                                                    {console.log(firstTimeToEnter())}
                                                </button>
                                                <button id='cardDecorationArrow-Review'
                                                    className='btn'
                                                    onClick={() => { navigateToQuote(data.id, data.version) }}
                                                    target="_blank"
                                                >
                                                     
                                                    <i className="fa-solid fa-arrow-right fa-sm	" ></i>
                                                </button>
                                            </div>

                                        </div>
                                        <p className='m-2'>
                                            Total Aprox: {
                                                typeof (data.totalAprox) !== "undefined" ?
                                                    Intl.NumberFormat('es-Mx', { style: "currency", currency: "MXN" }).format(data.totalAprox.toFixed(2)) :
                                                    "-----"
                                            }
                                        </p>
                                    </div>
                                )
                            }
                        </InfiniteScroll>

                    </>
                }
            </div>
        </>
    )
}

export default ReviewQuotes