
import Navbar from "../Components/Navbar";
import CatalogComponent from "../Components/CatalogComponent";
import { EditSearchSelectedItem } from "../context/editSearchSelectedItem";

//*start component
const CatalogPage = () =>{

    return (
        <div style={{overflow: "hidden"}}>
            <div className="catalog-head">
                <Navbar></Navbar>
            </div>

            <div style={{marginTop: "80px"}}></div>
            
        <EditSearchSelectedItem>
        <CatalogComponent></CatalogComponent>
        </EditSearchSelectedItem>
           
        </div>
    );
}

export default CatalogPage