import React, { useState, useEffect, useContext } from 'react';
import {GetQuotes} from '../Hooks/ActiveQuotesHooks'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { informationOfSearch } from '../context/informationOfSearch';
import { informationOfExport } from '../context/informationOfExport';
import { GetQuotesFirst15 } from '../Hooks/ImplementNewStructure';
import { functionsToImplementNewStructure } from '../context/functionsToImplementNewStructure';

function ListQuotes(props) {
    //VARIABLES
    //*Singleton
    const { setIdZoho, setAlias, setCategory} = useContext(informationOfExport)
    const { editQuoteSelect, setCostOfDelivery } = useContext(informationOfSearch)
    const { firstTimeToEnter } = useContext(functionsToImplementNewStructure)

    const navigate = useNavigate()
    const quotesArr = GetQuotesFirst15();

    async function navigate_to_detail (quoteId) {
        try {
            const data = await firstTimeToEnter(quoteId,"listHome")
            navigate('/results')
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <div>
                <h2 id="titleQuotes">Cotizaciones en Curso</h2>
            </div>
            <div id="containerListQuotes" className="d-flex flex-wrap">
                {
                quotesArr.map((data, index) =>

                <div key={data.id} id="cardQuote"  onClick = {() => navigate_to_detail(data.id)}>
                    <span id='labelAlias'>{data.alias}</span><br></br>
                    <span id='labelContent'>
                        <span>{data.id_cliente}</span><br></br>
                    </span>
                    <span id='labelContent'>
                        {
                            data.author && data.author.email && typeof(data.author.email) !== "undefined" && data.author.email !== null ?
                            <span>{data.author.email}</span> :
                            <span>----</span>
                        }
                        {
                            data.created_at!==null ? 
                            <span>{moment(data.created_at.toDate()).local().format("YYYY-MM-DD HH:mm")}</span> :
                            <span></span>
                        }
                    </span>
                    
                    <div style={{marginTop: "12px"}} className='wrap'>
                        <div id='consecutiveContainer'>
                            <span id='consecutiveNumber'>
                                {data.consecutive}
                            </span>
                        </div>
                        <div id='cardDecoration'>
                            <span id='cardDecorationArrow'>
                                <i className="fa-solid fa-arrow-right fa-sm	"></i>
                            </span>
                            
                        </div>
                        <p className='m-2'>
                            Total Aprox: { 
                                //console.log(data)
                                typeof(data.totals) !== "undefined" && data.totals !== null ?
                                Intl.NumberFormat('es-Mx',{style:"currency", currency:"MXN"}).format(data.totals.total.toFixed(2)):
                                "$----"
                            }
                        </p>
                    </div>
                    
                </div>
                
                )                
                }
            </div>
        </div>
        
      );
   
}

export default ListQuotes //*module export, to be used
