import { useState, useEffect } from 'react'
import { streamQuotesDetail, streamQuotesDetailAssist, streamQuotesDetailToHome, streamQuotesDetailAuto } from '../Data/newQuoteServices'

export function GetAllQuotesDetail(limitCount) {
  const [quotes, setQuotes] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);

  useEffect(() => {
    const unsubscribe = fetchQuotes();

    return () => unsubscribe();
  }, []);

  const fetchQuotes = () => {
    const snapShot = (snapshot) => {
      const items = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        items.push({ ...data, id: doc.id });
      });

      setQuotes((prevQuotes) => [...prevQuotes, ...items]);

      if (snapshot.docs.length > 0) {
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      } else {
        setLastDoc(null);
      }
    };

    return streamQuotesDetail(lastDoc, limitCount, snapShot);
  };

  return quotes;
}

export function GetAssistQuotesDetail(limit) {
  const [ quotesAssist, setQuotesAssist ] = useState([])

  useEffect(() => {
    const unsubscribe = streamQuotesDetailAssist(
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          items.push({...data, id: doc.id});
        });
        setQuotesAssist(items);
      },
      limit
    );
    return () => unsubscribe();
  }, [limit]);
  return quotesAssist;
}

export function GetAutoQuotesDetail(limitAuto) {
  const [ quotesAuto, setQuotesAuto ] = useState([])

  useEffect(() => {
    const unsubscribe = streamQuotesDetailAuto(
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          items.push({...data, id: doc.id});
        });
        setQuotesAuto(items);
      },
      limitAuto
    );
    return () => unsubscribe();
  }, [limitAuto]);
  //console.log("Limite asistida", limit);
    
  return quotesAuto;
}

export function GetQuotesFirst15() {
    const [quotesAccepted, setQuotesAccepted] = useState([])
  
    useEffect(() => {
      const unsubscribe = streamQuotesDetailToHome(
        (querySnapshot) => {
          const items = []
          querySnapshot.forEach((doc) => {
            const data = doc.data ()
            items.push({...data, id: doc.id})
          })
          setQuotesAccepted(items)
        }
      )
      return () => unsubscribe()
    },[])
  
    return quotesAccepted
  }