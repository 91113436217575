import { createContext, useState, useContext, useEffect } from "react";
import algoliasearch from "algoliasearch";
import uuid from "react-uuid";
import { getQuote, 
    moveDiscartedToPending, 
    movePendingToDiscarted, 
    serviceMoveAllToDiscarted, 
    addNewItem, 
    serviceAssingReasonDiscarted,
    serviceAddItemManual,
    addPendingDeleteResults,
    upDateUnit
} from "../Data/QuoteServices"
import { useNavigate } from "react-router-dom";
import { updateStatusNextActions, addProductInDetail } from "../Data/QuoteServices";

export const informationOfSearch = createContext()

export const InfoSearch = ({ children }) => {

    //VARIABLES
    //*Variables of algolia
    const searchClient = algoliasearch(
        process.env.REACT_APP_APPLICATION_ID,
        process.env.REACT_APP_SEARCH_API_KEY
    )
    const indexSerch = searchClient.initIndex(process.env.REACT_APP_NAME_INDEX)

    const navigate = useNavigate()

    const [ arrResults, setArrResults ] = useState ([]) //*Save values with reults
    const [ arrNotResults, setArrNotResults ] = useState ([]) //*save values without results
    const [ arrDiscarted, setArrDiscarted ] = useState ([])
    const [ idQuote, setIdQuote ] = useState()
    const [ total, setTotal ] = useState()
    const [ arrDocument, setArrDocument ] = useState()
    const [ costOfDelivery, setCostOfDelivery ] = useState()
    const [ clientOfReview, setClientOfReview ] = useState()
    const [ dataManual, setDataManual ] = useState([])
    const [ editQuote,setEditQuote ] = useState(false)
    
    const updateArrResultsFromCatalog = (oldItem, newHits) => {
        console.log(" el old item es .... ", oldItem)
        console.log(" el new array de hits es ... ", newHits)
        let indexLocal = arrResults.findIndex(item=>item.id===oldItem.id)
        console.log(arrResults[indexLocal])        
        let newData = {
            nameItemOfSearch: oldItem.nameItemOfSearch,
            id: oldItem.id,
            position: oldItem.position,
            requestedAmount: oldItem.requestedAmount,
            value: [...newHits]
        }
        console.log(newData)
        setArrResults(e=>{
            e.splice(indexLocal,1)
            return[...e.splice(indexLocal,0,newData), ...e]
        })
        updateTotal()
        updateStatusNextActions(idQuote)           
    }
    
    const arrayErase = () => {
        setArrResults(e=>{
            return[
                ...e.splice(0),
                ...e
            ]
        })
        setArrNotResults(e=>{
            return[
                ...e.splice(0),
                ...e
            ]
        })
    }

    const manualSearchDummy = (arrToSend) => {
        console.log(arrToSend)
        setArrDocument(arrToSend)
        arrToSend.map( (item,index) => {
            setArrNotResults(e=>{
                return[
                    ...e,
                    {
                        nameItemOfSearch: item.nameItem,
                        id: uuid(),
                        position: item.position,
                        requestedAmount: item.requestedAmount,
                        reasonDiscarted: "No Aplicate",
                        value:[]
                    }
                ]
            })
        })
    }

    const manualSearch = (arrToSend) => {
        //console.log("entramos no se sabe resultados")
        setArrDocument(arrToSend)
        arrayErase()
        const myPromise = new Promise (( resolve, reject)=> {
            arrToSend.map((item, index) => (
                indexSerch.search(item.nameItem, {
                    attributesToRetrieve: ['sku_description', 'sku', 'brand',
                    'price', 'color', 'gender', 'supplier', 'sale_unit', 'sub_brand', 'image_urls',
                    'sale_value', 'description', 'tech_file', 'suppliers', 'features'],
                    hitsPerPage:1
                }).then(( { hits } ) => {
                    //console.log(hits)
                    
                    if(hits.length===0) {
                        setArrNotResults(e=>{
                            return[
                                ...e,
                                {
                                    nameItemOfSearch: item.nameItem,
                                    id: uuid(),
                                    position: item.position,
                                    requestedAmount: item.requestedAmount,
                                    reasonDiscarted: "No Aplicate",
                                    value:[]
                                }
                            ]
                        })
                    } else {
                        console.log(hits[0].image_urls[0])
                        let nameProperty = Object.keys(hits[0].suppliers)
                        let base = hits[0].suppliers[nameProperty[0]]
                        hits[0].selected = true
                        hits[0].price = {
                            price_best: base.price_best,
                            supplier_code: base.supplier_code,
                            supplier_name: base.supplier_name, 
                            stock: base.stock, 
                            currency: base.currency, 
                            price_margin: .15,
                            price_margin_name: "DEFAULT"
                        }
                        if(hits[0].image_urls[0]!==undefined){
                            hits[0].image_cover = hits[0].image_urls[0]
                        }                       
                        
                        //console.log(hits)
                        setArrResults(e=>{
                            return [
                                ...e,
                                {
                                    nameItemOfSearch: item.nameItem,
                                    id: uuid(),
                                    position: item.position,
                                    requestedAmount: item.requestedAmount,
                                    reasonDiscarted: "No Aplicate",
                                    value: hits
                                }
                            ]
                        })
                    }
                    if(arrToSend.length-1===index) {
                        resolve(arrResults,arrNotResults)
                    }
                })
            ))
        })

        myPromise.then( () => {
            assignProperty()
        })
    }

    const assignProperty = () => {
        console.log(arrResults)
    }

    const sortArrs = async () => {


        setArrResults(arrResults=>{
            return arrResults.sort(( a , b ) => a.position - b.position)
        })
        setArrNotResults (arrNotResults => {
            return arrNotResults.sort(( a , b ) => a.position - b.position)
        })
        setArrDiscarted ( arrDiscarted =>{
            return arrDiscarted.sort(( a , b ) => a.position - b.position)
        })

    }

    const moveResultToNotResult = async (index) => {
        arrNotResults.push({
            nameItemOfSearch: arrResults[index].nameItemOfSearch,
            id: arrResults[index].id,
            position: arrResults[index].position,
            requestedAmount: arrResults[index].requestedAmount, 
            value: []
        })        
        //console.log(arrNotResults)
        
        setArrResults(prevItems=>{
            return prevItems.filter(prevItems => prevItems.id !== arrResults[index].id)
        })
        //console.log(arrResults)
        sortArrs()
        updateTotal()
        updateStatusNextActions(idQuote)
    }

    const moveResultToDiscarted = async (index) => {
        arrDiscarted.push({
            nameItemOfSearch: arrResults[index].nameItemOfSearch,
            id: arrResults[index].id,
            position: arrResults[index].position,
            requestedAmount: arrResults[index].requestedAmount,
            reasonDiscarted: "No Encontrado", 
            value: []
        })

        setArrResults(prevItems=>{
            return prevItems.filter(prevItems => prevItems.id !== arrResults[index].id)
        })
        
        sortArrs()
        updateTotal()
        updateStatusNextActions(idQuote)
    }

    const addNewItemManual = (index, newItem, fromResults, requestedAmount2) => {
        //console.log(newItem)
        arrNotResults[index].requestedAmount = requestedAmount2
        console.log(arrNotResults[index])
        if(fromResults===true){
            arrResults[index].value.push(newItem)
        }else {
            newItem.selected = true
            console.log(newItem)////********CHECAR Q TRAE ITEM Y SI SI LO ASIGNA */
           arrNotResults[index].value.push(newItem)
           moveNotResultsToResults(index)
        }
        updateTotal()
        //console.log(newItem.price)
        //console.log(arrNotResults[index])

        newItem.price = {
            currency: "MXN",
            price_best: newItem.price.price_best,
            stock: 1,
            supplier_code: newItem.brand,
            supplier_name: newItem.brand,
            price_margin: .15,
            price_margin_name: "DEFAULT"
        }
        
        let newData = {
            nameItemOfSearch: arrNotResults[index].nameItemOfSearch,
            id: arrNotResults[index].id,
            position: arrNotResults[index].position,
            requestedAmount: requestedAmount2,
            value: [newItem]
        }
        //console.log(newData)

        //console.log(idProductNotResult)
        if(editQuote===true){
            addProductInDetail(idQuote, newData, 'quote-detail')
        }
        //updateStatusNextActions(idQuote)
    }

    const editSkuManual = (index1, index2, newItem) => {
        let arrDummy = arrResults
        arrDummy[index1].value.splice(index2,1)
        arrDummy[index1].value.push(newItem)
        console.log(arrDummy)
        updateTotal()
        updateStatusNextActions(idQuote)
    }

    const moveNotResultsToResults = async (index) => {
        //console.log(arrNotResults[index])
        arrResults.push(arrNotResults[index])
        setArrNotResults(prevItems=>{
            return prevItems.filter(prevItems => prevItems.id !== arrNotResults[index].id)
        })
        sortArrs()
        updateTotal()
        updateStatusNextActions(idQuote)
    }

    const removeElementManual = (index1, index2) => {
        let changeData = arrResults.slice()
        changeData[index1].value.splice(index2,1)
        //console.log(changeData)
        //arrResults[index1].value.splice(index2, 1)
        setArrResults(changeData)
    }

    const moveNotResultsToDiscarted = async (index) => {
        let itemDummy = {
            id: arrNotResults[index].id,
            nameItemOfSearch: arrNotResults[index].nameItemOfSearch,
            position: arrNotResults[index].position,
            reasonDiscarted: "No Encontrado",
            requestedAmount: arrNotResults[index].requestedAmount,
            value: []
        }
        arrDiscarted.push(itemDummy)
        setArrNotResults(prevItems=>{
            return prevItems.filter(prevItems => prevItems.id !== arrNotResults[index].id)
        })
        if(editQuote===true){
            movePendingToDiscarted(idQuote, itemDummy, 'quote-detail')
        }
        sortArrs()
    }

    const addManualInNotResults = (newItem) => {
        let dummy = {
            requested_products: newItem.nameItemOfSearch,
            id: newItem.id,
            position: newItem.position,
            quantity: 1,
            reasonDiscarted: "No Aplicate",
            value:[]
        }
        arrNotResults.push(newItem)
        if(editQuote===true){
            addNewItem(idQuote, 'quote-detail', dummy)
        }
    }

    const moveDiscartedToNotResults = async (index) => {
        console.log(arrDiscarted[index])
        //arrDiscarted[index].reasonDiscarted = "No Aplicate"
        let itemDummy = {
            id: arrDiscarted[index].id,
            position: arrDiscarted[index].position,
            quantity: arrDiscarted[index].requestedAmount,
            requested_products: arrDiscarted[index].nameItemOfSearch 
        }
        arrNotResults.push(arrDiscarted[index])
        setArrDiscarted(prevItems=>{
            return prevItems.filter(prevItems => prevItems.id !== arrDiscarted[index].id)
        })
        
        console.log(arrNotResults)
        if(editQuote===true){
            moveDiscartedToPending(idQuote, itemDummy, 'quote-detail')
        }
        sortArrs()
        updateTotal()
    }

    const moveAllToDiscarted = () => {
        arrNotResults.map((item,index)=>{
            arrDiscarted.push(item)
        })
        setArrNotResults(e=>{
            e.splice(0)
            return e
        })
        
        if(editQuote===true){
            serviceMoveAllToDiscarted(idQuote, "quote-detail")
        }        
        sortArrs()
    }

    const assignReasonDiscarted = (rason,index) => {
        console.log("El indice de descartado es ", index)
        let itemDummy = {
            id: arrDiscarted[index].id,
            nameItemOfSearch: arrDiscarted[index].nameItemOfSearch,
            position: arrDiscarted[index].position,
            reasonDiscarted: rason,
            requestedAmount: arrDiscarted[index].requestedAmount,
            value: []
        }

        let arrFilteredDummy = arrDiscarted.filter(item => item.id !== arrDiscarted[index].id)
       
        serviceAssingReasonDiscarted(idQuote,"quote-detail",itemDummy)

        setArrDiscarted([
            itemDummy, 
            ...arrFilteredDummy
        ])
        sortArrs()
        console.log("asi queda el valor de arrDiscarded despues de actualizarce ... ", arrDiscarted)
    }

    

    const editQuoteSelect = async (idQuote, fromPublish) => {
        setIdQuote(idQuote)
        setEditQuote(fromPublish)
        try {
            const data = await getQuote(idQuote)
            console.log(data)
            assingDataToArrays(data)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    const assingDataToArrays = (data) => {
        if(data.arrResults!==undefined){
            setArrResults(data.arrResults)            
        }
        if(data.arrNotResults!==undefined) {
            setArrNotResults(data.arrNotResults)            
        }
        if(data.arrDiscarted!==undefined) {
            setArrDiscarted(data.arrDiscarted)            
        }

        navigate('/results')
    }

    const getTotalAprox = async (idQuote) => {
        setIdQuote(idQuote)
        try {
            const data = await getTotalAprox(idQuote)
                console.log(data)
                return data
            } catch (error) {
                console.log(error)
            }
    } 

    const moveNotResultsToResultsCatalog = (itemToEdit, arrResultsOfSelected, indexArrNotResults, saveRequestedAmount) => {
        //console.log(e)
        arrResultsOfSelected.map((item,index)=>{
            if(index===0){
                item.selected=true
            }else {
                item.selected=false
            }
        })
        //console.log(arrResultsOfSelected)
        let indexLocal = arrNotResults.findIndex(item=>item.id===itemToEdit.id)
        //console.log(arrNotResults[indexLocal])       
        let newData = {
            nameItemOfSearch: itemToEdit.nameItemOfSearch,
            id: itemToEdit.id,
            position: itemToEdit.position,
            requestedAmount: saveRequestedAmount,
            value: [...arrResultsOfSelected]
        }
        //console.log("hasta aqui va bien")
       
        setArrResults(prevItems=>{
            //console.log("entramos")
            return [...prevItems, newData
            ]
        })

        //console.log("hasta aqui va bien")
        
        setArrNotResults(prevItems=>{
            return prevItems.filter(prevItems => prevItems.id !== arrNotResults[indexLocal].id)
        })
        sortArrs()
        //se anade el producto en firebase
        if(editQuote===true){
            addProductInDetail(idQuote, newData, 'quote-detail')
        }
        updateTotal()
        updateStatusNextActions(idQuote)
    }

    const updateTotal = () => {
        let dummy = 0
        //console.log(arrResults[0])
        arrResults.map((item,index)=>{
            //console.log(item)
            item.value.map((item2, index2)=>{
                //console.log(item2)
                if(item2.selected===true){
                    dummy = dummy + (item.requestedAmount * item2.price.price_best)
                    setTotal( Intl.NumberFormat('es-MX',{style:'currency', currency:'MXN'}).format(dummy.toFixed(2)))
                }                
            })            
        })
        //console.log(total)
    }

    const updateRequestedAmount = (index, value) => {
        let dummy = {
            id: arrResults[index].id,
            nameItemOfSearch: arrResults[index].nameItemOfSearch,
            position: arrResults[index].position,
            requestedAmount: parseInt(value),
            value: arrResults[index].value
        }
        setArrResults(e=>{
            e.splice(index,1)
            return[...e.splice(index,0,dummy), ...e]
        })
        updateTotal()
        updateStatusNextActions(idQuote)
    }

    const updatePrice = (index1, index2, newPrice) => {
        //let arrDummy = arrResults[index1].value[index2].price.value
        //let arrDummyMain = 
        setArrResults(e =>{
            e[index1].value[index2].price.value=newPrice
            e[index1].value[index2].price.currency="MXN"
            return[...e]
        })
        updateTotal()
    }

    const activateRecommendation = (index1, index2) => {
        let indexWithSelected
        //console.log(indexWithSelected)
        arrResults[index1].value.map((item,index)=>{
            if(item.selected === true){
                indexWithSelected = index
            }
            if(arrResults[index1].value.length-1===index){
                //console.log("terminamos el recorridp")
                if(indexWithSelected != undefined){
                    console.log("llegamos")
                    setArrResults(e => {
                        e[index1].value[indexWithSelected].selected = false
                        e[index1].value[index2].selected = true
                        return[...e]
                    })                    
                }
            }
            //console.log(arrResults[index1].value)
        })
        /*//console.log(arrResults[index1].value[index2])
        let isMarket=false

        arrResults[index1].value.map((item,index)=>{
            console.log(item, isMarket)
            if(item.selected===false && isMarket===false){
                isMarket=false
            } else {
                isMarket=true
            }
        })
        //console.log(arrResults[index1].value[index2].selected)

        if(!isMarket || arrResults[index1].value[index2].selected===true){
            setArrResults(e=>{
                e[index1].value[index2].selected= !e[index1].value[index2].selected
                return[...e]
            })
            isMarket=!isMarket
        }
        //console.log(arrResults[index1].value[index2])*/
        updateStatusNextActions(idQuote)
    }

    const asignDataManual = (arr) => {
        console.log(arr)
        setDataManual(arr)
    }

    const deleteItemResultsAddNotResults = (item) =>{
        //console.log(item)
        let dataInfo = {
            id: item.id,
            position: item.position,
            quantity: item.requestedAmount,
            requested_products: item.nameItemOfSearch
        }
        let dataArrNotResults = {
            id: item.id,
            position: item.position,
            requestedAmount: item.requestedAmount,
            nameItemOfSearch: item.nameItemOfSearch,
            value:[]
        }
        let arrDummy = arrResults.filter(element=>element.id!==item.id)
        setArrResults(arrDummy)
        let arrDummy2 = [...arrNotResults]
        arrDummy2.push(dataArrNotResults)
        setArrNotResults(arrDummy2)
        addPendingDeleteResults(dataInfo, item, idQuote, "quote-detail")
        //console.log(dataInfo)
    }

    const changePiezaToMetro = (index) =>{
        console.log(arrResults[index].id)
        arrResults[index].value[0].sale_unit = "metro"
        //let data = arrResults[index]
        upDateUnit(idQuote, arrResults[index].id)
    }

    useEffect(()=>{
        if(arrResults.length>0) updateTotal()
    },[arrResults])

    return(
        <informationOfSearch.Provider value = {{
            manualSearch,
            arrResults,
            arrNotResults,
            arrayErase,
            sortArrs,
            moveResultToNotResult,
            moveResultToDiscarted,
            arrDiscarted,
            addNewItemManual,
            removeElementManual,
            moveNotResultsToDiscarted,
            moveDiscartedToNotResults,
            assignReasonDiscarted,
            addManualInNotResults,
            updateArrResultsFromCatalog,
            editSkuManual,
            editQuoteSelect,
            setIdQuote,
            idQuote,
            moveNotResultsToResultsCatalog,
            updateTotal,
            total,
            updateRequestedAmount,
            updatePrice,
            moveAllToDiscarted,
            getTotalAprox,
            arrDocument,
            activateRecommendation,
            editQuote,
            setCostOfDelivery,
            costOfDelivery,
            clientOfReview,
            setClientOfReview,
            dataManual,
            asignDataManual,
            manualSearchDummy,
            deleteItemResultsAddNotResults,
            changePiezaToMetro
        }}>
            {children}
        </informationOfSearch.Provider>
    )
}