import { useContext, useEffect, useState } from "react";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Highlight, Configure, Pagination, RefinementList} from 'react-instantsearch-hooks-web';
import EditSelectedLineComponent from "./EditSelectedLineComponent";
import { editSearchSelectedItem } from "../context/editSearchSelectedItem";
import Hit from './CatalogHitComponent';
import { informationOfSearch } from "../context/informationOfSearch";
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";
import { createDocCatalogInCart } from "../Data/newQuoteServices";
const searchClient = algoliasearch("N2BMFF9FQC", "f9b2fdd04f39ab7247898eecea1bd3c9");

export const numberFormat = (value) =>
  new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN'
  }).format(value);


const transBrandItems = (items) => {
    return items.map((item) => ({
      ...item,
      label: item.label.toUpperCase(),
      count: " (" + item.count + ")"
    }));
  };
 

const CatalogComponent=({editMode = false, hideModal, itemToEdit, addSkuFromNotResults, indexArrNotResults, changes, setChanges})=> {

    const {transformCatalogResultItems, arrResultsOfSelected, arrSearchedLine, initEdition } = useContext(editSearchSelectedItem)
    const { updateArrResultsFromCatalog, moveNotResultsToResultsCatalog } = useContext(informationOfSearch)
    const { idQuote, docId, setQuantityUpdate, quantityUpdate } = useContext(functionsToImplementNewStructure)

    const [ saveRequestedAmount, setSaveRequestedAmount ] = useState(1)

    //console.log(addSkuFromNotResults)
    // Debido al [] vacio, el useEffect se ejecuta una sola vez


    /*useEffect(() => {
        if(editMode === true){
            initEdition(itemToEdit)
        }
    }, []);*/
    
    //console.log(indexArrNotResults)

    function onClickSave(){
        updateArrResultsFromCatalog(itemToEdit, arrResultsOfSelected)
        hideModal()
    }
    
    function onClickCancel(){
        console.log("ESTE ARRAY ES EL ORIGINAL", itemToEdit)
        updateArrResultsFromCatalog(itemToEdit, itemToEdit.value)
        hideModal()
    }

    const onClickSaveFromNotResults = async () => {
        //console.log("llegamos")
        //moveNotResultsToResultsCatalog(itemToEdit, arrResultsOfSelected, indexArrNotResults, saveRequestedAmount)
        let product_id = arrResultsOfSelected[0].objectID
        await createDocCatalogInCart(idQuote,docId,product_id,quantityUpdate)
        hideModal()
    }

    const onClickCancelFromNotResults = () => {
        hideModal()
    }    

    return (
        <InstantSearch searchClient={searchClient} indexName="ecommerce_products">
        <Configure analytics={true} hitsPerPage={50}/>
        <div className='catalog-frame'>
            
                {
                    editMode === true && 
                    <div className="catalog-head-modal">
                        <span className="catalog-modal-title">
                            Catalogo
                        </span>
                        {
                            addSkuFromNotResults ?
                            <>
                                <button className="btn btn-primary catalog-save-button" disabled={arrResultsOfSelected.length > 0 ? null : true} 
                                    onClick={() => {
                                        onClickSaveFromNotResults()
                                        setChanges(changes+1)
                                    }}>Guardar</button>
                                <button className="btn btn-secondary catalog-cancel-button" onClick={() => {onClickCancelFromNotResults()}}>Cancelar</button>
                            </>
                            :
                            <>
                                <button className="btn btn-primary catalog-save-button" disabled={arrResultsOfSelected.length > 0 ? null : true} 
                                    onClick={() => {
                                        onClickSave()
                                        setChanges(changes+1)
                                    }}>Guardar</button>
                                <button className="btn btn-secondary catalog-cancel-button" onClick={() => {onClickCancel()}}>Cancelar</button>
                            </>    
                        }
                        
                        
                    </div>
                }
                
                <div className="catalog-frame-root-middle">
                    <div className='catalog-frame-middle'>
                        <div className='catalog-container-search-box'>
                            <SearchBox placeholder="Buscar productos..." classNames={{root:"catalog-root-search-box", submitIcon: 'catalog-search-icon', resetIcon: 'catalog-reset-icon', input: 'catalog-search-box catalog-search-box:focus'}} ></SearchBox>  
                        </div>
                        
                        <div id='catalog-container-lists' className='catalog-container-lists' >
                            {<RefinementList 
                            showMore={true}
                            limit={10}
                            classNames={{
                                root: 'catalog-filter',
                                item: 'catalog-filter-item',
                                checkbox: 'catalog-filter-checkbox',
                                list: 'catalog-filter-list',
                            }}
                            searchablePlaceholder="Buscar por marca..."
                            transformItems={transBrandItems}
                            attribute="brand" searchable="true" /> }
                            <Hits 
                                transformItems={transformCatalogResultItems}
                                classNames={{
                                    root: "catalog-list-root",
                                    list: 'catalog-list',
                                }}
                                hitComponent={Hit} />
                        </div>
                    </div>
                    {
                        editMode ===true &&
                        <div >
                            <div className="catalog-original-searched overflow-auto">
                                <p>{itemToEdit.requested_product}</p>
                                <input className="width23 ms-3" type="number" 
                                    onChange={(e)=>{
                                        setQuantityUpdate(e.target.value)
                                    }}
                                />
                            </div>
                            <EditSelectedLineComponent arrResultsOfSelected={arrResultsOfSelected}></EditSelectedLineComponent>
                        </div>
                    }
                </div>
                <div className='catalog-foot'>
                    <Pagination showPrevious={false} showNext={false} padding={10} classNames={{list: "catalog-pagination", item:'catalog-pagination-item', selectedItem: 'catalog-pagination-selected-item'}} />
                </div>
                
        </div>
        </InstantSearch>
    );

}

export default CatalogComponent //*module export, to be used