import { db } from '../fireBase'
import {
    getFirestore,
    query,
    orderBy,
    onSnapshot,
    collection,
    getDoc, 
    getDocs, 
    addDoc,
    setDoc,
    updateDoc,
    doc, 
    serverTimestamp, 
    arrayUnion,
    limit, 
    where,
    deleteDoc
} from 'firebase/firestore'

export const streamOrdersItems = ( snapshot, error ) => {
    const itemsColRef = collection(db, 'order-detail')
    const itemsQuery = query(itemsColRef, orderBy("consecutive","desc"))
    return onSnapshot (itemsQuery, snapshot, error)
}

export const confirmPaymentService = async (docId, toConfirm, reason) => {
    const itemsColRef = doc(db, 'order-detail', docId)
    if(toConfirm===true){
        await updateDoc(itemsColRef, {
            updated_at: serverTimestamp(),
            payment_status: "paid",
            payment_type: reason
        })
    }else {
        await updateDoc(itemsColRef, {
            updated_at: serverTimestamp(),
            payment_status: "pending"
        })
    }
    
    return docId
}

export const changeStatusOrder = async (docId, newStatus) =>{
    const itemsColRef = doc(db, 'order-detail', docId)
    if(newStatus==="enviado"){
        await updateDoc(itemsColRef, {
            'shipping.status': "shipped"
        })
    }else if(newStatus==="entregado") {
        await updateDoc(itemsColRef, {
            'shipping.status': "delivered"
        })
    }
}

export const GetOrder = async (id) => {
    const docRef = doc(db, 'order-detail', id)
    const data1 = await getDoc(docRef)
    const data2 = data1.data()
    //console.log(data2)
    return data2.detail
}

export const exportToExcel = async (id) => {
    const docRef = doc(db, 'order-detail', id)
    const data1 = await getDoc(docRef)
    const data2 = data1.data()
    //console.log(data2)
    return data2
}

export const exportToExcelCart = async (docId) => {
    let arrItems = []
    const orderRef = collection(db, 'order-detail/' + docId + '/cart')
    const orderCart = await getDocs(orderRef)
    orderCart.forEach(doc => {
        const data = doc.data()
        arrItems.push({...data, id: doc.id})
    });
    return arrItems
    
}

export const getProductforExport = async (docId) => {
    let arrItems = []
    const productRef = collection(db, 'products')
    const q = query(productRef, where('sku', '==', docId))
    const queryProd = await getDocs(q)
    queryProd.forEach(doc => {
        const data = doc.data()
        arrItems.push({...data})
    });
    return arrItems
}