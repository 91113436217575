import { db } from '../fireBase'
import {
    query,
    orderBy,
    onSnapshot,
    collection,
    getDoc,
    addDoc,
    setDoc,
    updateDoc,
    doc,
    serverTimestamp,
    limit,
    where,
    getDocs
} from "firebase/firestore"

export const streamCurrencies = (snapShot, error) => {
    const itemsColRef = collection(db, 'val-currency')
    //const itemsQuery = query(itemsColRef)
    return onSnapshot(itemsColRef, snapShot, error)
}

export const updateCurrency = (docId, currency) => {
    const currencyDocRef = doc(db, 'val-currency', docId)
    return updateDoc(currencyDocRef,{
        update_at: serverTimestamp(),
        ...currency
    })
}