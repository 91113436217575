import React from "react";
import { useNavigate } from 'react-router-dom'
import CatalogComponent from "../Components/CatalogComponent";
import { EditSearchSelectedItem } from "../context/editSearchSelectedItem";

function CatalogModal ({hide,arrHits, itemToEdit, addSkuFromNotResults, indexArrNotResults, changes, setChanges}) {

    //VARIABLES
    
    //console.log(arrHits)
    //console.log(itemToEdit)
    const navigate = useNavigate()

    const cancel = () => {   
        hide()
    }

    const hideModal = async () => {
        console.log("clic on hide modal...")
        hide()
    }


    return (
        <>
            <div className="modal" id="modal-catalog">
                <div className="modal-dialog  modal-fullscreen">
                    <div className="modal-content">
                        <div style={{width: "100vw", backgroundColor: "white", padding: "0px", paddingTop: "30px", overflowY: "hidden"}} className="modal-body">
                            <EditSearchSelectedItem>
                                <CatalogComponent  editMode={true} 
                                    hideModal={hideModal}
                                    itemToEdit={itemToEdit}
                                    addSkuFromNotResults = {addSkuFromNotResults}
                                    indexArrNotResults = {indexArrNotResults}
                                    changes={changes}
                                    setChanges={setChanges}
                                />
                            </EditSearchSelectedItem>
                        </div>
                        <div className="modal-footer">
                        
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    return(
        <>
            
            <div className="catalog-modal-frame">
                <div className="catalog-modal-centered">
                    <div className="catalog-modal" >
                        <EditSearchSelectedItem>
                            <CatalogComponent  editMode={true} hideModal={hideModal} itemToEdit={itemToEdit}/>
                        </EditSearchSelectedItem>
                    </div>
                </div>
            </div>
        </>
    )
    

    
}

export default CatalogModal