import React from "react";//*module that starts react is imported
import { useState } from "react";//*Imported HOOKS
import { useNavigate } from 'react-router-dom'//*IMPORTED navigate route
import { useAuth } from "../context/authContext";//*Imported singleton
//*imported all components
import Navbar from "../Components/Navbar";

const Login = () => {

    //#region //*extraction of external objects
    const { login, isLogin, handleIsLogin } = useAuth() //*this use for register a new user
    const navigate = useNavigate()//* this use for a navegate on the pages
    //#endregion

    //VARIABLES

    //#region //*get info variables and login errors. firebase
    //*save user data to register 
    const [user, setUser] = useState ({
        email: '',
        password: ''
    })//*Variable saved information of user
    const [error, setError] = useState()//*Saved error of login ---pwerror---emailerror
    //#endregion

    //FUNCTIONS

    //#region //*functions for Login functionality
    //*this function catch information of form
    const handeleChangeUserData = ({target: {name, value}}) => {
        setUser({...user, [name]: value})
    }

    //*this function ask call of firebase
    const sendingData = async (e) => {
        e.preventDefault()
        setError('')
        try {
            await login(user.email, user.password)
            handleIsLogin()
            navigate('/')
        } catch (error1) {            
            if(error1.code==="auth/wrong-password"){
                setError("Contraseña incorrecta")
            } if(error1.code==="auth/user-not-found") {
                //console.log(error1.code)
                setError("Correo incorrecto")
            }
        }       
    }
    //#endregion

    //Render of page
    return(
        <>
            <div id="containerNavBar">
                <Navbar isLogin={isLogin}/>               
            </div>

            <div id="containerForm">
                <p id="tittleForm">¡Bienvenido¡</p>
                
                <p id="subTittleForm">Bienvenido al cotizador. Por favor ingresa tus datos</p>
                
                <form className="d-flex justify-content-center flex-wrap"  onSubmit={sendingData}>
                    
                    <div className="form-group containerInput">
                        <span className="bi bi-person-fill form-control-feedback"></span>
                        <input 
                            type="email" 
                            name="email" 
                            placeholder="Usuario"
                            onChange={handeleChangeUserData}
                            className="form-control"
                            id="inputEmail"
                        />
                    </div>

                    <div className="form-group containerInput">
                        <span className="bi bi-key-fill form-control-feedback"></span>
                        <input 
                            type="password" 
                            name="password"
                            placeholder="Contraseña"
                            onChange={handeleChangeUserData}
                            className="form-control"
                            id="inputPassword"
                        />
                    </div>
                    
                    {error&&<><span id="spanError">{error}</span> </>}

                    <button className="float-right" id="buttonLogin">Ingresar</button>
                </form>
            </div>

            <div id="footerLogin"></div>
        </>
    )
}

export default Login //*module export, to be used