import { createContext, useState, useContext, useEffect } from "react";
import algoliasearch from "algoliasearch";
import { informationOfExport } from "./informationOfExport";
import { authContext } from "./authContext";
import { serviceCreateNewQuote, serviceCreateSubCollectionDraft, listenToQuote, bringAQuote } from "../Data/newQuoteServices";
import { informationOfSearch } from "./informationOfSearch";
import { updateDoc } from "firebase/firestore";

export const functionsToImplementNewStructure = createContext()

export const ImplementStruct = ({ children }) => {
    //se trae informacion del of search Context
    //Todo-----estas variables se va a quitar y se van a implementar directas para deja de usar ese context
    const { setCostOfDelivery } = useContext ( informationOfSearch )
    //TODO ESTAS SON VARIABLES DE CONTEXT GENERALES
    //se trae informacion del context export
    const { idZoho, setIdZoho, alias, idClient, setAlias } = useContext( informationOfExport )
    //se trae informacion del agente 
    const { user } = useContext(authContext)

    //TODO ESTAS VARIABLES SON DE ALGOLIA
    //initialize credentials of anlgolia
    const searchClient = algoliasearch(
        "N2BMFF9FQC",
        "f9b2fdd04f39ab7247898eecea1bd3c9"
        /*process.env.REACT_APP_APPLICATION_ID,
        process.env.REACT_APP_SEARCH_API_KEY*/
    )
    const indexSerch = searchClient.initIndex("ecommerce_products_bulk_search")

    //TODO ESTAS SON USESTATE QUE SE UTILIZA EN TODA LA APLICACION
    //Inicialize hoooks
    const [ arrAllItems, setArrAllItems ] = useState([])
    const [ arrClients, setArrClients ] = useState([])
    const [ arrDiscarted, setArrDiscarded ] = useState([])
    const [ arrItemsToSearch, setArrItemsToSearch ] = useState([])
    const [ arrNotResults, setArrNotResults ] = useState([])
    const [ arrResults, setArrResults ] = useState([])
    const [ docId, setDocId ] = useState()
    const [ idQuote, setIdQuote ] = useState()
    const [ itemAddProduct, setItemAddProduct ] = useState()
    const [ quantityUpdate, setQuantityUpdate ] = useState(1)
    const [ totalItems, setTotalItems ] = useState()
    const [ totalFound, setTotalFound ] = useState(1)
    const [ totalQuote, setTotalQuote ] = useState(0)

    //?TODO ESTAS FUNCIONES SIRVEN PARA LA BUSQUEDA DE ALGOLIA    
    //Esta funcion es la que hace la busqueda de los elementos del array en algolia
    const searchInAlgolia =  () =>{
        const myPromise = new Promise ((res,rej) => {
            arrItemsToSearch.map((item, index) => {
                indexSerch.search(item.requested_product,{
                    attributesToRetrieve: [ 'objectID' ],
                    hitsPerPage:1
                }).then(async({hits})=>{
                    if(hits.length > 0){
                        arrItemsToSearch[index].status="found"
                        arrItemsToSearch[index].product_id=hits[0].objectID
                    }
                    assignStats()
                })
            })
        })
    }

    //Esta funsion busca los elementos en algolia
    const serchClientsAlgolia = async (toSearch) => {
        let queries 
        try {
            /*queries = [
                {
                    indexName:process.env.REACT_APP_NAME_INDEX_COMPANIES,
                    query: toSearch,
                },
                {
                    indexName:process.env.REACT_APP_NAME_INDEX_CLIENTS,
                    query: toSearch
                }
            ]*/
            if(toSearch==="") {
                queries = [
                    {
                        indexName:"profile-user",
                        query: toSearch
                    }
                ]
            } else {
                queries = [
                    {
                        indexName:"profile-user",
                        query: toSearch,
                    },
                    {
                        indexName:"companies",
                        query: toSearch
                    }
                ]
            }            
            const results = await searchClient.multipleQueries(queries)
            const arrayResults = Object.entries(results)
            let arrDummy=[]
            await results.results.forEach(result=>{
                //console.log(result.hits)
                result.hits.forEach(hit=>{
                    arrDummy.push(hit)
                })                
            })
            setArrClients(arrDummy)
        } catch (error) {
            console.log(error)
        }
    }
    
    //TODO ESTAS FUNCIONES SRIVEN PARA CUANDO SE CREA UNA COTIZACION
    //Esta funcion asigna los valores de estadisticas que se usa en el popUpStats
    const assignStats = async () =>{        
        let count = 0
        setTotalItems(arrItemsToSearch.length)
        await arrItemsToSearch.map((item,index)=>{
            if(item.status==="found") count++
        })
        setTotalFound(count)
    }

    //Esta funcion indica el camino para crear una nueva cotizacion
    const createNewQuote = async () => {
        //primero se hace el mapeo de los datos iniciales
        let mapData = {
            accepted: false,
            alias: alias,
            author: {
                email: user.email,
                user_id: user.uid,
                is_admin: true
            },
            customer: {
                id: idClient,
                nameOfClient: idZoho
            },
            record: {
                next_action: null
            },
            shipping: {
                total: 0
            },
            version: 2
        }
        //crear el documento inical de la cotizacion 
        try {
            //aqui solo creal el documento inicial y asigna variables para revisar cot
            const idNewQuote = await serviceCreateNewQuote (mapData)
            //aqui se crean la subcoleccionDraft
            setIdQuote(idNewQuote)
            arrItemsToSearch.map(async item=>{
                await serviceCreateSubCollectionDraft(idNewQuote,item)
            })
            firstTimeToEnter(idNewQuote, true)
        } catch (error) {
            console.log(error)
        }    
    }

    //TODO ESTAS FUNCIONES SIRVEN PARA RECABAR DATOS DE LAS COTIZACIONES 
    //Esta funcion se ejecuta cuando por primera ves se entra a la cotizacion puede ser cuando 
    //se crea la cotizacion o cuando de le da click a una de las tarjetas de cotizacion
    const firstTimeToEnter = async (data, fromNew) => {
        let idQuoteDummy 
        if(fromNew==="listHome" || fromNew ==="reviewQuotes" ){
            setIdQuote(data)
        }
        if(fromNew===false){
            setIdQuote(data)
        }
        fromNew===true ? idQuoteDummy = idQuote : idQuoteDummy = data
        const quote = await bringAQuote(idQuoteDummy)
        //console.log(quote)
        setArrAllItems(quote[0])
        setAlias(quote[1].alias)
        setIdZoho(quote[1].customer.nameOfClient)
        quote[0].map(item=>{
            if(item.status === "to_search"){
                setArrNotResults(arrNotResults => [...arrNotResults,item])
            } else {
                if(item.status === "discarded"){
                    setArrDiscarded(arrDiscarted => [...arrDiscarted,item])
                }
            }
        })
    }

    useEffect(()=>{
        serchClientsAlgolia("")
    },[])

    useEffect(()=>{
        //searchInAlgolia()
        assignStats()
    },[arrItemsToSearch])

    useEffect(()=>{
        let arrOrdenado = [...arrResults]
        arrOrdenado.sort((a,b)=> a.position - b.position)
        if (!arraysIguales(arrResults, arrOrdenado))setArrResults(arrOrdenado)
    },[arrResults])

    useEffect(()=>{
        let arrOrdenado = [...arrNotResults]
        arrOrdenado.sort((a,b)=> a.position - b.position)
        if (!arraysIguales(arrNotResults, arrOrdenado))setArrNotResults(arrOrdenado)
    },[arrNotResults])

    useEffect(()=>{
        let arrOrdenado = [...arrDiscarted]
        arrOrdenado.sort((a,b)=> a.position - b.position)
        if (!arraysIguales(arrDiscarted, arrOrdenado)) setArrDiscarded(arrOrdenado)
    },[arrDiscarted])

    function arraysIguales(arr1, arr2) {
        if (arr1.length !== arr2.length) {
          return false;
        }
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i].id !== arr2[i].id) {
            return false;
          }
          if (arr1[i].position !== arr2[i].position) {
            return false;
          }
          // Agregar aquí las demás propiedades que se deben comparar
        }
        return true;
    }

    return(
        <functionsToImplementNewStructure.Provider value = {{
            //VARIABLES DE ESTADO
            arrAllItems,
            arrClients,
            arrDiscarted,
            arrItemsToSearch,
            arrNotResults,
            arrResults,
            docId,
            idQuote,
            itemAddProduct,
            quantityUpdate,
            totalItems,
            totalFound,
            totalQuote,     
            
            //MODIFICADORES DE VARIABLES DE ESTADO
            setArrDiscarded,
            setArrNotResults,
            setArrResults,            
            setArrItemsToSearch,
            setDocId,
            setItemAddProduct,
            setQuantityUpdate,

            //FUNCIONES
            createNewQuote,
            firstTimeToEnter,
            serchClientsAlgolia
        }}>
            {children}
        </functionsToImplementNewStructure.Provider>
    )
}