import {db} from '../fireBase'
import {
    getFirestore,
    query,
    orderBy,
    onSnapshot,
    collection,
    getDoc, 
    getDocs, 
    addDoc,
    setDoc,
    updateDoc,
    doc, 
    serverTimestamp, 
    arrayUnion,
    limit, 
    where,
    deleteDoc,
    FieldValue,
    arrayRemove,
    deleteField
} from 'firebase/firestore'

import axios from 'axios'

export const addIdDoc = async () => {
    const querySnapshot = await getDocs(collection(db,"company"))
    querySnapshot.forEach((item)=>{
        //let val = doc.data()
        let val = item.id
        let info = doc(db, "company", val)
        console.log(val)
        updateDoc(info,{
            id: val,
            ...item.data()
        })
    })
}

export const modifyCompany = async () => {
    let number = 1
    const querySnapshot = await getDocs(collection(db,"company"))
    querySnapshot.forEach((item)=>{
        let val = item.data()
        let info = doc(db, 'company', val.id)

        let business_sector1 = val.business_sector === "" ? null : val.business_sector
        let type1 = val.type === "" ? null : val.type
        let rfc1 = val.rfc === "" ? null : val.rfc
        let web_site1 = val.web_site === "" ? null : val.web_site

        //console.log(newMapCompany)
        updateDoc(info,{
            number_company: "EMP-"+number,
            category: val.category,
            id: val.id,
            zoho_id: null,
            business_sector: business_sector1,
            created_at: val.created_at,
            updated_at: serverTimestamp(),
            name: val.name,
            rfc: rfc1,
            CFDI_use: {
                code: null,
                description: null,
            },
            billing_address: {
                country: "Mexico", //segun el pais asi seran los campos abajo, por ahora solo manejamos Mexico
                entidad_federativa: null,
                municipio_o_delegacion: null,
                localidad: null,
                colonia: null,
                nombre_calle: null,
                no_exterior: null,
                no_interior: null,
                entre_calle: null,
                y_calle: null, 
                referencias_adicionales: null,
                codigo_postal: null,
            },
            shipping_address: [{
                country: "Mexico", //segun el pais asi seran los campos abajo, por ahora solo manejamos Mexico
                entidad_federativa: null,
                municipio_o_delegacion: null,
                localidad: null,
                colonia: null,
                nombre_calle: null,
                no_exterior: null,
                no_interior: null,
                entre_calle: null,
                y_calle: null, 
                referencias_adicionales: null,
                codigo_postal: null,
                geo_location: {
                    latitude: null,
                    longitude: null,
                },
                how_to_arrrive: null, //instrucciones de llegada para un conductor, cuando el gps no funciona en esa zona
                delivery_schedule: [
                    {day: "Lunes", from: null, to: null, status: null},
                    {day: "Martes", from: null, to: null, status: null},
                    {day: "Miércoles", from: null, to: null, status: null},
                    {day: "Jueves", from: null, to: null, status: null},
                    {day: "Viernes", from: null, to: null, status: null},
                    {day: "Sabado", from: null, to: null, status: null},
                    {day: "Domingo", from: null, to: null, status: null}
                ], 
                delivery_instructions: null
            }],
            type: type1,
            web_site: web_site1,
            notes: null,
            persons: [val.users[0].id],
            city: val.city,
            users: deleteField()
        })
        console.log("se actualizo" + " " + number)
        number++
    })
    console.log("se actualizaron")
}

export const modifyUser = async () => {
    let number = 1
    const querySnapshotCompany = await getDocs(collection(db, "company"))
    const querySnapshot = await getDocs(collection(db, "profile-user"))
    querySnapshot.forEach((item)=>{
        let val = item.data()
        let info = doc(db, "profile-user", item.id)
        let idCompany
        let categoryCompany
        querySnapshotCompany.forEach((item2)=>{
            let valCompany = item2.data()
            if(valCompany.persons[0]===item.id){
                idCompany = item2.id
                categoryCompany = valCompany.category
            }            
        })
        
        let email1 = val.email === "" ? null : val.email
        let phone1
        if (val.phone!==undefined) {
            if(val.phone.substr(0,3)==="+52"){
                phone1 = val.phone.slice(3)
            }else{
                phone1 = val.phone
            }
        }else{
            phone1 = val.phone_1
        }
        
        //console.log(phone1)
        let position1 = val.position === "" ? null : val.position
        let first_name1
        let second_name1
        let last_name1
        let full_name1
        if(val.name!==undefined){
            full_name1 = val.name
            let names = val.name.split(" ")
            if(names.length > 2) {
                first_name1=names[0]
                second_name1=names[1]
                last_name1=names[2]
            }if(names.length===1){
                first_name1=names[0]
                second_name1=null
                last_name1=null
            }if(names.length===2) {
                first_name1=names[0]
                second_name1=names[1]
                last_name1=null
            }
        }else {
            first_name1=val.first_name
            second_name1=val.second_name
            last_name1=val.last_name
            full_name1 = val.full_name
        }
        
        //console.log(first_name1,second_name1,last_name1)

        //console.log(val)
        //let ggg = 
        
        updateDoc(info,{
            zoho_id: null,
            name: deleteField(),
            phone: deleteField(),
            id: item.id,
            number_person: "VO-"+number,
            created_at: val.created_at,
            updated_at: serverTimestamp(),
            email: email1,
            category: categoryCompany,
            company_id: idCompany,
            credential_id: null, //opcional, la persona puede o no tener credencial para acceder a las apps de voltz
            first_name: first_name1,
            second_name: second_name1,
            last_name: last_name1,
            full_name: full_name1,
            phone_1: "+52" + phone1,
            ext_phone_1: null,
            phone_2: null, 
            ext_phone_2: null,
            position: position1,
            rfc: null,
            CFDI_use: {
                code: null,
                description: null,
            },
            billing_address: {
                country: "Mexico", //segun el pais asi seran los campos abajo, por ahora solo manejamos Mexico
                entidad_federativa: null,
                municipio_o_delegacion: null,
                localidad: null,
                colonia: null,
                nombre_calle: null,
                no_exterior: null,
                no_interior: null,
                entre_calle: null,
                y_calle: null, 
                referencias_adicionales: null,
                codigo_postal: null,
            },
            shipping_address: [{
                country: "Mexico", //segun el pais asi seran los campos abajo, por ahora solo manejamos Mexico
                entidad_federativa: null,
                municipio_o_delegacion: null,
                localidad: null,
                colonia: null,
                nombre_calle: null,
                no_exterior: null,
                no_interior: null,
                entre_calle: null,
                y_calle: null, 
                referencias_adicionales: null,
                codigo_postal: null,
                geo_location: {
                    latitude: null,
                    longitude: null,
                },
                how_to_arrrive: null, //instrucciones de llegada para un conductor, cuando el gps no funciona en esa zona
                delivery_schedule: [
                    {day: "Lunes", from: null, to: null, status: null},
                    {day: "Martes", from: null, to: null, status: null},
                    {day: "Miércoles", from: null, to: null, status: null},
                    {day: "Jueves", from: null, to: null, status: null},
                    {day: "Viernes", from: null, to: null, status: null},
                    {day: "Sabado", from: null, to: null, status: null},
                    {day: "Domingo", from: null, to: null, status: null}
                ], 
                delivery_instructions: null
            }],
            companies: [idCompany]
    })
        console.log("se actualizo"+number)
        number++
    })
    console.log("se actualizo uno")
}

export const addAliasToCompanies = async () => {
    const querySnapshot = await getDocs(collection(db,"company"))
    querySnapshot.forEach((item)=>{
        //let val = doc.data()
        let val = item.id
        let info = doc(db, "company", val)
        //console.log(val)
        updateDoc(info,{
            alias: null,
            updated_at: serverTimestamp(),
            ...item.data()
        })
    })
}

export const addRazonSocial = async () =>{
    const querySnapshot = await getDocs(collection(db,"profile-user"))
    querySnapshot.forEach((item)=>{
        let val = item.id
        let info = doc(db, "profile-user", val)
        updateDoc(info,{
            razon_social: null,
            updated_at: serverTimestamp(),
            ...item.data()
        })
        console.log("se hizo")
    })   
}

export const addCompaniesInZoho = async () =>{
    const arrWithAllCompanies = await getDocs(collection(db, "company"))
    arrWithAllCompanies.forEach(async(item)=>{
        let val = item.data()
        let usersDummy
        //console.log(val.persons)
        if(val.persons.length>0){
            const docRef = doc(db,"profile-user", val.persons[0])
            const docSnap = await getDoc(docRef)
            const consDocData = docSnap.data()
            usersDummy=[{
                email: consDocData.email,
                id: consDocData.number_person,
                name: consDocData.first_name,
                last_name: consDocData.last_name,
                phone_1: consDocData.phone_1,
                ext_phone_1: consDocData.ext_phone_1,
                phone_2: consDocData.phone_2,
                ext_phone_2: consDocData.ext_phone_2,
                position: consDocData.position
            }]
        }else{
            usersDummy=[{
                email: null,
                id: null,
                name: null,
                last_name: null,
                phone_1: null,
                ext_phone_1: null,
                phone_2: null,
                ext_phone_2: null,
                position: null
            }]
        }

        let date = val.created_at.toDate()
        date = date.toISOString()
        date = date.slice(0,10).replace(/-/g,'')+'T' + date.slice(11,19) + '-' + date.slice(20,23) + 'Z'
        date = date.replace(/(\d{2}):(\d{2})/,"$1")
        let valBody = {
            "id": val.number_company,
            "business_sector": val.business_sector,
            "category": null,
            "created_at":date,
            "name": val.name,
            "rfc": val.rfc,
            "CFDI_use": {
                "code":val.CFDI_use.code,
                "description":val.CFDI_use.description
            },
            "billing_address": {
                "country":val.billing_address.country,
                "entidad_federativa":val.billing_address.entidad_federativa,
                "municipio_o_delegacion":val.billing_address.municipio_o_delegacion,
                "localidad":val.billing_address.localidad,
                "colonia":val.billing_address.colonia,
                "nombre_calle":val.billing_address.nombre_calle,
                "no_exterior":val.billing_address.no_exterior,
                "no_interior":val.billing_address.no_interior,
                "entre_calle":val.billing_address.entre_calle,
                "y_calle":val.billing_address.y_calle,
                "referencias_adicionales":val.billing_address.referencias_adicionales,
                "codigo_postal":val.billing_address.codigo_postal
            },
            "shipping_address": {
                "country":val.shipping_address[0].country,
                "entidad_federativa":val.shipping_address[0].entidad_federativa,
                "municipio_o_delegacion":val.shipping_address[0].municipio_o_delegacion,
                "localidad":val.shipping_address[0].localidad,
                "colonia":val.shipping_address[0].colonia,
                "nombre_calle":val.shipping_address[0].nombre_calle,
                "no_exterior":val.shipping_address[0].no_exterior,
                "no_interior":val.shipping_address[0].no_interior,
                "entre_calle":val.shipping_address[0].entre_calle,
                "y_calle":val.shipping_address[0].y_calle,
                "referencias_adicionales":val.shipping_address[0].referencias_adicionales,
                "codigo_postal":val.shipping_address[0].codigo_postal,
                "geo_location": {
                    "latitude":val.shipping_address[0].geo_location.latitude,
                    "longitude":val.shipping_address[0].geo_location.longitude
                },
                "how_to_arrive":val.shipping_address[0].how_to_arrive,
                "delivery_schedule": [
                    {
                        "day":val.shipping_address[0].delivery_schedule[0].day,
                        "from":val.shipping_address[0].delivery_schedule[0].from,
                        "to":val.shipping_address[0].delivery_schedule[0].to,
                        "status":val.shipping_address[0].delivery_schedule[0].status
                    },
                    {
                        "day":val.shipping_address[0].delivery_schedule[1].day,
                        "from":val.shipping_address[0].delivery_schedule[1].from,
                        "to":val.shipping_address[0].delivery_schedule[1].to,
                        "status":val.shipping_address[0].delivery_schedule[1].status
                    },
                    {
                        "day":val.shipping_address[0].delivery_schedule[2].day,
                        "from":val.shipping_address[0].delivery_schedule[2].from,
                        "to":val.shipping_address[0].delivery_schedule[2].to,
                        "status":val.shipping_address[0].delivery_schedule[2].status
                    },
                    {
                        "day":val.shipping_address[0].delivery_schedule[3].day,
                        "from":val.shipping_address[0].delivery_schedule[3].from,
                        "to":val.shipping_address[0].delivery_schedule[3].to,
                        "status":val.shipping_address[0].delivery_schedule[3].status
                    },
                    {
                        "day":val.shipping_address[0].delivery_schedule[4].day,
                        "from":val.shipping_address[0].delivery_schedule[4].from,
                        "to":val.shipping_address[0].delivery_schedule[4].to,
                        "status":val.shipping_address[0].delivery_schedule[4].status
                    },
                    {
                        "day":val.shipping_address[0].delivery_schedule[5].day,
                        "from":val.shipping_address[0].delivery_schedule[5].from,
                        "to":val.shipping_address[0].delivery_schedule[5].to,
                        "status":val.shipping_address[0].delivery_schedule[5].status
                    },
                    {
                        "day":val.shipping_address[0].delivery_schedule[6].day,
                        "from":val.shipping_address[0].delivery_schedule[6].from,
                        "to":val.shipping_address[0].delivery_schedule[6].to,
                        "status":val.shipping_address[0].delivery_schedule[6].status
                    }
                ],
                "delivery_instructions":val.shipping_address[0].delivery_instructions},
                "type":val.type,
                "web_site":val.web_site,
                "notes":val.notes,
                "users":usersDummy
            }
        
        var options = {
            method: 'POST',
            body: valBody,
            json: true
        }

        axios({
            method: 'POST',
            url: 'https://cors-anywhere.herokuapp.com/https://sandbox.zohoapis.com/crm/v2/functions/endpoint_clientes/actions/execute?auth_type=apikey&zapikey=1003.2a1af1aa78423cb02ed7a17410c23a72.95b8b5bc0a8d6961ea713fcc05d7e5a5',
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
            body: valBody
        })
        .then(res=>{
            console.log(res.data())
        })
        .catch(error => {
            console.log(error)
        })        
    })
}

export const addUsersInZoho = async () =>{
    const arrWithAllUsers = await getDocs(collection(db, "profile-user"))
    arrWithAllUsers.forEach((item)=>{
        let infoData = item.data
    })
}