import React from "react";
import { addIdDoc, 
    modifyCompany, 
    modifyUser, 
    addAliasToCompanies, 
    addRazonSocial, 
    addCompaniesInZoho,
    addUsersInZoho
} from "../Data/Modifications";

const Modification = () =>{

    return(
        <div className="d-flex width33 justify-content-center mt-5 flex-wrap">
            <button className="width100 mb-3" disabled
                onClick={()=>{
                    addIdDoc()
                }}
            >
                Agregar campo id en cada doc
            </button>
            <button className="width100 mb-3" disabled
                onClick={()=>{
                    modifyCompany()
                }}
            >
                Agregar numero de compañia
            </button>
            <button className="width100 mb-3" disabled
                onClick={()=>{
                    modifyUser()
                }}
            >
                Modificacion de data de la lista de usuarios
            </button>
            <button className="width100 mb-3" disabled
                onClick={()=>{
                    addAliasToCompanies()
                }}
            >
                Agregar alias a todas las empresa null
            </button>
            <button className="width100 mb-3" disabled
                onClick={()=>{
                    addRazonSocial()
                }}
            >
                Agregar razon social a los usuarios
            </button>

            <button className="width100 mb-3" disabled  //Implementado en develop
                onClick={()=>{
                    addCompaniesInZoho()
                }}
            >
                Agregar todas las compañias de voltz a zoho
            </button>
            <button className="width100 mb-3" disabled  //Implementado en develop
                onClick={()=>{
                    addUsersInZoho()
                }}
            >
                Agregar todos los usuarios de voltz a zoho
            </button>
        </div>
    )
}

export default Modification