import React, { useContext, useState, useEffect } from "react";
import { informationOfSearch } from "../context/informationOfSearch";
import axios from "axios";
import { addNewProduct } from "../Data/QuoteServices"
import { serverTimestamp } from "firebase/firestore";
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";
import { createDocManualInCart } from"../Data/newQuoteServices"

const AddManualSku = (props) => {
    //Context general pero ya va quedar obsoleto
    const { arrResults, arrNotResults, addNewItemManual, editSkuManual } = useContext(informationOfSearch)
    //trae var y funciones basicas del cotizador
    const { nameItemAddProduct, itemAddProduct, idQuote } = useContext(functionsToImplementNewStructure)
    //se define el objeto VACIO que se crea y se manda a la BD 
    let dataItem = {}
    //este Hook guardara los datos que se recopilan del formulario
    const [ newItem, setNewItem ] = useState({
        sku:null,
        sku_description:null,
        brand:null,
        supplier:null,
        price_best:null
    })
    const [ urlToScraping, setUrlToScraping ] = useState()
    const [ isSearch, setIsSearch ] = useState(false)
    const [ fromScraping, setFromScraping ] = useState(false)
    const [ assignRequestedAmount, setAssingnRequestedAmount ] = useState()
    const [ canAddItem, setCanAddItem ] = useState(false)
    const [ assingnUnit, setAssingnUnit ] = useState("Unidad de medida")
    const [ assingnWarranty, setAssingnWarranty ] = useState("Tiempo de garantia")
    const [ firstCount, setFirstCount ] = useState(0)
    const [ secondCount, setSecondCount ] = useState(0)
    const [ itemToSend, setItemToSend ] = useState({})
    const [ statusQuery, setAtatusQuery ] = useState()

    useEffect(()=>{
        determineCanPublish()
    },[assingnUnit])

    //esta funcion ayuda a controlar qesten llenos los campos necesarios para crear producto
    const determineCanPublish = (dummy2) =>{
        //console.log(newItem)
        let count = 0
        typeof(newItem.sku) === "string" ? count++ : count-- 
        typeof(newItem.brand) === "string" ? count++ : count--
        typeof(newItem.sku_description) === "string" ? count++ : count--
        typeof(newItem.supplier) === "string" ? count++ : count--
        typeof(newItem.price_best) === "number" ? count++ : count--
        assingnUnit !== "Unidad de medida" ? count++ : count--
        //hasUnit===true ? count++ : count--
        typeof(assignRequestedAmount)==="number"||dummy2===true ? count++ : count--
        count===7 ? setCanAddItem(true) : setCanAddItem(false)
        //console.log(typeof(assignRequestedAmount))
        setFirstCount(count-1)
    }

    //esta funcion ayuda a contar los campos que estan llenos y cuales no de la segunda parte
    const countSecondPart = () => {
        let count = 0
        typeof(newItem.image_cover)==="string" ? count++ : count--
        typeof(newItem.tech_file)==="string" ? count++ : count--
        typeof(newItem.price_public)==="number" ? count++ : count--
        typeof(newItem.stock)==="number" ? count++ : count--
        typeof(assingnWarranty)==="string" ? count++ : count--
        typeof(newItem.details)==="string" ? count++ : count--
        typeof(newItem.features)==="string" ? count++ : count--
        setSecondCount(count)
    }

    //Esta funcion ayuda a traer los datos ingresados y guardarlos en los campos correspondientes
    const handleChangeInfoNewItem = ({target:{name, value}}) => {
        //console.log(newItem)
        if(name==="price"){ 
            setNewItem({...newItem, ["price_best"]:parseFloat(value)})
        }else {
            if(name==="sale_value"){
                setNewItem({...newItem, [name]: parseInt(value)})
            }else{
                setNewItem({...newItem, [name]: value})
            }            
        }
        countSecondPart()                             
    }

    const saveItem = async () => {
        //console.log(newItem.tech_file)
        newItem.brand_favicon = null
        newItem.image_cover = newItem.image_cover ? newItem.image_cover : null
        newItem.image_urls = null
        newItem.maker_web = newItem.maker_web ? newItem.maker_web : null
        newItem.price_public = newItem.price_public ? newItem.price_public : null
        newItem.price_currency = "MXN"
        newItem.price_margin = .15
        newItem.product_id = "C0_"+newItem.sku
        newItem.sale_value = 1
        newItem.supplier_code = null
        newItem.supplier_name = null
        newItem.tech_file = newItem.tech_file ? newItem.tech_file : null
        newItem.warranty = newItem.warranty ? newItem.warranty : null
        if(typeof(newItem.source) === "undefined") newItem.source = "manual"
        newItem.quantity = assignRequestedAmount ? assignRequestedAmount : 1
        newItem.requested_product = itemAddProduct.requested_product
        newItem.position = itemAddProduct.position
        try {
            await createDocManualInCart(idQuote,itemAddProduct,newItem)
            props.hide()
        } catch (error) {
            console.log(error)
        }
    }

    //Esta funcion contiene la funcionalidad para el screping de las paginas
    const getScraping = async () =>{
        setFromScraping(true)
        //console.log(urlToScraping)
        //let encodeQuery = encodeURIComponent(urlToScraping)
        let urlConcat = "https://us-central1-voltz-develop.cloudfunctions.net/scrape?url="+ encodeURIComponent(urlToScraping)
        //console.log(urlConcat)
        setIsSearch(true)
        axios.get(urlConcat,{
            Authorization: "bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjE4MzkyM2M4Y2ZlYzEwZjkyY2IwMTNkMDZlMWU3Y2RkNzg3NGFlYTUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNjE4MTA0NzA4MDU0LTlyOXMxYzRhbGczNmVybGl1Y2hvOXQ1Mm4zMm42ZGdxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA3MzQ3OTI5MDU0NDU0NTE0MTEwIiwiaGQiOiJ2b2x0ei5teCIsImVtYWlsIjoiaG9sYUB2b2x0ei5teCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoidnBHWjZtZmU5M09YSmNZdVZlZE5QZyIsImlhdCI6MTY2OTkxMzc3MiwiZXhwIjoxNjY5OTE3MzcyLCJqdGkiOiJjNzkzNTlhNTVkZWRlMThiZDM2Y2MzMTE5ZDVjM2UyMTNmNzFjZDYyIn0.jj1Etr1GH7629K0aFDGh1ioOAUQr80Mk-QCI9S5UvZAS25rppBsMF7LPJMmnax-D9UNEzAswWMfutiyC6mNl8HowB--7Oc48rtFNzUvBQRV_vu3ayo9s45wKmuancSQPrLdFYcQyEfIZPIf15mK984EfbjTcLAf8mQMtbBMymTZx_199brs3QF6jTlR3aUqYN7HjGfeJhpjvhtTeTH9HjCg2RcFohGrg0DzOCz7GkTkBCjZo1ShEx4St7-I_7mEFBQeInsNBhzv-fk6N-eEAoAxu1ZIPSC28bfIe-q5W0GbZD0LMeknIAPr_7EsnJSJC0N2kikLQSDucfbukN150WA",
            "Content-Type": "application/json",
            timeout: 0
        })
        .then(response=>{
            //console.log(response)
            //setIsSearch(false)
            createObject(response.data.data)
        })
        .catch(error=>{
            //console.log(error)
            setIsSearch(false)
        })
    }

    //Esta funcion corre despues de que el scraping haya terminado y con la informacion recopilada la asigna a los campos correspondiente
    const createObject = (data) => {
        let brand_to_catalog_code_scraping = data.brand.trim().toLowerCase().replace(/ /g, "-")
        
        let sku_scraping = data.sku.trim().replace(/ /g, "-").replace(/\//g, "-")
        
        let catalog_code_scraping = brand_to_catalog_code_scraping +"_" + sku_scraping
        let price_public_scraping
        let price_best_scraping
        
        if(data.supplier_code === "elektron_web" || data.supplier_code === "homedepot_web"){
            price_best_scraping= parseFloat((data.price_best/1.16).toFixed(2))
            price_best_scraping = parseFloat((data.price_public/1.16).toFixed(2))
        } else {
            price_best_scraping = data.price_best
            price_public_scraping = data.price_best
        }

        if(data.supplier_code === "homedepot_web") price_public_scraping = null
        if(data.supplier_code === "acomee_web") price_public_scraping = null
        if(data.supplier_code === "euroelectrica_web") price_public_scraping = null

        let stock_scraping = "stock" in data ? data["stock"] : 1

        //itemToSend.id= brand_to_catalog_code_scraping + sku_scraping
        itemToSend.brand= data.brand
        itemToSend.catalog_code= brand_to_catalog_code_scraping
        itemToSend.features = "features" in data ? data['features'] : null
        itemToSend.image_cover= data.image_cover
        itemToSend.image_urls= null
        itemToSend.maker_web = urlToScraping
        itemToSend.price_currency= "MNX"
        itemToSend.price_public= price_public_scraping
        itemToSend.principal_category = null
        itemToSend.sale_unit= null
        itemToSend.sale_value= 1
        itemToSend.sku= sku_scraping
        itemToSend.sku_description= data.sku_description
        itemToSend.source= "scraping"
        itemToSend.status= "Disponible"
        itemToSend.suppliers= {
                [data.supplier_code]: {
                    currency: "MNX",
                    price_best: price_best_scraping,
                    stock: stock_scraping,
                    supplier_code: data.supplier_code,
                    supplier_name: data.supplier_name,
                    created_at: serverTimestamp(),
                    updated_at: null
                }
            }
        itemToSend.tech_file= data.url_datasheet
        itemToSend.brand_favicon=null
        itemToSend.warranty=null

        //console.log(newItemAdd)

        dataItem.brand = data.brand
        dataItem.brand_favicon = null
        dataItem.image_cover = data.image_cover
        dataItem.image_urls = null
        dataItem.maker_web = null
        dataItem.price_best = price_best_scraping
        dataItem.price_currency = "MXN"
        dataItem.price_margin = .15
        dataItem.price_public = price_public_scraping
        dataItem.product_id = "C0_" + sku_scraping
        dataItem.sale_unit = data.sale_unit
        dataItem.sale_value=1
        dataItem.sku = sku_scraping
        dataItem.sku_description = data.sku_description
        dataItem.supplier_code = null
        dataItem.supplier_name = null
        dataItem.warranty = null

        setNewItem(dataItem)
        setIsSearch(false)
        //console.log(newItemAdd)
        //itemToSend.id=brand_to_catalog_code_scraping + sku_scraping
        //console.log(itemToSend)
        determineCanPublish()
    }

    return (
        <>
            <div className="modal pt-5" id="modalStyleAddItem">
                <div className="modal-dialogAddItem">
                    <div className="modal-contentAddItem">
                        <div className="modal-header d-block headerAddItem d-flex">
                            <div id="containerFirstButtons" className="d-flex justify-content-between width48">
                                <div>
                                    <p id="tittleAddItem">Agregar SKU manualmente</p>
                                    <p id="subTittleAddItem">Esta información la verá el cliente</p>
                                </div>
                            </div>                 

                            <div className="containerButtonsHeaderAddItem width48 d-flex justify-content-end">
                                <button className="m-3" id="buttonInfoAddItem" disabled >
                                    <span className="tooltiptext">{}</span>
                                    ?
                                </button>
            
                                <button className="m-3" onClick = { ()=>{ props.hide() }} id="buttonCloseAddItem">
                                    Cerrar
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            {
                                fromScraping ?
                                    isSearch ?
                                        <>
                                            <div className="d-flex flex-wrap" id="containerYellowAddItem">
                                                <p id="tittleScraping" className="width100">Extraer datos de una URL (scraping)</p>
                                                <div className="width100 d-flex">
                                                    <input id="inputScraping" className="width85" type="text" defaultValue={urlToScraping}/> 
                                                    <div class="sk-cube-grid ms-4">
                                                        <div class="sk-cube sk-cube1"></div>
                                                        <div class="sk-cube sk-cube2"></div>
                                                        <div class="sk-cube sk-cube3"></div>
                                                        <div class="sk-cube sk-cube4"></div>
                                                        <div class="sk-cube sk-cube5"></div>
                                                        <div class="sk-cube sk-cube6"></div>
                                                        <div class="sk-cube sk-cube7"></div>
                                                        <div class="sk-cube sk-cube8"></div>
                                                        <div class="sk-cube sk-cube9"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div id="containerGreenAddItem">
                                                <p id="tituloResultScraping">Extracción finalizada: PARCIAL</p>
                                                <p id="subtituloResultScraping">Algunos de los campos vienen vacios, favor completar manualmente</p>
                                            </div>
                                        </>
                                :
                                    <>
                                        <div className="d-flex flex-wrap" id="containerYellowAddItem">
                                            <p id="tittleScraping" className="width100">Extraer datos de una URL (scraping)</p>
                                            <div className="width100 d-flex">
                                                <input id="inputScraping" className="width85" type="text" placeholder="Pegar URL aquí"
                                                    onChange={(e)=>{
                                                        
                                                        setUrlToScraping(e.target.value)
                                                    }}
                                                />
                                                <button id="buttonSearchScraping" className="ms-4"
                                                    onClick={()=>{
                                                        getScraping()
                                                    }}
                                                >
                                                    Extraer
                                                </button>
                                    
                                            </div>
                                        </div>
                                    </>
                            }
                            
                                   
                                            
                            {
                                fromScraping?
                                    isSearch?
                                        <> 
                                            <div className="containerFormAddItem d-flex flex-wrap">
                                                <p className="tittleFormAddItem width100"> DATOS OBLIGATORIOS ({firstCount}/6)</p>
                                                <form onSubmit={saveItem} className="d-flex flex-wrap">
                                                    <div className="width50">
                                                        <label className="width100 d-flex"><p className="width10 text-left pt-3"> Sku: </p><input type="text" onChange={handleChangeInfoNewItem} name="sku" className="width90 styleInputAddItem" placeholder="Cargando...." disabled/></label>
                                                    </div>
                                                    <div className="width50">
                                                        <label className="width100 d-flex"><p className="width10 text-left pt-3"> Marca: </p><input type="text" onChange={handleChangeInfoNewItem} name="brand" className="width90 styleInputAddItem " placeholder="Cargando...." disabled/></label>
                                                    </div>
                                                    <div className="width100">
                                                        <label className="width100 d-flex"><p className="width5 text-left pt-3"> Título: </p><input type="text" onChange={handleChangeInfoNewItem} name="sku_description" className="width95 styleInputAddItem" placeholder="Cargando...." disabled/></label>
                                                    </div>
                                                    <div className="width100">
                                                        <label className="width100 d-flex"><p className="width10 text-left pt-3">Vendedor:</p><input type="text" onChange={handleChangeInfoNewItem} name="supplier" className="width90 styleInputAddItem" placeholder="Cargando...." disabled/></label>
                                                    </div>                                                
                                                    <div className="width50">
                                                        <label className="width100 d-flex"><p className="width10 text-left pt-3">Costo voltz: </p><input type="number" onChange={handleChangeInfoNewItem} name="price" className="width90 styleInputAddItem" placeholder="Cargando...." disabled/></label>
                                                    </div>
                                                    <div className="width50 dropdown">
                                                        <label className="width100 d-flex">
                                                            <p className="width15 text-left pt-3">Unidad de medida: </p>
                                                            <div className="dropdown width85 styleInputAddItem">
                                                                <button className="btn dropdown-toggle width100" type="button" data-bs-toggle="dropdown" 
                                                                    aria-expanded="false" 
                                                                >
                                                                    {assingnUnit}
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnUnit("Pieza")}}>PZA(Pieza)</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnUnit("Metro")}}>MTO(Metro)</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnUnit("Kilogramo")}}>KG(Kilogramo)</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnUnit("Kit")}}>KIT(Kit)</button></li>
                                                                </ul>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="containerFormAddItem mt-4">
                                                <p className="tittleFormAddItem width100" >DATOS OPCIONALES ({secondCount}/6)</p>
                                                <form className="d-flex flex-wrap" onSubmit={saveItem}>
                                                    <div className="width50">
                                                        <div className="width100">
                                                            <label className="width100 d-flex"><p className="width15 text-left pt-3"> Imagen: </p> <input type="text" onChange={handleChangeInfoNewItem} name="image_cover" className="width85 styleInputAddItem" placeholder="Cargando...." disabled/></label>
                                                        </div>
                                                        <div className="width100">
                                                            <label className="width100 d-flex"><p className="width15 text-left pt-3"> Ficha técnica: </p> <input type="text" onChange={handleChangeInfoNewItem} name="tech_file" className="width85 styleInputAddItem" placeholder="Cargando...." disabled/> </label>
                                                        </div>
                                                        <div className="width100">
                                                            <label className="width100 d-flex"><p className="width15 text-left pt-3"> Precio público: </p> <input type="number" onChange={handleChangeInfoNewItem} name="price_public" className="width85 styleInputAddItem" placeholder="Cargando...." disabled/> </label>
                                                        </div>                                                    
                                                        <div className="width100">
                                                            <label className="width100 d-flex"><p className="width15 text-left pt-3"> Stock: </p> <input type="number" onChange={handleChangeInfoNewItem} name="stock" className="width85 styleInputAddItem" placeholder="Cargando...." disabled/></label>
                                                        </div>
                                                    </div>

                                                    <div className="width50">
                                                        <label className="width100 d-flex">
                                                            <p className="width15 text-left pt-3">Unidad de medida: </p>
                                                            <div className="dropdown width85 styleInputAddItem">
                                                                <button className="btn dropdown-toggle width100" type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled>
                                                                    {assingnWarranty}
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnWarranty("No aplica")}}>No aplica</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnWarranty("30 Días")}}>30 Días</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnWarranty("3 Meses")}}>3 Meses</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnWarranty("6 Meses")}}>6 Meses</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnWarranty("1 Año")}}>1 Año</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnWarranty("2 Años")}}>2 Años</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnWarranty("3 Años")}}>3 Años</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnWarranty("4 Años")}}>4 Años</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{setAssingnWarranty("5 Años")}}>5 Años</button></li>
                                                                </ul>
                                                            </div>
                                                        </label>                                                    
                                                        <input type="text" onChange={handleChangeInfoNewItem} name="features" className="width98 styleInputAddItem height100" placeholder="Cargando...." disabled/>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div className="containerFormAddItem d-flex flex-wrap">
                                                <p className="tittleFormAddItem width100"> DATOS OBLIGATORIOS ({firstCount}/6)</p>
                                                <form onSubmit={saveItem} className="d-flex flex-wrap">
                                                    <div className="width50">
                                                        <label className="width100 d-flex"><p className="width10 text-left pt-3"> Sku: </p><input type="text" onChange={handleChangeInfoNewItem} name="sku" className="width90 styleInputAddItem" defaultValue={newItem.sku}/></label>
                                                    </div>
                                                    <div className="width50">
                                                        <label className="width100 d-flex"><p className="width10 text-left pt-3"> Marca: </p><input type="text" onChange={handleChangeInfoNewItem} name="brand" className="width90 styleInputAddItem " defaultValue={newItem.brand}/></label>
                                                    </div>
                                                    <div className="width100">
                                                        <label className="width100 d-flex"><p className="width5 text-left pt-3"> Título: </p><input type="text" onChange={handleChangeInfoNewItem} name="sku_description" className="width95 styleInputAddItem" defaultValue={newItem.sku_description}/></label>
                                                    </div>
                                                    <div className="width100">
                                                        <label className="width100 d-flex"><p className="width10 text-left pt-3">Vendedor:</p><input type="text" onChange={handleChangeInfoNewItem} name="supplier" className="width90 styleInputAddItem" defaultValue={newItem.supplier}/></label>
                                                    </div>                                                
                                                    <div className="width50">
                                                        <label className="width100 d-flex"><p className="width10 text-left pt-3">Costo voltz: </p><input type="number" onChange={handleChangeInfoNewItem} name="price" className="width90 styleInputAddItem" defaultValue={newItem.price_best}/></label>
                                                    </div>
                                                    <div className="width50 dropdown">
                                                        <label className="width100 d-flex">
                                                            <p className="width15 text-left pt-3">Unidad de medida: </p>
                                                            <div className="dropdown width85 styleInputAddItem">
                                                                <button className="btn dropdown-toggle width100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    {assingnUnit}
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        newItem.sale_unit = "Pieza"
                                                                        dataItem.sale_unit = "Pieza"
                                                                        itemToSend.sale_unit = "Pieza"
                                                                        setAssingnUnit("Pieza")
                                                                        determineCanPublish()
                                                                    }}>PZA(Pieza)</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        itemToSend.sale_unit = "Metro" 
                                                                        newItem.sale_unit = "Metro"
                                                                        dataItem.sale_unit = "Metro"
                                                                        setAssingnUnit("Metro")
                                                                        determineCanPublish()
                                                                    }}>MTO(Metro)</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        itemToSend.sale_unit = "Kilogramo" 
                                                                        newItem.sale_unit="Kilogramo"
                                                                        dataItem.sale_unit="Kilogramo"
                                                                        setAssingnUnit("Kilogramo")
                                                                        determineCanPublish()
                                                                    }}>KG(Kilogramo)</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        itemToSend.sale_unit = "Kit"
                                                                        newItem.sale_unit="Kit"
                                                                        dataItem.sale_unit="Kit"
                                                                        setAssingnUnit("Kit")
                                                                        determineCanPublish()
                                                                    }}>KIT(Kit)</button></li>
                                                                </ul>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="containerFormAddItem mt-4">
                                                <p className="tittleFormAddItem width100" >DATOS OPCIONALES ({secondCount}/6)</p>
                                                <form className="d-flex flex-wrap" onSubmit={saveItem}>
                                                    <div className="width50">
                                                        <div className="width100">
                                                            <label className="width100 d-flex"><p className="width15 text-left pt-3"> Imagen: </p> <input type="text" onChange={handleChangeInfoNewItem} name="image_cover" className="width85 styleInputAddItem" defaultValue={newItem.image_cover}/></label>
                                                        </div>
                                                        <div className="width100">
                                                            <label className="width100 d-flex"><p className="width15 text-left pt-3"> Ficha técnica: </p> <input type="text" onChange={handleChangeInfoNewItem} name="tech_file" className="width85 styleInputAddItem" defaultValue={newItem.tech_file}/> </label>
                                                        </div>
                                                        <div className="width100">
                                                            <label className="width100 d-flex"><p className="width15 text-left pt-3"> Precio público: </p> <input type="number" onChange={handleChangeInfoNewItem} name="price_public" className="width85 styleInputAddItem" defaultValue={newItem.price_public}/> </label>
                                                        </div>                                                    
                                                        <div className="width100">
                                                            <label className="width100 d-flex"><p className="width15 text-left pt-3"> Stock: </p> <input type="number" onChange={handleChangeInfoNewItem} name="stock" className="width85 styleInputAddItem" defaultValue={newItem.stock}/></label>
                                                        </div>
                                                    </div>

                                                    <div className="width50">
                                                        <label className="width100 d-flex">
                                                            <p className="width15 text-left pt-3">Unidad de medida: </p>
                                                            <div className="dropdown width85 styleInputAddItem">
                                                                <button className="btn dropdown-toggle width100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    {assingnWarranty}
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("No aplica")
                                                                        dataItem.warranty = "No aplica"
                                                                        newItem.warranty = "No aplica" 
                                                                        countSecondPart()
                                                                    }}>No aplica</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("30 Días")
                                                                        dataItem.warranty = "30 Días"
                                                                        newItem.warranty = "30 Días"
                                                                        countSecondPart()
                                                                    }}>30 Días</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("3 Meses")
                                                                        dataItem.warranty = "3 Meses"
                                                                        newItem.warranty = "3 Meses"
                                                                        countSecondPart()
                                                                    }}>3 Meses</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("6 Meses")
                                                                        dataItem.warranty = "6 Meses"
                                                                        newItem.warranty = "6 Meses"
                                                                        countSecondPart()
                                                                    }}>6 Meses</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("1 Año")
                                                                        dataItem.warranty = "1 Año"
                                                                        newItem.warranty = "1 Año"
                                                                        countSecondPart()
                                                                    }}>1 Año</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("2 Años")
                                                                        dataItem.warranty = "2 Años"
                                                                        newItem.warranty = "2 Años"
                                                                        countSecondPart()
                                                                    }}>2 Años</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("3 Años")
                                                                        dataItem.warranty = "3 Años"
                                                                        newItem.warranty = "3 Años"
                                                                        countSecondPart()
                                                                    }}>3 Años</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("4 Años")
                                                                        dataItem.warranty = "4 Años"
                                                                        newItem.warranty = "4 Años"
                                                                        countSecondPart()
                                                                    }}>4 Años</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("5 Años")
                                                                        dataItem.warranty = "5 Años"
                                                                        newItem.warranty = "5 Años"
                                                                        countSecondPart()
                                                                    }}>5 Años</button></li>
                                                                </ul>
                                                            </div>
                                                        </label>
                                                        {
                                                            typeof(newItem.details) === "string" ?
                                                                <input type="text" onChange={handleChangeInfoNewItem} name="features" className="width98 styleInputAddItem height100" defaultValue={newItem.details}/>
                                                            :
                                                                <input type="text" onChange={handleChangeInfoNewItem} name="features" className="width98 styleInputAddItem height100" defaultValue={newItem.features}/>
                                                        }                                             
                                                        
                                                    </div>
                                                </form>
                                            </div>
                                        </>  
                                :
                                    <>
                                        <div className="containerFormAddItem d-flex flex-wrap">
                                            <p className="tittleFormAddItem width100"> DATOS OBLIGATORIOS ({firstCount}/6)</p>
                                            <form onSubmit={saveItem} className="d-flex flex-wrap">
                                                <div className="width50">
                                                    <label className="width100 d-flex"><p className="width10 text-left pt-3"> Sku: </p><input type="text" onChange={handleChangeInfoNewItem} name="sku" className="width90 styleInputAddItem" placeholder="Código Sku"/></label>
                                                </div>
                                                <div className="width50">
                                                    <label className="width100 d-flex"><p className="width10 text-left pt-3"> Marca: </p><input type="text" onChange={handleChangeInfoNewItem} name="brand" className="width90 styleInputAddItem " placeholder="Marca/Fabricante"/></label>
                                                </div>
                                                <div className="width100">
                                                    <label className="width100 d-flex"><p className="width5 text-left pt-3"> Título: </p><input type="text" onChange={handleChangeInfoNewItem} name="sku_description" className="width95 styleInputAddItem" placeholder="Descripción del producto"/></label>
                                                </div>
                                                <div className="width100">
                                                    <label className="width100 d-flex"><p className="width10 text-left pt-3">Vendedor:</p><input type="text" onChange={handleChangeInfoNewItem} name="supplier" className="width90 styleInputAddItem" placeholder="Proveedor"/></label>
                                                </div>                                                
                                                <div className="width50">
                                                    <label className="width100 d-flex"><p className="width10 text-left pt-3">Costo voltz: </p><input type="number" onChange={handleChangeInfoNewItem} name="price" className="width90 styleInputAddItem" placeholder="Costo de provedor"/></label>
                                                </div>
                                                <div className="width50 dropdown">
                                                    <label className="width100 d-flex">
                                                        <p className="width15 text-left pt-3">Unidad de medida: </p>
                                                        <div className="dropdown width85 styleInputAddItem">
                                                            <button className="btn dropdown-toggle width100" type="button" 
                                                                data-bs-toggle="dropdown" 
                                                                aria-expanded="false"
                                                                onClick={()=>{
                                                                     console.log("la llevamos")
                                                                }}
                                                            >
                                                                {assingnUnit}
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        newItem.sale_unit = "Pieza"
                                                                        dataItem.sale_unit = "Pieza"
                                                                        setAssingnUnit("Pieza")
                                                                        determineCanPublish()
                                                                    }}>PZA(Pieza)</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        newItem.sale_unit = "Metro"
                                                                        dataItem.sale_unit = "Metro"
                                                                        setAssingnUnit("Metro")
                                                                        determineCanPublish()
                                                                    }}>MTO(Metro)</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        newItem.sale_unit="Kilogramo"
                                                                        dataItem.sale_unit="Kilogramo"
                                                                        setAssingnUnit("Kilogramo")
                                                                        determineCanPublish()
                                                                    }}>KG(Kilogramo)</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        newItem.sale_unit="Kit"
                                                                        dataItem.sale_unit="Kit"
                                                                        setAssingnUnit("Kit")
                                                                        determineCanPublish()
                                                                    }}>KIT(Kit)</button></li>
                                                            </ul>
                                                        </div>
                                                    </label>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="containerFormAddItem mt-4">
                                            <p className="tittleFormAddItem width100" >DATOS OPCIONALES ({secondCount}/6)</p>
                                            <form className="d-flex flex-wrap" onSubmit={saveItem}>
                                                <div className="width50">
                                                    <div className="width100">
                                                        <label className="width100 d-flex"><p className="width15 text-left pt-3"> Imagen: </p> <input type="text" onChange={handleChangeInfoNewItem} name="image_cover" className="width85 styleInputAddItem" placeholder="Url de imagen"/></label>
                                                    </div>
                                                    <div className="width100">
                                                        <label className="width100 d-flex"><p className="width15 text-left pt-3"> Ficha técnica: </p> <input type="text" onChange={handleChangeInfoNewItem} name="tech_file" className="width85 styleInputAddItem" placeholder="Url de ficha técnica"/> </label>
                                                    </div>
                                                    <div className="width100">
                                                        <label className="width100 d-flex"><p className="width15 text-left pt-3"> Precio público: </p> <input type="number" onChange={handleChangeInfoNewItem} name="price_public" className="width85 styleInputAddItem" placeholder="Precio público"/> </label>
                                                    </div>                                                    
                                                    <div className="width100">
                                                        <label className="width100 d-flex"><p className="width15 text-left pt-3"> Stock: </p> <input type="number" onChange={handleChangeInfoNewItem} name="stock" className="width85 styleInputAddItem" placeholder="Stock"/></label>
                                                    </div>
                                                </div>

                                                <div className="width50">
                                                    <label className="width100 d-flex">
                                                        <p className="width15 text-left pt-3">Unidad de medida: </p>
                                                        <div className="dropdown width85 styleInputAddItem">
                                                            <button className="btn dropdown-toggle width100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                {assingnWarranty}
                                                            </button>
                                                                <ul className="dropdown-menu">
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("No aplica")
                                                                        dataItem.warranty = "No aplica"
                                                                        newItem.warranty = "No aplica" 
                                                                        countSecondPart()
                                                                    }}>No aplica</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("30 Días")
                                                                        dataItem.warranty = "30 Días"
                                                                        newItem.warranty = "30 Días"
                                                                        countSecondPart()
                                                                    }}>30 Días</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("3 Meses")
                                                                        dataItem.warranty = "3 Meses"
                                                                        newItem.warranty = "3 Meses"
                                                                        countSecondPart()
                                                                    }}>3 Meses</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("6 Meses")
                                                                        dataItem.warranty = "6 Meses"
                                                                        newItem.warranty = "6 Meses"
                                                                        countSecondPart()
                                                                    }}>6 Meses</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("1 Año")
                                                                        dataItem.warranty = "1 Año"
                                                                        newItem.warranty = "1 Año"
                                                                        countSecondPart()
                                                                    }}>1 Año</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("2 Años")
                                                                        dataItem.warranty = "2 Años"
                                                                        newItem.warranty = "2 Años"
                                                                        countSecondPart()
                                                                    }}>2 Años</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("3 Años")
                                                                        dataItem.warranty = "3 Años"
                                                                        newItem.warranty = "3 Años"
                                                                        countSecondPart()
                                                                    }}>3 Años</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("4 Años")
                                                                        dataItem.warranty = "4 Años"
                                                                        newItem.warranty = "4 Años"
                                                                        countSecondPart()
                                                                    }}>4 Años</button></li>
                                                                    <li><button className="dropdown-item" type="button" onClick={()=>{
                                                                        setAssingnWarranty("5 Años")
                                                                        dataItem.warranty = "5 Años"
                                                                        newItem.warranty = "5 Años"
                                                                        countSecondPart()
                                                                    }}>5 Años</button></li>
                                                                </ul>
                                                        </div>
                                                    </label>                                                    
                                                    <input type="text" onChange={handleChangeInfoNewItem} name="features" className="width98 styleInputAddItem height100" placeholder="Detalles"/>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                            }
                        </div>
                        <div className="modal-footer">
                            <p className="textNormal">Unidades solicitadas</p>
                            <input type="number" className="styleInputAddItem width10 text-center" placeholder="00000"
                                onChange={(e)=>{
                                    var dummy = true
                                    setAssingnRequestedAmount(parseInt(e.target.value))
                                    determineCanPublish(dummy)
                                }}
                            /> 
                            {
                                canAddItem ?
                                    <button className="btn" id="saveAddItem" onClick={()=>{saveItem()}}>
                                        Guardar
                                    </button>
                                :
                                    <button className="btn" id="saveAddItem" disabled>Guardar</button>
                            }                              
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddManualSku