import React, { useContext, useEffect, useState } from "react";
import { informationOfSearch } from "../context/informationOfSearch";
import PopUpTechFile from "./PopUpTechFile";
import { GetDiscountService } from "../Data/DiscountServices";
import { updateStatusNextActions, updateMargin } from "../Data/QuoteServices"
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";
import { moveResultToPending, changePriceMargin } from "../Data/newQuoteServices";

const TableResults = (props) => {

    //const discountArr = GetDiscount()
    let discountArr

    //VARIABLES
    //*Singletons
    const {
        moveResultToNotResult, 
        moveResultToDiscarted, 
        removeElementManual, 
        updateRequestedAmount,
        activateRecommendation,
        deleteItemResultsAddNotResults,
        changePiezaToMetro
    } = useContext(informationOfSearch)
    const {
        arrResults,
        idQuote,
        totalQuote
     } = useContext(functionsToImplementNewStructure)

    const [ showOthers, setShowOthers ] = useState([])
    const [ showTechFile, setShowTechFile ] = useState(false)
    const [ index1Item, setIndex1Item ] = useState()
    const [ index2Item, setIndex2Item ] = useState()
    const [ arrDiscount, setArrDiscount ] = useState()
    const [selectedRow, setSelectedRow] = useState(null);

    const discountPromise = new Promise(async(resolve, reject)=>{
        discountArr = await GetDiscountService()
        //setArrDiscount(discountArr)
        if(discountArr.length>0) resolve(discountArr)
    })

    discountPromise.then((data)=>setArrDiscount(data))

    const toggleRow = (id) => {
        console.log(id)
        const shownState = showOthers.slice()
        const index = shownState.indexOf(id)

        if(index >= 0) {
            shownState.splice(index,1)
            setShowOthers(shownState)
        } else {
            shownState.push(id)
            setShowOthers(shownState)
        }
        //setSelectedRow(id === selectedRow ? null : id);
    }

    const launchAddnew = (index) => {
        props.activePopUpAddItem(index)
    }
    
    const launchEditnew = (index, index2) => {
        props.activePopUpEditItem (index,index2)
    }

    const changeRequestedAmount = (index,e) => {
        //console.log(arrResults[index],e.target.value)
        updateRequestedAmount(index, e.target.value)
        props.setChanges(props.changes+1) 
    }

    const addCategoryItem = ( val, product_id ) => {
        changePriceMargin(idQuote, product_id, val)
    }

    return (
        <>
            <div className="containerTables">
                <p id="tittleTableResults">Cotizados ({ arrResults.length })</p>
                <div id="containerTableResult">
                    <table className="table table-hover table-borderless" >
                        <thead className="headTableResult">
                            <tr>
                                <th scope="col" className="text-center" id="columnNumber">#</th>
                                <th scope="col" id="columnItem">PRODUCTO</th>
                                <th scope="col" className="text-center" id="columnQuantity">CANTIDAD</th>
                                <th scope="col" className="text-center" id="columnSkus">SKUs</th>
                                <th scope="col" className="text-center" id="columnPoints"></th>
                            </tr>
                        </thead>
                        <tbody className="tableBody">
                            {                                                           
                                arrResults.map(( item,index ) => (
                                    <>
                                        <tr key={item.product_id+1}>
                                            <td className="text-center" scope="row" id="textNumber" onClick = { ()=> { 
                                                //console.log(item)
                                                toggleRow(item.product_id)
                                            }}> 
                                                { item.position } 
                                            </td>                                    
                                            <td id="textItem" onClick = { ()=> {
                                                //console.log(item)
                                                toggleRow(item.product_id)
                                            }}> { item.requested_product} </td>
                                            <td className="text-center indexSup" id="textLengthResults"> 
                                                {
                                                    //<input type="number" id="inputNumberRequest" onChange={(e)=>changeRequestedAmount(index,e)} defaultValue={ item.requestedAmount}/>
                                                    item.quantity
                                                }
                                            </td>
                                            <td className="text-center" id="textLengthResults" onClick = { ()=> {
                                                //console.log(item)
                                                toggleRow(item.product_id)}
                                            }>1</td>
                                            {
                                                <td className="text-center indexSup dropdown" id="points"> 
                                                    <button className="buttonPoints btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bi bi-three-dots-vertical"/>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li className="dropdown-item opstionsResult" onClick={() => {moveResultToPending(idQuote,item.draft_id,item.product_id)}}>
                                                            Mandar a pendientes
                                                        </li>
                                                        {
                                                            /*item.value[0].sale_unit !== "metro" ?
                                                                <li className="dropdown-item opstionsResult" onClick={()=>{
                                                                    changePiezaToMetro(index)
                                                                    props.setChanges(props.changes+1)
                                                                }}>
                                                                    Convertir a metros
                                                                </li>
                                                            :
                                                                ''*/
                                                        }
                                                        {
                                                             /*
                                                             <li className="dropdown-item opstionsResult" onClick={() => {props.launchActiveLapicito(index)}}>Agregar/quitar SKUS</li>
                                                             <li className="dropdown-item opstionsResult" onClick={() => launchAddnew(index) }>Agregar SKU manualmente</li>
                                                             <li className="dropdown-item opstionsResult" onClick={() => {moveResultToNotResult(index)
                                                                 props.setChanges(props.changes+1)
                                                             }}>Mover a pendientes</li>
                                                             <li className="dropdown-item opstionsResult" onClick={() => {moveResultToDiscarted(index)
                                                                 props.setChanges(props.changes+1)
                                                             }}>Mover a descartados</li>*/
                                                        }
                                                       
                                                    </ul>
                                                </td>
                                            }
                                            
                                        </tr>                                

                                        {
                                            showOthers.includes(item.product_id) &&(
                                                //console.log(item)
                                                <tr style={{backgroundColor: "white", borderWidth: "1px", borderColor: "#EDEDED"}}  key={item.product_id}>
                                                    <td>
                                                    {
                                                        item.tech_file ?
                                                        <button onClick={()=> {
                                                            setShowTechFile(true)
                                                            setIndex1Item(index)
                                                        }} id="button-file" >
                                                            <i className="bi bi-info-circle-fill icon-file-color"></i>
                                                        </button> : ''
                                                    }
                                                    </td>
                                                    <td className="selectedSku" >
                                                        <div>
                                                            <p className="descriptionSku">{ item.sku_description }</p>
                                                            <p className="brandSku">{ item.brand } </p>
                                                            <p className="priceSku">{ Intl.NumberFormat('es-MX',{style:'currency', currency:'MXN'}).format(item.price_best) + " " + "MXN" + " (" + item.sale_value + " " + item.sale_unit + ")" }</p>
                                                            <p className="supplierSku">{ item.supplier_name + " (" + item.sku + ")" }</p>                                                             
                                                        </div>
                                                    </td>
                                                    <td className="d-flex justify-content-center">
                                                        {
                                                            item.selected ? 
                                                            <button className="btn btn-star" onClick={()=>{//activateRecommendation(index, index2)
                                                                //props.setChanges(props.changes+1)
                                                            }}>
                                                                <i className="bi bi-star-fill"/>
                                                            </button> :
                                                            <button className="btn btn-star" onClick={()=>{//activateRecommendation(index, index2)
                                                                //props.setChanges(props.changes+1)
                                                            }}>
                                                                <i className="bi bi-star"/>
                                                            </button>
                                                        }
                                                    </td>
                                                    <td>
                                                        <p>Margen:</p>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            {
                                                                item.source === "manual" || item.source === "scraping" ?
                                                                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        {Math.round(item.price_margin*100)} %
                                                                    </button>
                                                                :
                                                                    <p> {item.price_margin*100} </p>
                                                            }                                                            
                                                            <ul className="dropdown-menu">
                                                                {
                                                                    arrDiscount.map((itemDiscount,indexDiscount)=>(
                                                                        <li onClick={()=>{addCategoryItem( itemDiscount.value, item.product_id)}}>
                                                                            <p>{Math.round(itemDiscount.value*100)}%</p>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    
                                                    {/*
                                                        item2.isManual ? 
                                                        
                                                            <td className="text-center indexSup dropdown" id="points">
                                                                <button className="buttonPoints btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i className="bi bi-three-dots-vertical"/>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    <li className="dropdown-item opstionsResult" onClick={() => launchEditnew(index,index2) } >Editar</li>
                                                                    <li className="dropdown-item opstionsResult" onClick={() => removeElementManual(index,index2) }>Eliminar</li>
                                                                </ul>
                                                            </td>
                                                        :
                                                        
                                                            <td>
                                                                <button className="btn" onClick={()=>props.activePopUpChangePrice(index,index2)}>
                                                                    <i class="bi bi-pencil-square"></i>
                                                                </button>
                                                            </td>
                                                        
                                                        
                                                    */}
                                                </tr>                                       
                                            )                                           
                                        }
                                        
                                            <>{/*
                                            showOthers.includes(item.id) &&(
                                                item.value.map((item2,index2)=>(
                                                    //console.log(item)
                                                    <tr style={{backgroundColor: "white", borderWidth: "1px", borderColor: "#EDEDED"}}  key={item2.id}>
                                                            <td>
                                                            {
                                                                item2.tech_file ?
                                                                <button onClick={()=> {
                                                                    setShowTechFile(true)
                                                                    setIndex1Item(index)
                                                                    setIndex2Item(index2)
                                                                }} id="button-file" >
                                                                    <i className="bi bi-info-circle-fill icon-file-color"></i>
                                                                </button> : ''
                                                            }
                                                            </td>
                                                            <td className="selectedSku" >
                                                                <div>
                                                                    <p className="descriptionSku">{ item2.sku_description }</p>
                                                                    <p className="brandSku">{ item2.brand } </p>
                                                                    <p className="priceSku">{ Intl.NumberFormat('es-MX',{style:'currency', currency:'MXN'}).format(item2.price.price_best) + " " + item2.price.currency + " (" + item2.sale_value + " " + item2.sale_unit + ")" }</p>
                                                                    <p className="supplierSku">{ item2.price.supplier_name + " (" + item2.sku + ")" }</p>                                                             
                                                                </div>
                                                            </td>
                                                            <td className="d-flex justify-content-center">
                                                                {
                                                                    item2.selected ? 
                                                                    <button className="btn btn-star" onClick={()=>{//activateRecommendation(index, index2)
                                                                        //props.setChanges(props.changes+1)
                                                                    }}>
                                                                        <i className="bi bi-star-fill"/>
                                                                    </button> :
                                                                    <button className="btn btn-star" onClick={()=>{//activateRecommendation(index, index2)
                                                                        //props.setChanges(props.changes+1)
                                                                    }}>
                                                                        <i className="bi bi-star"/>
                                                                    </button>
                                                                }
                                                            </td>
                                                            <td>
                                                                <p>Margen:</p>
                                                            </td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    {
                                                                        item2.price.price_margin ?
                                                                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                {Math.round(item2.price.price_margin*100)} %
                                                                            </button>
                                                                        :
                                                                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                -
                                                                            </button>
                                                                    }
                                                                    
                                                                    <ul className="dropdown-menu">
                                                                        {
                                                                            arrDiscount.map((itemDiscount,indexDiscount)=>(
                                                                                <li onClick={()=>{addCategoryItem(itemDiscount.level_id, itemDiscount.value, index)}}>
                                                                                    <p>{Math.round(itemDiscount.value*100)}%</p>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            
                                                            {/*
                                                                item2.isManual ? 
                                                                
                                                                    <td className="text-center indexSup dropdown" id="points">
                                                                        <button className="buttonPoints btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="bi bi-three-dots-vertical"/>
                                                                        </button>
                                                                        <ul className="dropdown-menu">
                                                                            <li className="dropdown-item opstionsResult" onClick={() => launchEditnew(index,index2) } >Editar</li>
                                                                            <li className="dropdown-item opstionsResult" onClick={() => removeElementManual(index,index2) }>Eliminar</li>
                                                                        </ul>
                                                                    </td>
                                                                :
                                                                
                                                                    <td>
                                                                        <button className="btn" onClick={()=>props.activePopUpChangePrice(index,index2)}>
                                                                            <i class="bi bi-pencil-square"></i>
                                                                        </button>
                                                                    </td>
                                                                
                                                                
                                                            }
                                                    </tr>
                                                ))                                                
                                            ) */} </>
                                        
                                    </>
                                ))                                
                            }
                        </tbody>
                        <tfoot className="headTableResult">
                            <tr>
                                <td id="spaceDummy" scope="row"></td>
                                <td></td>
                                <td id="textValue">VALOR APROX:</td>
                                <td style={{fontSize: "18px"}}>$ { totalQuote }</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            {
                showTechFile ?
                    <PopUpTechFile hide={()=>setShowTechFile(false)}
                        index1 = {index1Item}
                        index2 = {index2Item}

                    />
                :
                ''
            }
        </>
    )
}

export default TableResults