import React, { useContext, useState } from "react";
import { informationOfSearch } from "../context/informationOfSearch";
import uuid from "react-uuid";
import { addItemToPending } from "../Data/newQuoteServices";
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";

const AddManualPendiente = (props) => {

    //VARIABLE
    //*Singleton
    const {arrResults, arrNotResults, arrDiscarted, addManualInNotResults} = useContext(informationOfSearch)
    const { idQuote } = useContext(functionsToImplementNewStructure)

    //*Hooks
    const [ itemToSearch, setItemToSearch ] = useState()
    const [ itemAddToPending, setItemAddToPending ] = useState ({
        nameItemOfSearch: '',
        id: uuid(),
        position: (arrResults.length + arrNotResults.length + arrDiscarted.length)+1,
        requestedAmount: 1,
        reasonDiscarted: "No Aplicate",
        value:[]
    })

    const addProduct = async () =>{
        addItemToPending(idQuote,itemToSearch)
        props.hide()
    }

    return(
        <>
            <div className="modal pt-5" id="modalStyle">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="d-flex justify-content-between width100">
                                <div>
                                    <p id="tittleAddItem">Agregar fila de producto</p>
                                </div>
                                <div >
                                    <button type="button" className="btn-close" onClick = { ()=>{ props.hide() } }></button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <textarea maxLength = {120} type="text" placeholder="Descripción del producto" className="styleInputAddItem width100" onChange={ e => {setItemToSearch(e.target.value)}}/>
                        </div>
                        <div className="modal-footer">
                            <button className="btn" id="saveAddItem" onClick={()=>{addProduct()}}>Agregar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddManualPendiente