import React, { useState } from "react";
import Navbar from '../Components/Navbar';
import { GetOrders } from "../Hooks/OrdersHooks";
import { confirmPaymentService, exportToExcel, exportToExcelCart, getProductforExport } from "../Data/OrderesServices";
import moment from 'moment';
import PopUpConfirmOrDisconfirm from "../Components/PopUpConfirmOrDisconfirm";
import PopUpTablesOrders from "../Components/PopUpTablesOrder";
import { reverseStatus } from "../Data/QuoteServices";
import PopUpGeneralStatusOrder from '../Components/PopUpGeneralStatusOrder'
import Papa from 'papaparse'
const Orders = () => {
    
    const orders = GetOrders()

    const [ isFilter, setIsFilter ] = useState(false)
    const [ arrFilter, setArrFilter ] = useState()
    const [ nameToFilter, setNameToFilter ] = useState()
    const [ isOpenPopUp, setIsOpenPopUp ] = useState(false)
    const [ toConfirmPopUp, setToConfirmPopUp ] = useState()
    const [ methodOfPayment, setMethodOfPayment ] = useState()
    const [ idOrder, setIdOrder ] = useState()
    const [ activeListSkus, setActiveListSkus ] = useState(false)
    const [ nextStatus, setNextStatus ] = useState()
    const [ isOpenPopUpGeneral, setIsOpenPopUpGeneral ] = useState(false)

    const confirmOrDisconfirmPayment = async (id, toConfirm, reason) => {
        console.log(reason)
        console.log(id)
        try {
            await confirmPaymentService (id, toConfirm, reason)
            //console.log("se logro")
        } catch (error) {
            console.log(error)
        }
    }

    const filterArray = (e) => {
        setNameToFilter(e)
        //console.log(orders)
        let arrFilter1 = orders.filter(correct=>{
            //console.log(correct.alias)
            if(correct.alias.includes(nameToFilter)) return correct
        })
        setArrFilter(arrFilter1)
        setIsFilter(true)
    }

    const downloadCSV = async (idOrder) => {
        try {
            const orderData = await exportToExcel(idOrder);
            const dataCart = await exportToExcelCart(idOrder)
            const dataToArray = [
                
                ['ID', 'Consecutive', 'Id Producto', 'SKU', 'Marca', 'Descripcion', 'Precio Compra', 'Precio Venta', 'Cantidad', 'Total Antes de Descuento', 'Total Despues de Descuento', 'Descuento Total', 'Proveedor', 'Codigo Proveedor', 'Image', 'Fecha Agregado']

            ]
            const createdDate = new Date(orderData.created_at.seconds * 1000)
            const formattedDate = `${createdDate.getDate().toString().padStart(2, '0')}-${(createdDate.getMonth() + 1).toString().padStart(2, '0')}-${createdDate.getFullYear()} ${createdDate.getHours().toString().padStart(2, '0')}:${createdDate.getMinutes().toString().padStart(2, '0')}`;
            
            for (let i = 0; i < dataCart.length; i++) {
                const item = dataCart[i]
                const productCart = await getProductforExport(item.sku)
                productCart.forEach(prod => {

                    dataToArray.push([
                        idOrder,
                        orderData.consecutive,
                        item.id,
                        item.sku,
                        prod.brand,
                        prod.sku_description,
                        item.price_best,
                        item.price_public,
                        item.quantity,
                        item.total.before_discount,
                        item.total.after_discount,
                        item.total.discount,
                        prod.suppliers?.S2?.supplier_name || prod.suppliers?.S3?.supplier_name || 'desconocido',
                        prod.catalog_code,
                        prod.image_cover,
                        formattedDate

                    ]);
                    console.log(dataToArray)
                })
                
             
            }
            
            const csv = Papa.unparse(dataToArray);
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = 'Orden_'+orderData.consecutive;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log(error);
        }
    };


    //console.log(orders)

    return(
        <>
            <Navbar/>
            <div className="d-flex p-3 justify-content-around">
                <button id="buttonReturnHome">
                    Home
                </button>
                <input className="textUpper" id="inputSearchOrder" type="text" placeholder="Buscar pedido por alias"
                    onChange={(e)=>{
                        filterArray(e.target.value.toUpperCase())
                    }}
                />
            </div>

            <div className="d-flex flex-wrap">
                {
                    isFilter ? 
                        arrFilter.map((item,index)=>(
                            <div key={item.id} className='width20 cardQuote'>
                                    <span id='labelAlias'>{item.alias}</span><br></br>
                                    <span id='labelContent'>
                                        <span>{item.customer_id}</span><br></br>
                                        {
                                            //<span>${item.total.toFixed(2)} ({item.detail.length} productos)</span>
                                        }
                                    </span>
                                    
                                                        
                                        <div style={{marginTop: "12px"}} className='d-flex'>
                                            <div id='consecutiveContainer-Review-Order'>
                                                <span id='consecutiveNumber-Review'>
                                                    {item.consecutive}
                                                </span>
                                                
                                            </div>
                                            

                                                <button id='cardDecorationArrow-Review'
                                                    className='btn'
                                                    target="_blank"
                                                    onClick={()=>{
                                                        window.open(process.env.REACT_APP_URL+item.id)
                                                    }}
                                                >
                                                    <i className="bi bi-eye-fill" ></i>
                                                </button>
                                            
                                                            
                                        </div>

                                        <div style={{marginTop: "12px"}} className="d-flex">
                                            {
                                                
                                                item.payment_status !== "paid" ?
                                                        <button id='cardDecorationArrow-Review-Red'
                                                            className='btn'
                                                            onClick={()=>{
                                                                setToConfirmPopUp(true)
                                                                setIdOrder(item.id)
                                                                setIsOpenPopUp(true)
                                                            }}
                                                        >
                                                            Confirmar pago
                                                        </button>
                                                    :
                                                        item.shipping &&  item.shipping.status === "processing" && new Date("2023-03-30")<new Date((item.created_at.seconds*1000)+(item.created_at.nanoseconds/1000))   ?
                                                            <button id='cardDecorationArrow-Review-Blue'
                                                                className='btn'
                                                                onClick={()=>{
                                                                    setNextStatus("enviado")
                                                                    setIdOrder(item.id)
                                                                    setIsOpenPopUpGeneral(true)
                                                                }}
                                                            >
                                                                Confirmar envío
                                                            </button>
                                                        :
                                                            item.shipping && item.shipping.status === "shipped" && new Date("2023-03-30")<new Date((item.created_at.seconds*1000)+(item.created_at.nanoseconds/1000))   ?
                                                                <button id='cardDecorationArrow-Review-Yellow'
                                                                    className='btn'
                                                                    onClick={()=>{
                                                                        setNextStatus("entregado")
                                                                        setIdOrder(item.id)
                                                                        setIsOpenPopUpGeneral(true)
                                                                    }}
                                                                >
                                                                    Confirmar entrega
                                                                </button>
                                                            :
                                                                item.shipping && item.shipping.status === "delivered" && new Date("2023-03-30")<new Date((item.created_at.seconds*1000)+(item.created_at.nanoseconds/1000))   ?
                                                                    <button id='cardDecorationArrow-Review-Black'
                                                                        className='btn'
                                                                    >
                                                                        Entregado
                                                                    </button>
                                                                :
                                                                    <button id='cardDecorationArrow-Review-LightBlue'
                                                                        className='btn'
                                                                        disabled
                                                                    >
                                                                        Desactualizado
                                                                    </button>
                                                
                                            }
                                            
                                            <button id='cardDecorationArrow-Review-Yellow'
                                                        className='btn'
                                                        target="_blank"
                                                        onClick={()=>{
                                                            reverseStatus(item.id)
                                                        }}
                                                    >
                                                        Revertir
                                            </button>
                                        </div>
                                        <div className="width100">
                                            <button className="buttonListOfSkus"
                                                onClick={()=>{
                                                    setIdOrder(item.id)
                                                    setActiveListSkus(true)
                                                    downloadCSV(item.id);
                                                }}
                                            >
                                                Lista de SKU
                                            </button>
                                        </div>

                                    <span style={{marginTop: "12px"}} id='labelContent'>
                                        {
                                            item.created_at!==null ? 
                                            <span>{moment(item.created_at.toDate()).local().format("DD-MM-YYYY")}</span> :
                                            <span></span>
                                        }
                                    </span>
                                        
                            </div>
                        ))
                    :
                        orders.map((item,index)=>(
                            <div key={item.id} className='width20 cardQuote'>
                                    <span id='labelAlias'>{item.alias}</span><br></br>
                                    <span id='labelContent'>
                                        <span>{item.customer_id}</span><br></br>
                                        {
                                            /*item.totals ?
                                                <span>${item.totals.total.toFixed(2)} ({item.detail.length} productos)</span>
                                            :
                                                <span>${item.total.toFixed(2)} ({item.detail.length} productos)</span>*/
                                        }
                                    </span>
                                    
                                                        
                                        <div style={{marginTop: "12px"}} className='d-flex'>
                                            <div id='consecutiveContainer-Review-Order'>
                                                <span id='consecutiveNumber-Review'>
                                                    {item.consecutive}
                                                </span>
                                                
                                            </div>
                                            

                                                <button id='cardDecorationArrow-Review'
                                                    className='btn'
                                                    target="_blank"
                                                    onClick={()=>{
                                                        window.open(process.env.REACT_APP_URL+item.id)
                                                    }}
                                                >
                                                    <i className="bi bi-eye-fill" ></i>
                                                </button>
                                            
                                                            
                                        </div>

                                        <div style={{marginTop: "12px"}} className="d-flex">
                                            {
                                                item.payment_status !== "paid" ?
                                                    <button id='cardDecorationArrow-Review-Red'
                                                        className='btn'
                                                        onClick={()=>{
                                                            setToConfirmPopUp(true)
                                                            setIdOrder(item.id)
                                                            setIsOpenPopUp(true)
                                                        }}
                                                    >
                                                        Confirmar pago
                                                    </button>
                                                :
                                                    item.shipping &&  item.shipping.status === "processing" && new Date("2023-03-30")<new Date((item.created_at.seconds*1000)+(item.created_at.nanoseconds/1000))   ?
                                                        <button id='cardDecorationArrow-Review-Blue'
                                                            className='btn'
                                                            onClick={()=>{
                                                                setNextStatus("enviado")
                                                                setIdOrder(item.id)
                                                                setIsOpenPopUpGeneral(true)
                                                            }}
                                                        >
                                                            Confirmar envío
                                                        </button>
                                                    :
                                                        item.shipping && item.shipping.status === "shipped" && new Date("2023-03-30")<new Date((item.created_at.seconds*1000)+(item.created_at.nanoseconds/1000))   ?
                                                            <button id='cardDecorationArrow-Review-Yellow'
                                                                className='btn'
                                                                onClick={()=>{
                                                                    setNextStatus("entregado")
                                                                    setIdOrder(item.id)
                                                                    setIsOpenPopUpGeneral(true)
                                                                }}
                                                            >
                                                                Confirmar entrega
                                                            </button>
                                                        :
                                                            item.shipping && item.shipping.status === "delivered" && new Date("2023-03-30")<new Date((item.created_at.seconds*1000)+(item.created_at.nanoseconds/1000))   ?
                                                                <button id='cardDecorationArrow-Review-Black'
                                                                    className='btn'
                                                                >
                                                                    Entregado
                                                                </button>
                                                            :
                                                                <button id='cardDecorationArrow-Review-LightBlue'
                                                                    className='btn'
                                                                    disabled
                                                                >
                                                                    Desactualizado
                                                                </button>
                                            }
                                            
                                            <button id='cardDecorationArrow-Review-Yellow'
                                                        className='btn'
                                                        target="_blank"
                                                        onClick={()=>{
                                                            reverseStatus(item.id)
                                                        }}
                                                    >
                                                        Revertir
                                            </button>
                                        </div>

                                        <div className="width100">
                                            <button className="buttonListOfSkus"
                                                onClick={()=>{
                                                    setIdOrder(item.id)
                                                    setActiveListSkus(true)
                                                    downloadCSV(item.id);
                                                }}
                                            >
                                                Lista de SKU
                                            </button>
                                        </div>

                                        <span style={{marginTop: "12px"}} id='labelContent'>
                                        {
                                            item.created_at!==null ? 
                                            <span>{moment(item.created_at.toDate()).local().format("DD-MM-YYYY")}</span> :
                                            <span></span>
                                        }
                                    </span>
                                        
                                </div>
                        ))
                }
            </div>

            {
                isOpenPopUp ?
                    <PopUpConfirmOrDisconfirm hide={()=>{setIsOpenPopUp(false)}}
                        toConfirmPopUp = { toConfirmPopUp }
                        setMethodOfPayment = { ()=> {setMethodOfPayment()}}
                        idOrder = { idOrder }
                        confirmOrDisconfirmPayment = {confirmOrDisconfirmPayment}
                    />
                :
                    ''
            }

            {
                isOpenPopUpGeneral ?
                    <PopUpGeneralStatusOrder hide = {()=>{setIsOpenPopUpGeneral(false)}}
                        nextStatus = {nextStatus}
                        idOrder = { idOrder }
                    />
                :
                    ''
            }

            {
                activeListSkus ?
                    <PopUpTablesOrders hide={()=>{setActiveListSkus(false)}}
                        idOrder = {idOrder}
                    />
                :
                    ''
            }

        </>
    )
}

export default Orders