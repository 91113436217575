import React, { useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { addNewQuote } from '../Data/QuoteServices'
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";

const PopUpStats = (props) => {

    //VARIABLES
    //*SINGLETONS
    const { totalItems, totalFound, createNewQuote } = useContext( functionsToImplementNewStructure )

    const navigate = useNavigate()

    let total = totalItems
    let porcentCotizados = ((totalFound*100)/total).toFixed(2)
    let porcentNoCotizados = ((((total-totalFound)*100)/total)).toFixed(2)

    return(
        <>
            <div className="modal pt-5" id="modalStyle">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="tittlePopUpDtats">Resultados de la carga</h5>
                        </div>
                        <div className="modal-body">
                            <p id="pTotalRowPopUpStats">FILAS DE PRODUCTOS DETECTADAS: {total}</p>
                            <p id="pTotalCotizadosPopUpStats">COTIZADOS: {totalFound} ({porcentCotizados}%)</p>
                            <p id="pTotalPendientesPopUpStats">PENDIENTES: {totalItems-totalFound} ({porcentNoCotizados}%)</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" 
                                onClick={()=>{navigate(0)}} id="buttonCancelPopUpStats">
                                    Cancelar
                            </button>
                            <button type="button" className="btn btn-primary" 
                                onClick={async()=>{
                                    await createNewQuote()
                                    props.hide()
                                    navigate("/results")
                                }} id="buttonCreatePopUpStats">
                                    Crear
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopUpStats