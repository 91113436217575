import React from "react";
import { useState } from "react";


const PopUpNewStatsVentas = (props) => {
    const [isSelectedButton1, setIsSelectedButton] = useState(false);
    const [isSelectedButton2, setIsSelectedButtonS] = useState(false);
    const handleButtonClick1 = () => {
        setIsSelectedButton(!isSelectedButton1);
    };
    const handleButtonClick2 = () => {
        setIsSelectedButtonS(!isSelectedButton2);
    };
    return (
        <>
            <div className="modal pt5" id="modalStyle">
                <div className="modal-dialog modal-dialog-client">
                    <div className="modal-content modal-content-client">
                        <div className="modal-header headerNewClient d-flex">
                            <div className="flex-start title-modal-stats">
                                <h3>Cotizaciones asistidas</h3>
                            </div>
                            <div className="flex-end">
                                <button className="button-modal-stats" onClick={() => {
                                    props.close()
                                }}>
                                    Cerrar
                                </button>
                            </div>
                        </div>
                        <div className="modal-body bodyNewUser">
                            <div className="width100">
                                <div className="d-flex timeline-modal">
                                    <div className="buttons-shortcuts">
                                        <button
                                            variant={isSelectedButton1 ? 'primary' : 'secondary'}
                                            onClick={handleButtonClick1}
                                            style={{ backgroundColor: isSelectedButton1 ? '#2E3344' : 'transparent', color: isSelectedButton1 ? '#fff' : '#000' }}
                                        >
                                            HOY
                                        </button>
                                    </div>
                                    <div className="buttons-shortcuts">
                                        <button
                                            variant={isSelectedButton2 ? 'primary' : 'secondary'}
                                            onClick={handleButtonClick2}
                                            style={{ backgroundColor: isSelectedButton2 ? '#2E3344' : 'transparent', color: isSelectedButton2 ? '#fff' : '#000' }}
                                        >
                                            MAÑANA
                                        </button>
                                    </div>
                                    <input className="date-modal-stats" type="date"

                                    /*onChange={(e)=>{
                                        updateDate(e.target.value)
                                    }}*/
                                    />
                                </div>
                                <div className="data-modal">
                                    <div className="components-modal">
                                        <div className="header-component-modal info-component-modal">
                                            <p>$10,000,000. valor</p> <i id="iconLogOut" className="bi bi-arrow-down header-arrow-false"></i>
                                        </div>
                                        <div className="users-component-modal info-component-modal">
                                            <p>(60%) LUIS</p> <i id="iconLogOut" className="bi bi-arrow-up arrow-true"></i>
                                            <p>(20%) HUMB</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>
                                            <p>(20%) JOSE</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>
                                        </div>
                                    </div>
                                    <div className="components-modal">
                                        <div className="header-component-modal info-component-modal">
                                            <p>1 cliente nuevo</p> <i id="iconLogOut" className="bi bi-arrow-down header-arrow-false"></i>
                                        </div>
                                        <div className="users-component-modal info-component-modal">
                                            <p>(0%) LUIS</p> <i id="iconLogOut" className="bi bi-arrow-up arrow-true"></i>
                                            <p>(0%) HUMB</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>
                                            <p>(100%) JOSE</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>
                                        </div>
                                        <a href="#">+ 9 clientes registrados</a>
                                    </div>
                                    <div className="components-modal">
                                        <div className="header-component-modal info-component-modal">
                                            <p>10 cotizaciones</p> <i id="iconLogOut" className="bi bi-arrow-up header-arrow-true"></i>
                                        </div>
                                        <div className="users-component-modal info-component-modal">
                                            <p>(10%) LUIS</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>
                                            <p>(60%) HUMB</p> <i id="iconLogOut" className="bi bi-arrow-up arrow-true"></i>
                                            <p>(30%) JOSE</p> <i id="iconLogOut" className="bi bi-arrow-up arrow-true"></i>
                                        </div>
                                    </div>
                                    <div className="components-modal">
                                        <div className="header-component-modal info-component-modal">
                                            <p>100 partidas</p> <i id="iconLogOut" className="bi bi-arrow-up header-arrow-true"></i>
                                        </div>
                                        <div className="users-component-modal info-component-modal">
                                            <p>(5%) LUIS</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>
                                            <p>(45%) HUMB</p> <i id="iconLogOut" className="bi bi-arrow-up arrow-true"></i>
                                            <p>(45%) JOSE</p> <i id="iconLogOut" className="bi bi-arrow-up arrow-true"></i>
                                        </div>
                                    </div>
                                    <div className="components-modal">
                                        <div className="header-component-modal info-component-modal">
                                            <p>5 partidas sin cotizar</p> <i id="iconLogOut" className="bi bi-arrow-up header-arrow-true"></i>
                                        </div>
                                        <div className="users-component-modal info-component-modal">
                                            <p>(4) LUIS</p> <i id="iconLogOut" className="bi bi-arrow-up arrow-true"></i>
                                            <p>(1) HUMB</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>
                                            <p>(0) JOSE</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>
                                        </div>
                                        <a href="#">+ 90 partidas cotizadas (90%)</a>
                                    </div>
                                    <div className="components-modal">
                                        <div className="header-component-modal info-component-modal">
                                            <p>90% con catálogo</p> <i id="iconLogOut" className="bi bi-arrow-up header-arrow-true"></i>
                                        </div>
                                        <div className="users-component-modal info-component-modal">
                                            <p>(10%) MANUAL</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>
                                            <p>(0%) SCRAPPING</p> <i id="iconLogOut" className="bi bi-arrow-down arrow-false"></i>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopUpNewStatsVentas;