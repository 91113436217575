import React, { useContext, useState } from "react";
import { informationOfSearch } from "../context/informationOfSearch";
import { GetCurrencies } from '../Hooks/ValCurrencyHooks'

const PopUpChangePrice = (props) => {

    const currenciesArr = GetCurrencies()

    //VARIABLES
    //*Singleton
    const { arrResults, updatePrice } = useContext(informationOfSearch)
    
    //console.log(arrResults[props.index1])
    //*
    const [ newPrice,setNewPrice ] = useState( arrResults[props.index1].value[props.index2].price.value )
    const [ activeSpan, setActiveSpan ] = useState(false)

    console.log(newPrice)

    //FUNCTIONS
    const changeValue = (index) => {
        let valDummy = ( arrResults[props.index1].value[props.index2].price.value * currenciesArr[index].usd )
        //console.log(valDummy)
        setNewPrice(valDummy)
        setActiveSpan(true)
    }

    return(
        <>
            <div className="modal pt5" id="modalStyle">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header d-flex justify-content-between">
                        <div>
                            <p id="tittleAddItem">Modificar Precio</p>
                        </div>
                        <div id="containerCloseAddItem">
                            <button type="button" className="btn-close" onClick = { ()=>{ props.hide() } }></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <p>{arrResults[props.index1].value[props.index2].sku_description}</p>
                        <div className="d-flex ">
                            <input type="number"
                                defaultValue = { newPrice }
                                onChange = {e=>{setNewPrice(e.target.value)}}
                                className="form-Control"
                            />
                            {
                                activeSpan ? 
                                <div id="spanCurrency">
                                    <span className="marginL15">
                                        Precio Convertido: $ {newPrice.toFixed(2)} MXN
                                    </span>
                                    <button className="btn marginL15" 
                                        id="saveAddItem" 
                                        onClick={()=>{
                                            updatePrice(props.index1, props.index2, newPrice) 
                                            props.hide()
                                    }}>
                                        Convertir
                                    </button>
                                </div>
                                :
                                ''
                            }
                        </div>                        
                    </div>
                    <div className="modal-footer">
                        <div id="container-Footer" className="d-flex justify-content-around">
                            <div className="d-flex">
                                {
                                   arrResults[props.index1].value[props.index2].price.currency === "USD" ? 
                                   <>
                                        <p id="tittleConversion" className="m-1">Convertir a pesos</p>
                                        {
                                            currenciesArr.map((item,index)=>(
                                                <button className="m-1 btn buttonCurrency"
                                                    onClick={()=>changeValue(index)}
                                                >
                                                    {item.name} : {item.usd}
                                                </button>
                                            ))
                                        }
                                   </>:
                                   ''
                                }
                                
                            </div>
                            <button className="btn" 
                                id="saveAddItem" 
                                onClick={()=>{
                                    updatePrice(props.index1, props.index2, newPrice) 
                                    props.hide()
                                }}
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopUpChangePrice