import React from "react";
import { changeStatusOrder } from "../Data/OrderesServices";

const PopUpConfirmOrDisconfirm = (props) => {

    //console.log(props.idOrder)

    return(
        <>      
            <div className="modal pt5" id="modalStyle">
                <div className="modal-dialog">
                    <div className="modal-content marginT50">
                        <div className="modal-header flex-wrap">
                            <p className="width100 text-center" id="tittlePopUpConfirm">
                                ¿Estás seguro de pasar el pedido a "
                                {props.nextStatus}"?
                            </p>
                            <div className="width100 marginL10">
                                <button id="cardDecorationArrow-Review-Red"
                                    className="btn"
                                    onClick={props.hide}
                                >
                                    Cancelar
                                </button>
                                <button id="cardDecorationArrow-Review-Green"
                                    className="btn"
                                    onClick = {()=>{
                                            changeStatusOrder(props.idOrder, props.nextStatus)
                                            props.hide()
                                        }
                                    }
                                >
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default PopUpConfirmOrDisconfirm