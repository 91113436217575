import { db } from '../fireBase'
import {
    getFirestore,
    query,
    orderBy,
    onSnapshot,
    collection,
    getDoc, 
    getDocs, 
    addDoc,
    setDoc,
    updateDoc,
    doc, 
    serverTimestamp, 
    arrayUnion,
    arrayRemove,
    limit, 
    where,
    deleteDoc,
    FieldValue,
    deleteField,
    startAfter,
    limitCount
} from "firebase/firestore"

//esta funcion agrega el documento inicial de una cotizacion
export const serviceCreateNewQuote = async ( data ) =>{
    const quotesColRef = collection(db, "quote-detail")
    const docRef = await addDoc(quotesColRef,{
        created_at: serverTimestamp(),
        ...data
    })
    return docRef.id
}

//esta funcion agrega la subcoleccion draft la cual tiene la lista de todos los items de la cot
export const serviceCreateSubCollectionDraft = async (idQuote,docData) => {
    const subcollectionRef = collection(db,"quote-detail",idQuote,"draft")
    const newItem = await addDoc(subcollectionRef,docData)
    const docRef = doc(db,"quote-detail/"+idQuote+"/draft/"+newItem.id)
    console.log(newItem.id)
    await updateDoc(docRef,{
        id:newItem.id
    })
    return
}

//al momento de crear una cotizacion lanza esta funcion la cual trae los datos
//de la subcoleccion draft de la quote creada
export const bringAQuote = async (idQuote) => {
    let arrItemsDraft = []
    //console.log(idQuote)
    const infoGeneralRef = doc(db,"quote-detail/" + idQuote)
    const draftRef = collection(db,"quote-detail/" + idQuote + "/draft")
    const infoGeneral = await getDoc(infoGeneralRef)
    const itemsDraft = await getDocs(draftRef)
    const dataInfoGeneral = infoGeneral.data()
    itemsDraft.forEach(doc=>{
        arrItemsDraft.push(doc.data())
    })
    return [arrItemsDraft, dataInfoGeneral]
}

//esta funcion activa el escucha de una quote en la subcoleccion Cart
export const listenToQuoteCart = (docId,setNewItems) => {
    const cartRef = collection(db, "quote-detail/" + docId + "/cart");
    onSnapshot(cartRef,(querySnapshot) => {
        const arrItems = [];
        querySnapshot.forEach((doc) => {
            arrItems.push(doc.data());
        });
        setNewItems(arrItems)
    }, (error) => {
        console.log("Error al obtener los datos de la subcolección:", error);
    });
}

//esta funcion activa el escucha de una quote en la subcoleccion Draft
export const listenToQuoteDraft = (docId,setArrNoResults, setArrDiscarded) => {
    const cartRef = collection(db, "quote-detail/" + docId + "/draft");
    onSnapshot(cartRef,(querySnapshot) => {
        const arrItemsDiscarted = []
        const arrItemsPending = [];
        querySnapshot.forEach((doc) => {
            let info = doc.data()
            if(info.status==="to_search") {
                arrItemsPending.push(info)
            }else if(info.status==="discarded"){
                arrItemsDiscarted.push(info)
            }
        });
        setArrNoResults(arrItemsPending)
        setArrDiscarded(arrItemsDiscarted)
    }, (error) => {
        console.log("Error al obtener los datos de la subcolección:", error);
    });
}

//este funcion alimenta el hook que trae toda la info de las ultimas 15 cotizaciones
export const streamQuotesDetailToHome = (snapShot, error) => {
    const quotesRef = collection(db,'quote-detail')
    const itemsQuery = query(quotesRef,orderBy("created_at","desc"),limit(15))
    return onSnapshot(itemsQuery, snapShot, error)
}

//este funcion alimenta el hook que trae toda la info de todas las quote-detail
export const streamQuotesDetail = (startAfterDoc, limitCount, snapShot, error) => {
    let quotesRef = collection(db, 'quote-detail');
    let itemsQuery = query(quotesRef, orderBy('created_at', 'desc'), limit(50));
  
    if (startAfterDoc) {
      itemsQuery = query(quotesRef, orderBy('created_at', 'desc'), startAfter(startAfterDoc), limit(limitCount));
    }
  
    return onSnapshot(itemsQuery, snapShot, error);
  };

// funcion que recibe las cotizaciones asistidas
export const streamQuotesDetailAssist = (snapShot, queryLimit, error) => {
    const quotesRef = collection(db, 'quote-detail')
    const itemsQuery = query(
        quotesRef, 
        where('author.is_admin', '==', true), 
        orderBy('created_at', 'desc'), 
        limit(queryLimit)
    );
    return onSnapshot(itemsQuery, snapShot, error);
}

// funcion que recibe las cotizaciones autogestion
export const streamQuotesDetailAuto = (snapShot, queryLimit, error) => {
    const quotesRef = collection(db, 'quote-detail')
    const itemsQuery = query(
        quotesRef, 
        where('author', '!=', 'is_admin'), 
        orderBy('author', 'asc'), 
        orderBy('created_at', 'desc'), 
        limit(queryLimit));
    return onSnapshot(itemsQuery, snapShot, error);
}

//esta funcion agrega un producto nuevo a la lista de draft para q aparaezaca en la lista de pendientes
export const addItemToPending = async (docId, newItem) => {
    const quoteRef = collection(db,'quote-detail/'+docId+'/draft')
    let lengthArrDraft = await getDocs(quoteRef)
    lengthArrDraft = lengthArrDraft.size
    const mapNewItem = {
        id:'',
        position : lengthArrDraft+1,
        requestedAmount : 1,
        requested_product : newItem,
        status : "to_search"
    }
    const idNewDoc = await addDoc(quoteRef, mapNewItem)
    await updateDoc(idNewDoc,{id:idNewDoc.id})
}

//esta funcion pasa todos lo pendientes a descartados con razon de no encontrado por default No Encontrado
export const moveAllPendingsToDiscarded = async (docId) => {
    const quoteRef = collection(db,'quote-detail/'+docId+'/draft')
    const docsDraft = await getDocs(quoteRef)
    docsDraft.forEach(async doc => {
        const docRef = doc.ref
        const data = doc.data()
        if(data.status !== "found"){
            await updateDoc(docRef,{
                status:"discarded",
                reason:"No Encontrado"
            })
        }
        
    });
}

//esta funcion cambia estatus de un producto de draft a to_serch para asi pasar de descartado a pendiente
export const moveDiscardedToPending = async (quoteId, docId) => {
    const docRef = doc(db, 'quote-detail/'+quoteId+'/draft/'+docId)
    await updateDoc(docRef, {
        status:"to_search"
    })
}

//esta funcion actualiza la razon del por que se descarto
export const changeReasonToDiscarded = async (quoteId,docId,reason) => {
    const docRef = doc(db, 'quote-detail/'+quoteId+'/draft/'+docId)
    await updateDoc(docRef,{
        reason:reason
    })
}

//esta funcion cambia el status de un producto de draft a discarded para asi pasar un item de pendiente a descartado
export const movePendingToDiscarded = async (quoteId,docId) => {
    const docRef = doc(db, 'quote-detail/'+quoteId+'/draft/'+docId)
    await updateDoc(docRef,{
        status: "discarded",
        reason: "No Encontrado"
    })
}

//Esta funcion cambia el status de un producto de draft a to_search para asi pasar un item de encontrado a pendiente
export const moveResultToPending = async (quoteId, docId, idProduct) => {
    const docRefDraft = doc(db, 'quote-detail/'+quoteId+'/draft/'+docId)
    const docRefCart = doc(db,"quote-detail/"+quoteId+"/cart/"+idProduct)
    await updateDoc(docRefDraft,{
        status: "to_search",
        product_id: deleteField()
    })
    await deleteDoc(docRefCart)
}

//Esta funcion va crear un nuevo producto en la subcoleccion cart y en la subcoleccion draft va cambiar su status a found y va poner calculeteTotals
export const createDocManualInCart = async (quoteId, item, data) => {
    console.log(data)
    const draftRef= doc(db, "quote-detail/"+quoteId+"/draft/"+item.id)
    const cartRef = collection(db,"quote-detail/"+quoteId+"/cart")
    const quoteRef = doc(db, "quote-detail/"+quoteId)
    const idCart = await addDoc(cartRef,data)
    const docInCartRef = doc(db,'quote-detail/'+quoteId+"/cart/"+idCart.id)
    const collectionNewProduct = collection(db,"new-products")
    await updateDoc (draftRef,{
        status:"found",
        product_id:idCart.id
    })
    await updateDoc (docInCartRef, {
        product_id:idCart.id
    })
    await addDoc(collectionNewProduct,data)
    await updateDoc (quoteRef,{
        record:{
            next_action:"calculate_totals"
        }
    })
}

//Esta funcion funciona para agregar a la subcolleccion cart cuando el producto proviene del catalogo
export const createDocCatalogInCart = async (quoteId, docId, product_id, quantity) => {
    const draftRef = doc(db,"quote-detail/"+quoteId+"/draft/"+docId)
    //console.log(product_id)
    await updateDoc (draftRef,{
        status:"found",
        requestedAmount:quantity,
        product_id:product_id
    })
}

//Esta funcion cambia el price_margin en el producto de cart seleccionado
export const changePriceMargin = async (quoteId, productId, newVal) => {
    const cartRef = doc(db, "quote-detail/"+quoteId+"/cart/"+productId)
    const mainRef = doc(db,"quote-detail/"+quoteId)
    await updateDoc (cartRef, {
        price_margin: newVal
    })
    await updateDoc ( mainRef, {
        record : {
            next_action: "calculate_totals"
        }
    })
}