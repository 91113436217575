import React from "react";
import { useNavigate } from "react-router-dom";

const PopUpPublished = (props) => {
    const navigate = useNavigate()

    const cerradoPopUpToHome = () => {
        props.hide()
        navigate('/')
        //navigate(0) 
    }

    return(
        <>
        {
            props.canPublish ?
                
                <div className="modal pt-5" id="modalStyle">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div id="containerFirstButtons" className="d-flex justify-content-between">
                                    <div>
                                        <p id="tittlePopUpPublished">Cotización publicada exitosamente, comparta este link con su cliente:</p>
                                        <a href={process.env.REACT_APP_URL_V2 + props.id} target="_blank">{process.env.REACT_APP_URL_V2 + props.id}</a>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-close" onClick = { ()=>{ cerradoPopUpToHome() } }></button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex justify-content-end">
                                    <button id="buttonCloseQuote" onClick={ ()=> {cerradoPopUpToHome()} }>Aceptar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                <div className="modal pt-5" id="modalStyle">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header d-block">
                                <div id="containerFirstButtons" className="d-flex justify-content-between">
                                    <div >
                                        {
                                            props.costOfDelivery === 0 &&
                                            <li className="mt-3" id="tittlePopUpPublished">{props.messageToShow2}</li>
                                        }
                                        {
                                            props.allWithReason === false &&
                                            <li className="mt-3" id="tittlePopUpPublished">{props.messageToShow3}</li>
                                        }
                                    </div>
                                    <div>
                                        <button type="button" className="btn-close" onClick = { ()=>{ props.hide() } }></button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex justify-content-end">
                                    {
                                            !props.allWithReason ?
                                                <button id="buttonCloseQuote" onClick={ ()=> {props.hide()} }>Entendido</button>
                                            :
                                                <>
                                                    <button id="buttonCloseQuote" onClick={ ()=> {props.hide()} }>No es correcto</button>
                                                    <button id="buttonCloseQuoteRed" onClick={ ()=>{
                                                        props.publish()
                                                        props.hide()
                                                    }}>
                                                        Si es correcto
                                                    </button>
                                                </>                                            
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
             
        </>
    )
}

export default PopUpPublished