import React, { useEffect, useState, useContext } from 'react'
import { informationOfSearch } from '../context/informationOfSearch'
import { GetOrder } from '../Data/OrderesServices'

const PopUpTablesOrders = (props) =>{

    let infOrder

    const [ orderInfo, setOrderInfo ] = useState()

    const orderPromise = new Promise (async(resolve, reject)=>{
        infOrder = await GetOrder(props.idOrder)
        if(infOrder!==undefined) resolve(infOrder)
    })

    orderPromise.then((data)=>{
        data.map((item)=>{
            console.log(item.products_ordered[0])
        })
    })

    return (
        <>
        {
            
        }
        </>
    )
}

export default PopUpTablesOrders