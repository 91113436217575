import React, { useContext, useState } from "react";
import { informationOfSearch } from "../context/informationOfSearch";
import { functionsToImplementNewStructure } from "../context/functionsToImplementNewStructure";
import { moveAllPendingsToDiscarded, movePendingToDiscarded } from "../Data/newQuoteServices";

const TableNotResults = (props) => {

    //VARIABLES
    //*Singletons
    const { moveNotResultsToDiscarted, moveAllToDiscarted} = useContext ( informationOfSearch )
    const { arrNotResults, idQuote, setItemAddProduct, setDocId } = useContext ( functionsToImplementNewStructure )

    return(
        <>
            <div className="containerTables">
                <p id="tittleTableNotResults">Pendientes ({ arrNotResults.length })</p>
                <div id="containerTableNoResult">
                    <table className="table table-borderless" >
                        <thead id="headNoResults">
                            <tr>
                                <th scope="col" className="text-center" id="pHashtag" >#</th>
                                <th scope="col" id="pNameItemNotHeadResults" className="d-flex justify-content-between">
                                    <p>PRODUCTO</p>
                                    <button id="buttonPointsNotResults" onClick={()=>{moveAllPendingsToDiscarded(idQuote)
                                        props.setChanges(props.changes+1)
                                    }}>Descartar Todos</button>
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody className="tableBody">
                            {
                                arrNotResults.map((item,index)=>(
                                    <tr>
                                        <td scope="row" className="text-center pPositionNotResults"> { item.position } </td>
                                        <td className="pNameItemNotResults"> { item.requested_product} </td>
                                        <td className="text-center indexSup dropdown" id="points"> 
                                            <button className="buttonPoints btn" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-three-dots-vertical"/>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li class="dropdown-item opstionsResult" onClick={() => {
                                                    props.launchActiveLapicitoFromNotResults(item)
                                                    setDocId(item.id)
                                                }}>
                                                    Agregar SKUS
                                                </li>
                                                <li class="dropdown-item opstionsResult" onClick={() => {
                                                    setItemAddProduct(item)
                                                    props.showPopUpNewItemManual()
                                                }}>
                                                    Agregar SKU manualmente
                                                </li>
                                                <li class="dropdown-item opstionsResult" onClick={() => {movePendingToDiscarded(idQuote,item.id)}}>
                                                    Mover a descartados
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot id="footerNoResults">
                            <tr>
                                <td scope="col"></td>
                                <th scope="col" id="containerPoints">
                                    <button id="buttonPointsNotResults" onClick={props.show}>
                                        <i id="iconPlusNotResults" className="bi bi-plus-circle"></i>
                                        Agregar nueva fila de producto
                                    </button>
                                </th>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )
}

export default TableNotResults