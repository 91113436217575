import { useState, useEffect } from "react";
import { streamCompaniesItems, streamUsersItems } from "../Data/CompanyServices";
import { getCompany } from "../Data/CompanyServices";

export function GetCompanies() {
    const [ companies, setCompanies ] = useState([])

    useEffect(() => {
        const unsubscribe = streamCompaniesItems(
            (querySnapshot) => {
                const items = []
                querySnapshot.forEach(doc => {
                    const data = doc.data()
                    items.push({...data, id: doc.id})
                });
                setCompanies(items)
            }
        )
        return () => unsubscribe()
    },[])

    return companies
}

/*export function GetUsers(data) {
    const [ users, setUsers ] = useState([])

    useEffect(() => {
        const unsubscribe = streamUsersItems(
            (querySnapshot) => {
                const items = []
                querySnapshot.forEach(async doc => {
                    const data = doc.data()
                    const infoCompany= await getCompany(data.company_id)
                    data.infoCompany = infoCompany
                    //console.log(data)
                    items.push({...data, id: doc.id})
                    console.log(items)
                });
                setUsers(items)
                data(items)
                //console.log(items)
            }
        )
        return () => unsubscribe()
    },[])

    return users
}*/
export function GetUsers() {
    const [ users, setUsers ] = useState([])

    useEffect(() => {
        const unsubscribe = streamUsersItems(
            (querySnapshot) => {
                const items = []
                querySnapshot.forEach(async doc => {
                    const data = doc.data()
                    items.push({...data, id: doc.id})
                });
                setUsers(items)
            }
        )
        return () => unsubscribe()
    },[])

    return users
}